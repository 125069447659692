import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { commonConfig } from "~/config/common-config";
import { constants } from "~/common/constants";

// Required to make TS happy
const stagingProdList: string[] = [
  constants.Environments.STAGING,
  constants.Environments.PRODUCTION,
];

export const DataDogRum: React.FC = () => {
  const isClientContext = typeof window !== "undefined";
  const isStagingOrProduction = stagingProdList.includes(
    commonConfig.NEXT_PUBLIC_ENDIL_ENV
  );

  useEffect(() => {
    if (
      isClientContext &&
      isStagingOrProduction &&
      commonConfig.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE > 0
    ) {
      /* 
      For additional configuration options, see: 
      https://docs.datadoghq.com/real_user_monitoring/browser/#configuration

      For sampling rate specific configuration see: 
      https://docs.datadoghq.com/real_user_monitoring/guide/sampling-browser-plans/ 
      */
      datadogRum.init({
        applicationId:
          commonConfig.NEXT_PUBLIC_DATADOG_RUM_APPLICATION_ID || "",
        clientToken: commonConfig.NEXT_PUBLIC_DATADOG_RUM_CLIENT_TOKEN || "",
        service: constants.DATADOG_SERVICE_NAME,
        env: commonConfig.NEXT_PUBLIC_ENDIL_ENV,
        version: commonConfig.NEXT_PUBLIC_ENDIL_VERSION,
        sessionSampleRate: commonConfig.NEXT_PUBLIC_DATADOG_RUM_SAMPLE_RATE,
        // Session replay sample rate will be 50% of the sessionSampleRate. See docs above.
        sessionReplaySampleRate: 50,
        // We don't have APM tracing enabled so this probably isn't needed but setting it to 0 just in case.
        traceSampleRate: 0,
        trackResources: true,
        trackLongTasks: true,
        trackUserInteractions: true,
        defaultPrivacyLevel: "mask-user-input",
      });
    }
  }, []);

  return null;
};
