import lodashGet from "lodash.get";
import lodashSet from "lodash.set";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Script from "next/script";
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useAnalytics } from "~/components/Analytics";
import {
  useGlobalModals,
  useRegisterAccountModal,
} from "~/components/GlobalState";
import { PromotionalBannerFlyout } from "~/components/Piano/PromotionalBanner";
import { useSyncPermissions } from "~/components/Piano/useSyncPermissions";
import { commonConfig } from "~/config/common-config";
import { useLoginBehavior } from "~/lib/client/useLoginBehavior";
import { useRouteWithUTMParams } from "~/lib/client/useRouteWithUTMParams";
import { dispatchEvent } from "~/lib/shared/eventEmitter";
import {
  PianoContextProps,
  PianoContextProvider,
  PianoSDKStatus,
} from "./PianoContext";
import type {
  PianoCustomEventHandler,
  PianoExecuteExperienceEvent,
  ReportPreviewTemplateIds,
  SetResponseVariables,
  TinyPassInitArray,
} from "./piano-types";

const prunePath = (path: string): string => path.split(/[?#]/)[0];

/**
 * Initializes and loads the Piano SDK
 *
 * See docs: https://docs.piano.io/track/implementing-piano/
 */
export const PianoLoader = ({
  children,
  pianoApplicationId,
  isSandbox,
}: PropsWithChildren<{
  pianoApplicationId?: string;
  isSandbox: boolean;
}>): JSX.Element => {
  const router = useRouter();
  const [activeSubscriptionName, setActiveSubscriptionName] = useState<
    string | null
  >(null);

  const templatesRef = useRef<ReportPreviewTemplateIds>({
    ProPlusReportTemplateId: "",
    ProPlusReportVariantId: "",
    ProReportTemplateId: "",
    ProReportVariantId: "",
    RegisteredReportTemplateId: "",
    RegisteredReportVariantId: "",
  });
  const [reportTemplates, setReportTemplates] =
    useState<ReportPreviewTemplateIds>(templatesRef.current);

  const [paywallVisibilityChange, setPaywallVisibilityChange] =
    useState<boolean>(false);
  const [showPromotionalBanner, setShowPromotionalBannerChange] =
    useState<string>("");
  const [meterActiveChanged, setPaywallVisibilityActiveChanged] =
    useState<boolean>(false);
  const [paywall, setPaywall] = useState<{
    visible: boolean;
    path?: string;
  }>({
    visible: false,
  });
  const [pianoEventChanged, setPianoEventChanged] = useState<boolean>(false);
  const { current: pianoEvents } = useRef<
    Array<[string, Record<string, unknown>]>
  >([]);
  const routeWithUTM = useRouteWithUTMParams();
  const loginHandler = useLoginBehavior();
  const [loaderStatus, setLoaderStatus] = useState<PianoSDKStatus>(
    PianoSDKStatus.NotReady
  );
  const { status: sessionStatus } = useSession();

  const { setShouldShowRegisterAccountModal } = useRegisterAccountModal();

  const [shouldRunSyncPermissions, setShouldRunSyncPermissions] =
    useState(false);

  const [hasExperienceExecuted, setHasExperienceExecuted] = useState(false);

  const [hasNavigatedToRegister, setHasNavigatedToRegister] = useState(false);

  const { analyticsEnhancedTrack } = useAnalytics();
  const syncPermissions = useSyncPermissions();

  const { openModal, closeModal, isModalOpen } = useGlobalModals();

  const PIANO_MODAL_ID = "PIANO_MODAL_ID";

  /**
   * This logic has to live outside of the useEffect / customEventHandler.
   * Piano only creates the event handlers once and they are holding on to
   * a reference to a PianoLoader component (function) that is no longer
   * being rendered. That means any state changes above, such as isModalOpen,
   * do not propagate to the Piano event handler, even though there is closure
   * over isModalOpen. By using useState for hasNavigatedToRegister it doesn't
   * matter what reference Piano is holding onto because the state changes
   * propagate between the components.
   */
  // route to the register page if a modal is open
  if (hasNavigatedToRegister && isModalOpen) {
    routeWithUTM("/register");
    setHasNavigatedToRegister(false);
  }
  // show the register modal if there are no other modals open
  if (hasNavigatedToRegister && !isModalOpen) {
    setShouldShowRegisterAccountModal(true);
    setHasNavigatedToRegister(false);
  }

  /**
   * This callback is registered with Piano on initialization and then
   * it's reference is NEVER CHANGED due to the way Piano handles callbacks.
   *
   * Therefore any pieces of state are captured in the closure at first
   * registration.
   */
  const createEventHandler = useCallback(
    (eventName: string, allowedPropertyPaths?: string[]) =>
      (event: Record<string, unknown>) => {
        let hasSetValue = false;
        const sanitizedEvent = allowedPropertyPaths?.reduce(
          (result, propertyPath) => {
            const value: any = lodashGet(event, propertyPath);
            if (value !== undefined) {
              lodashSet(result, propertyPath, value);
              hasSetValue = true;
            }
            return result;
          },
          {}
        );

        pianoEvents.push([
          eventName,
          hasSetValue ? { pianoEvent: sanitizedEvent } : {},
        ]);
        setPianoEventChanged(true);
      },
    [pianoEvents]
  );

  /**
   * Handle Piano events in a useEffect that should enable
   * the stateful pieces related to react contexts behind
   * analyticsEnhancedTrack to update accordingly.
   */
  useEffect(() => {
    if (!pianoEventChanged) {
      return;
    }
    if (pianoEvents.length) {
      pianoEvents.forEach(([eventName, payload]) => {
        analyticsEnhancedTrack(eventName, payload);
      });
    }

    // reset array without changing reference
    pianoEvents.length = 0;
    setPianoEventChanged(false);
  }, [pianoEventChanged, pianoEvents, analyticsEnhancedTrack]);

  /**
   * Initialize Piano SDK with base options.
   */
  useEffect(() => {
    if (!pianoApplicationId) {
      return;
    }
    if (loaderStatus === PianoSDKStatus.Ready) {
      return;
    }

    // Guards if this component is rendered multiple times
    if (window.tp && window.tp.aid) {
      setLoaderStatus(PianoSDKStatus.Ready);
      return;
    }

    const customEventHandler: PianoCustomEventHandler = (event) => {
      setShowPromotionalBannerChange("");

      if (event.eventName === "navigate-to-href" && event.params) {
        analyticsEnhancedTrack("Piano Custom Event navigate-to-href", {
          href: event.params.href,
        });
        routeWithUTM(event.params.href, { utm_source: "piano_experience" });
      }

      if (event.eventName === "navigate-to-login") {
        analyticsEnhancedTrack("Piano Custom Event navigate-to-login");
        loginHandler({ type: "redirect" });
      }
      if (event.eventName === "popup-login") {
        analyticsEnhancedTrack("Piano Custom Event popup-login");
        loginHandler({ type: "window" });
      }
      if (event.eventName === "navigate-to-register") {
        analyticsEnhancedTrack("Piano Custom Event navigate-to-register");
        setHasNavigatedToRegister(true);
      }
      if (event.eventName === "navigate-to-trial") {
        analyticsEnhancedTrack("Piano Custom Event navigate-to-trial");
        routeWithUTM("/free-trial", { utm_source: "piano_experience" });
      }
      if (event.eventName === "free-trial-checkout") {
        analyticsEnhancedTrack("Piano Custom Event free-trial-checkout");
        routeWithUTM("/subscription-checkout?subscription=free_trial_annual", {
          utm_source: "piano_experience",
        });
      }
      if (event.eventName === "navigate-to-subscribe") {
        analyticsEnhancedTrack("Piano Custom Event navigate-to-subscribe");
        routeWithUTM("/subscription-checkout", {
          utm_source: "piano_experience",
        });
      }
      if (event.eventName === "navigate-to-pricing") {
        analyticsEnhancedTrack("Piano Custom Event navigate-to-pricing");
        routeWithUTM("/subscribe#pricing", { utm_source: "piano_experience" });
      }
      if (event.eventName === "redeem-shared-access-subscription-seat") {
        analyticsEnhancedTrack(
          "Piano Custom Event redeem-shared-access-subscription-seat"
        );
        setShouldRunSyncPermissions(true);
      }
      if (event.eventName === "navigate-to-econ-demo") {
        analyticsEnhancedTrack("Piano Custom Event navigate-to-econ-demo");
        routeWithUTM("/morning-consult-economic-intelligence-demo-request", {
          utm_source: "piano_experience",
        });
      }
      if (event.eventName === "navigate-to-enterprise-landing-page") {
        analyticsEnhancedTrack(
          "Piano Custom Event navigate-to-enterprise-landing-page"
        );
        routeWithUTM("/enterprise-data-access", {
          utm_source: "piano_experience",
        });
      }

      if (event.eventName === "auto-renew-toggle" && event.params) {
        const { termname, autorenew } = event.params;
        const isEnablingAutorenew = autorenew === "enabled";
        const eventName = isEnablingAutorenew
          ? "Enable Auto Renew"
          : "Disable Auto Renew";

        analyticsEnhancedTrack(eventName, {
          termname,
          autorenew,
        });

        if (!isEnablingAutorenew) {
          dispatchEvent("auto-renew-disabled");
        }
      }
    };

    const initArray: TinyPassInitArray = [
      ["setAid", pianoApplicationId],

      // sandbox settings
      ["setSandbox", isSandbox],
      ["setDebug", commonConfig.NEXT_PUBLIC_PIANO_DEBUG],
      [
        "setEndpoint",
        `https://${isSandbox ? "sandbox" : "api"}.tinypass.com/api/v3`,
      ],

      // auth-sync settings
      ["setUseTinypassAccounts", false],
      ["setUsePianoIdUserProvider", false],
      ["setUsePianoIdLiteUserProvider", true],

      // callbacks
      // see docs: https://docs.piano.io/callbacks/
      [
        "addHandler",
        "checkoutComplete",
        createEventHandler("Piano Checkout Complete"),
      ],
      [
        "addHandler",
        "checkoutClose",
        (e: Record<string, unknown>) => {
          closeModal("PIANO_MODAL_ID");
          createEventHandler("Piano Checkout Close")(e);
        },
      ],
      [
        "addHandler",
        "checkoutCustomEvent",
        createEventHandler("Piano Checkout Custom Event"),
      ],
      [
        "addHandler",
        "checkoutError",
        createEventHandler("Piano Checkout Error"),
      ],
      [
        "addHandler",
        "checkoutSelectTerm",
        createEventHandler("Piano Checkout Select Term"),
      ],
      [
        "addHandler",
        "checkoutStateChange",
        createEventHandler("Piano Checkout State Change"),
      ],
      [
        "addHandler",
        "completeUpgradePurchase",
        createEventHandler("Piano Checkout Complete Upgrade Purchase"),
      ],
      ["addHandler", "showOffer", createEventHandler("Piano Show Offer")],
      [
        "addHandler",
        "showTemplate",
        (e: Record<string, unknown>) => {
          if (e.displayMode === "modal") {
            openModal(PIANO_MODAL_ID);
          }
          createEventHandler("Piano Show Template", [
            "offerId",
            "templateId",
            "templateVariantId",
          ])(e);
        },
      ],
      [
        "addHandler",
        "startCheckout",
        createEventHandler("Piano Start Checkout"),
      ],
      [
        "addHandler",
        "submitPayment",
        createEventHandler("Piano Submit Payment"),
      ],
      [
        "addHandler",
        "experienceExecute",
        (event: PianoExecuteExperienceEvent) => {
          const { accessList } = event;
          const termWithProResource = accessList.find(
            ({ rid }) => rid === "pro"
          );

          if (termWithProResource) {
            setActiveSubscriptionName("Individual Subscription");
          }
          setHasExperienceExecuted(true);
          return createEventHandler("Piano Experience Execute")(event);
        },
      ],
      [
        "addHandler",
        "setResponseVariable",
        ({ responseVariables }: SetResponseVariables) => {
          const knownVariables = [
            "showPaywall",
            "promotionalBanner",
            "ProPlusReportTemplateId",
            "ProPlusReportVariantId",
            "ProReportTemplateId",
            "ProReportVariantId",
            "RegisteredReportTemplateId",
            "RegisteredReportVariantId",
          ];

          const unknownKeys = Object.keys(responseVariables).reduce(
            (acc, val) => {
              if (!knownVariables.includes(val)) {
                return [...acc, val];
              }
              return acc;
            },
            [] as string[]
          );

          if (unknownKeys.length) {
            console.warn(
              "Unknown keys passed as response variables",
              unknownKeys
            );
          }

          if (
            "showPaywall" in responseVariables &&
            responseVariables.showPaywall
          ) {
            setPaywallVisibilityChange(true);
          }

          if ("promotionalBanner" in responseVariables) {
            setShowPromotionalBannerChange(responseVariables.promotionalBanner);
          }

          if ("ProPlusReportTemplateId" in responseVariables) {
            templatesRef.current = {
              ...templatesRef.current,
              ProPlusReportTemplateId:
                responseVariables.ProPlusReportTemplateId,
              ProPlusReportVariantId: responseVariables.ProPlusReportVariantId,
            };
            setReportTemplates(templatesRef.current);
          }

          if ("ProReportTemplateId" in responseVariables) {
            templatesRef.current = {
              ...templatesRef.current,
              ProReportTemplateId: responseVariables.ProReportTemplateId,
              ProReportVariantId: responseVariables.ProReportVariantId,
            };
            setReportTemplates(templatesRef.current);
          }

          if ("RegisteredReportTemplateId" in responseVariables) {
            templatesRef.current = {
              ...templatesRef.current,
              RegisteredReportTemplateId:
                responseVariables.RegisteredReportTemplateId,
              RegisteredReportVariantId:
                responseVariables.RegisteredReportVariantId,
            };
            setReportTemplates(templatesRef.current);
          }
        },
      ],
      [
        "addHandler",
        "meterActive",
        createEventHandler("Piano Meter Active", [
          "maxViews",
          "meterName",
          "totalViews",
          "views",
          "viewsLeft",
        ]),
      ],
      [
        "addHandler",
        "meterActive",
        () => setPaywallVisibilityActiveChanged(true),
      ],
      [
        "addHandler",
        "meterExpired",
        createEventHandler("Piano Meter Expired", [
          "maxViews",
          "meterName",
          "totalViews",
          "views",
          "viewsLeft",
        ]),
      ],
      ["addHandler", "meterExpired", () => setPaywallVisibilityChange(true)],
      ["addHandler", "customEvent", customEventHandler],

      // called after SDK loaded
      [
        "init",
        () => {
          setLoaderStatus(PianoSDKStatus.Ready);
        },
      ],
    ];

    /**
     * Set the initArray on `window.tp` so that when the piano
     * SDK loads, it will read the initArray and initalize properly.
     *
     * However, this breaks the global type definition - hence the
     * otherwise spurious `@ts-expect-error` annotation below
     */
    // @ts-expect-error
    window.tp = initArray;
  }, [
    openModal,
    closeModal,
    isModalOpen,
    router,
    loaderStatus,
    pianoApplicationId,
    isSandbox,
    setShouldRunSyncPermissions,
  ]);

  useEffect(() => {
    if (shouldRunSyncPermissions && sessionStatus === "authenticated") {
      syncPermissions(true);
      setShouldRunSyncPermissions(false);
    }
  }, [
    shouldRunSyncPermissions,
    setShouldRunSyncPermissions,
    syncPermissions,
    sessionStatus,
  ]);

  const { asPath } = router;
  useEffect(() => {
    if (paywallVisibilityChange) {
      setPaywallVisibilityChange(false);
      setPaywall({
        visible: true,
        path: prunePath(asPath),
      });
    }
    if (meterActiveChanged) {
      setPaywallVisibilityActiveChanged(false);
      setPaywall({
        visible: false,
      });
    }
  }, [paywallVisibilityChange, meterActiveChanged, asPath]);

  const context: PianoContextProps =
    loaderStatus === PianoSDKStatus.Ready
      ? {
          pianoStatus: PianoSDKStatus.Ready,
          piano: window.tp!,
          promotionalBannerSlug: showPromotionalBanner,
          paywallShown: paywall.path === prunePath(asPath),
          activeSubscriptionName,
          hasExperienceExecuted,
          reportTemplates,
        }
      : {
          pianoStatus: loaderStatus,
          piano: null,
          hasExperienceExecuted,
        };

  return (
    <PianoContextProvider {...context}>
      {pianoApplicationId ? (
        <Script
          async
          id="piano-script"
          data-testid="piano-script"
          strategy="afterInteractive"
          src="//cdn.piano.io/api/tinypass.min.js"
        />
      ) : (
        <></>
      )}
      {children}
      <PromotionalBannerFlyout />
    </PianoContextProvider>
  );
};
