export const TrainIcon = () => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.33268 12.0001C4.23268 12.0001 3.33268 12.9001 3.33268 14.0001C3.33268 15.1001 4.23268 16.0001 5.33268 16.0001C6.43268 16.0001 7.33268 15.1001 7.33268 14.0001C7.33268 12.9001 6.43268 12.0001 5.33268 12.0001ZM5.33268 14.6668C4.96602 14.6668 4.66602 14.3668 4.66602 14.0001C4.66602 13.6334 4.96602 13.3334 5.33268 13.3334C5.69935 13.3334 5.99935 13.6334 5.99935 14.0001C5.99935 14.3668 5.69935 14.6668 5.33268 14.6668ZM14.666 12.0001C13.566 12.0001 12.666 12.9001 12.666 14.0001C12.666 15.1001 13.566 16.0001 14.666 16.0001C15.766 16.0001 16.666 15.1001 16.666 14.0001C16.666 12.9001 15.766 12.0001 14.666 12.0001ZM14.666 14.6668C14.2993 14.6668 13.9993 14.3668 13.9993 14.0001C13.9993 13.6334 14.2993 13.3334 14.666 13.3334C15.0327 13.3334 15.3327 13.6334 15.3327 14.0001C15.3327 14.3668 15.0327 14.6668 14.666 14.6668ZM19.3327 4.4501L19.1827 4.26677C14.8993 -0.7999 7.33268 -1.4499 2.26602 2.81677C1.74935 3.2501 1.26602 3.73343 0.816016 4.26677L0.666016 4.4501V18.6668H4.29935L2.06602 23.4001L3.26602 23.9668L4.19935 22.0001H15.7993L16.7327 23.9668L17.9327 23.4001L15.716 18.6668H19.3327V4.4501ZM17.9993 4.9501V8.11677L10.666 9.83343V1.3501C13.4827 1.53343 16.1327 2.81677 17.9993 4.9501ZM9.33268 1.3501V9.83343L1.99935 8.11677V4.9501C3.86602 2.81677 6.49935 1.53343 9.33268 1.3501ZM15.1827 20.6668H4.83268L5.78268 18.6668H14.2493L15.1827 20.6668ZM1.99935 17.3334V9.48343L9.99935 11.3501L17.9993 9.48343V17.3334H1.99935Z"
      fill="#4657CE"
    />
  </svg>
);
