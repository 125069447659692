import { cx } from "cva";
import NextLink from "next/link";
import NextImage from "next/image";

export type LayoutAppLogoProps = {
  className?: string;
  onClick?: () => void;
  // render one of LogoLarge, LogoCondensed, or LogoResponsive below
  logo: React.ReactNode;
};

export const AppLogoLarge = ({ className }: { className?: string }) => (
  <NextImage
    src="/assets/mcpro-logo-color.png"
    alt="logo"
    width={260}
    height={5}
    priority={true}
    className={className}
  />
);

const AppLogoCondensed = ({ className }: { className?: string }) => (
  <NextImage
    src="/assets/mcpro-logo-condensed-color.png"
    alt="logo"
    width={80}
    height={5}
    priority={true}
    className={className}
  />
);

export const AppLogoResponsive = () => (
  <>
    <AppLogoLarge className="hidden md:block" />
    <AppLogoCondensed className="md:hidden" />
  </>
);

export const LayoutAppLogo: React.FC<LayoutAppLogoProps> = ({
  className,
  logo,
  ...rest
}) => (
  <div className="flex max-h-[48px] items-center">
    <NextLink
      {...rest}
      aria-label="Go to homepage"
      className={cx(
        className,
        "flex w-full items-center justify-center gap-xs py-xxs"
      )}
      href="/"
      legacyBehavior={false}
    >
      {logo}
    </NextLink>
  </div>
);
