const MC_SESSION_REFERRER = "mc_session_referrer";

type RefererResults = { mcReferer: string; mcSessionReferer: string };

export const getCustomRefererProperties = (
  refererPath: string | null
): RefererResults => {
  let mcReferer: string;
  let mcSessionReferer: string | null =
    window.sessionStorage.getItem(MC_SESSION_REFERRER);

  if (refererPath === null) {
    mcReferer = window.document.referrer;
  } else {
    const url = new URL(refererPath, window.location.origin);
    mcReferer = url.href;
  }

  if (mcSessionReferer === null) {
    mcSessionReferer = mcReferer;
    window.sessionStorage.setItem(MC_SESSION_REFERRER, mcSessionReferer);
  }

  return { mcReferer, mcSessionReferer };
};
