// General utility to convert a url or path to a path + query string
export const createLoginRedirectPath = (urlOrPath: string): string => {
  const currentUrl = new URL(window.location.href);
  const urlInstance = new URL(urlOrPath, currentUrl.origin);

  return urlInstance.pathname + urlInstance.search;
};

// This is specifically used for the "next-auth/react" signIn() method's callbackUrl option
export const createLoginRedirectCallbackUrl = (urlOrPath: string): string =>
  `/login?redirect=${encodeURIComponent(createLoginRedirectPath(urlOrPath))}`;
