import { cva, VariantProps } from "cva";
import { forwardRef, useId } from "react";
import { ConditionalError } from "~/components/ConditionalError";
import { sharedInputStyles } from "~/ui-library/shared-styles/shared-input-styles";

const InputContainerStyles = cva(["flex min-w-0 flex-col gap-xxs"], {
  variants: {
    fullWidth: {
      true: ["w-full"],
      false: ["w-fit"],
    },
  },
});
const labelStyles = cva("", {
  variants: {
    hideLabel: {
      true: "sr-only",
      false: "",
    },
  },
});

const InputStyles = cva(sharedInputStyles, {
  variants: {
    disabled: {
      true: ["bg-gray-100", "cursor-not-allowed"],
      false: ["hover:outline hover:outline-2 hover:outline-gray-300"],
    },
    fullWidth: {
      true: ["w-full"],
      false: ["w-fit"],
    },
  },
});

export type InputProps = JSX.IntrinsicElements["input"] &
  VariantProps<typeof InputStyles> &
  React.RefAttributes<HTMLInputElement> & {
    containerClassName?: string;
    error?: string;
    hideLabel?: boolean;
    label?: string;
  };

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      containerClassName,
      className,
      disabled,
      error,
      fullWidth,
      label,
      hideLabel,
      ...rest
    },
    ref
  ) => {
    const inputId = useId();
    return (
      <div
        className={InputContainerStyles({
          className: containerClassName,
          fullWidth,
        })}
      >
        {label ? (
          <label htmlFor={inputId} className={labelStyles({ hideLabel })}>
            {label}
          </label>
        ) : null}
        <input
          disabled={disabled}
          className={InputStyles({ className, fullWidth, disabled })}
          id={inputId}
          ref={ref}
          {...rest}
        />
        <ConditionalError data-testid="input-error" error={error} />
      </div>
    );
  }
);

Input.displayName = "Input";
