import { cva, VariantProps } from "cva";
import { BodyProps } from "~/ui-library/components/Body";
import { extendedTwMerge } from "~/ui-library/design-tokens/extendedTwMerge";
import {
  sharedPillStyles,
  pillAppearances,
} from "~/ui-library/shared-styles/shared-pill-styles";
import bodyStyles from "~/ui-library/shared-styles/text-body";

const pillStyles = cva([...sharedPillStyles, "px-[0.75rem] py-[0.125rem]"], {
  variants: {
    appearance: {
      ...pillAppearances,
    },
  },
  defaultVariants: {
    appearance: "default",
  },
});

export type PillProps<T extends React.ElementType> = {
  as?: T;
  bold?: boolean;
  children: React.ReactNode;
  className?: string;
  fontSize?: BodyProps["size"];
} & React.ComponentPropsWithoutRef<T> &
  VariantProps<typeof pillStyles>;

export const Pill = <T extends React.ElementType>({
  as,
  appearance,
  bold = true,
  children,
  className,
  fontSize,
  ...rest
}: PillProps<T>) => {
  const Component = as || "div";

  return (
    <Component
      {...rest}
      className={extendedTwMerge(
        bodyStyles({ bold, size: fontSize }),
        pillStyles({ appearance }),
        className
      )}
    >
      {children}
    </Component>
  );
};
