import axios, { AxiosError, AxiosHeaders } from "axios";
import { constants } from "~/common/constants";
import { retrieveCsrfToken } from "~/lib/client/retrieveCsrfToken";
import { EndilCsrfTokenError } from "~/lib/shared/endilErrors";

/**
 * Endil API Client with CSRF protection built in
 */
export const endilApiClient = axios.create({
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

endilApiClient.interceptors.request.use(async (options) => {
  const csrfToken = await retrieveCsrfToken();
  (options.headers as AxiosHeaders).set(constants.CSRF_HEADER_NAME, csrfToken);
  return options;
});

endilApiClient.interceptors.response.use(
  (response) => response,
  async (error: AxiosError<{ error?: { code?: string } }>) => {
    if (!error.config || !error.response?.data?.error?.code) {
      throw error;
    }
    if (error.response.data.error.code === EndilCsrfTokenError.code) {
      const { config } = error;
      const csrfToken = await retrieveCsrfToken(true);
      (config.headers as AxiosHeaders)?.set(
        constants.CSRF_HEADER_NAME,
        csrfToken
      );
      return axios(config);
    }
    throw error;
  }
);
