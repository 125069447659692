import { useRouter } from "next/router";
import { useEffect } from "react";
import { PianoSDKStatus, usePiano } from "~/components/Piano/PianoContext";
import { useGlobalModals } from "~/components/GlobalState";

export const RouteChangeHandler: React.FC = () => {
  const { piano, pianoStatus } = usePiano();
  const router = useRouter();
  const { closeAllModals } = useGlobalModals();

  useEffect(() => {
    const handleRouteChange = () => {
      closeAllModals();
      if (pianoStatus === PianoSDKStatus.Ready) {
        piano.offer.close();
      }
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [router, piano, pianoStatus, closeAllModals]);

  return null;
};
