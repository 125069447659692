import {
  NavigationBarLink,
  NavigationDisclosureItem,
  NavigationDisclosureListItem,
} from "@mci/cross-platform-components";
import Link from "next/link";
import {
  SideNavigationGroupItem,
  SideNavigationItem,
} from "~/components/Layout/LayoutSideNavigation/sideNavMenuData";
import { useSideNavigationAnalytics } from "~/components/Layout/LayoutSideNavigation/useSideNavigationAnalytics";

const isGroupItem = (item: any): item is SideNavigationGroupItem =>
  Boolean(item.childItems);

export const SideNavigationListItem = ({
  item,
}: {
  item: SideNavigationItem | SideNavigationGroupItem;
}) => {
  const trackNavItemClicked = useSideNavigationAnalytics();
  if (isGroupItem(item)) {
    const { childItems, icon, label } = item;
    return (
      <NavigationDisclosureItem
        label={label}
        icon={icon}
        as="button"
        listItems={childItems.map((childItem, idx) => (
          <NavigationDisclosureListItem
            key={`item.link-${idx}`}
            label={childItem.label}
            as={Link}
            href={childItem.link}
            onClick={() => {
              trackNavItemClicked(childItem.label);
            }}
          />
        ))}
      />
    );
  }

  return (
    <NavigationBarLink
      as={Link}
      href={item.link}
      activeIcon={null}
      inactiveIcon={item.icon}
      label={item.label}
      onClick={() => {
        trackNavItemClicked(item.label);
      }}
    />
  );
};
