import { useMemo } from "react";
import {
  convertHTMLToComponents,
  type TagToClassNameMap,
} from "~/lib/shared/html-utils";
import { TagToReactConverters } from "~/lib/shared/html-utils/tag-to-react-converters";

export type BuildHTMLProps = {
  /** Respect style attributes for spans within the component. */
  allowStyles?: boolean;
  /**
   * A string containing potential html fragments and/or escaped characters.
   *
   * The string will be converted and rendered as HTML. Further implementation details can be found in
   * [html-to-components.tsx](https://gitlab.morningconsult.com/connection-portfolio/cxp/endil/-/blob/main/lib/shared/html-to-components/html-to-components.tsx)
   *
   * @example
   * // Usage:
   * <BuildHtml html="<p><a href='/foo'>This is a link</a> &amp; this is not.</p>" />;
   *
   * // HTML Output:
   * <p>
   *   <a href="/foo"> This is a link </a> & this is not.
   * </p>
   */
  html: string | undefined | null;
  /**
   * Map object defining expected tags (`a`, `h2`, `p`, etc.) with class names to apply to each element.
   * 
   * The classes for each element are **appended** to a list of default classes applied 
   * 
   * @example
   * // Usage:
   * import { TagToClassNameMap } from "~/lib/shared/html-utils";

   * const map: TagToClassNameMap = new Map([
   *   ["h2", "color-danger-800"],
   *   ["p", "text-body-xsmall"],
   * ])
   * <BuildHtml html="<h2>scary text</h2> <p>lil text</p>" />;
   * 
   * // HTML Output:
   * <h2 className="text-headline2 color-danger-800">
   *   scary text
   * </h2>
   * <p className="text-body-xsmall">
   *   lil text
   * </p>
   */
  tagToClassNameMap?: TagToClassNameMap;
  tagToReactConverters?: TagToReactConverters;
};

export const BuildHTML: React.FC<BuildHTMLProps> = ({
  allowStyles,
  html,
  tagToClassNameMap,
  tagToReactConverters,
}) => {
  const render = useMemo(
    () =>
      html
        ? convertHTMLToComponents(
            html,
            tagToClassNameMap,
            tagToReactConverters,
            {
              allowStyleAttribute: allowStyles,
            }
          )
        : html,
    [allowStyles, html, tagToClassNameMap]
  );

  return <>{render}</>;
};
