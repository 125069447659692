import { z } from "zod";

export const FIRST_NAME_FIELD_SCHEMA = z
  .string()
  .min(1, { message: "First name is required" });

export const LAST_NAME_FIELD_SCHEMA = z
  .string()
  .min(1, { message: "Last name is required" });

export const EMAIL_FIELD_SCHEMA = z
  .string()
  .email({ message: "Valid email address is required" });
