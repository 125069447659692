/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { cva, cx, VariantProps } from "cva";
import { extendedTwMerge } from "~/ui-library/design-tokens/extendedTwMerge";
import { overlayStyles } from "~/ui-library/shared-styles/shared-overlay-styles";

const containerStyles = cva(["fixed"], {
  defaultVariants: {
    priority: "high",
  },
  variants: {
    priority: {
      high: "z-50",
      low: "z-30",
    },
    isOpen: {
      true: ["pointer-events-auto"],
      false: ["pointer-events-none"],
    },
    fillScreen: {
      true: "",
      false: "",
    },
    showOverlay: {
      true: "",
      false: "",
    },
  },
  compoundVariants: [
    {
      fillScreen: true,
      className: "top-[64px]",
    },
    {
      showOverlay: true,
      className: "top-[64px]",
    },
  ],
});
const flyoutStyles = cva(
  ["fixed", "z-20", "overflow-auto", "transition-all duration-default"],
  {
    defaultVariants: {
      appearance: "default",
      fillScreen: false,
      openFromDirection: "left",
      priority: "high",
    },
    variants: {
      appearance: {
        default: "bg-white",
        primary: "bg-primary-50",
      },
      isOpen: {
        true: [],
        false: [],
      },
      openFromDirection: {
        left: ["left-0 h-[calc(100%-64px)]"],
        right: ["right-0 h-[calc(100%-64px)]"],
        top: ["inset-x-0"],
        bottom: ["inset-x-0 bottom-0"],
      },
      /** When true, fill the entire screen by expanding horizontally or vertically.
       * When false, shrink to the contents with a max width/height set.
       */
      fillScreen: {
        true: ["w-screen", "h-screen"],
        false: [],
      },
      showOverlay: {
        true: "shadow-1",
        false: "shadow-3",
      },
      priority: {
        high: "",
        low: "",
      },
    },
    compoundVariants: [
      {
        isOpen: true,
        openFromDirection: "bottom",
        className: "",
      },
      {
        isOpen: false,
        openFromDirection: "bottom",
        className: "translate-y-[100%]",
      },
      // shrink the size of the flyout to fit the contents
      {
        openFromDirection: "bottom",
        fillScreen: false,
        className: "h-fit max-h-screen",
      },
      {
        isOpen: true,
        openFromDirection: "left",
        className: "translate-x-[0%]",
      },
      {
        isOpen: false,
        openFromDirection: "left",
        className: "-translate-x-[150%]",
      },
      {
        isOpen: true,
        openFromDirection: "right",
        className: "translate-x-[0%]",
      },
      {
        isOpen: false,
        openFromDirection: "right",
        className: "translate-x-[150%]",
      },
      {
        isOpen: true,
        openFromDirection: "top",
        className: "translate-y-[0%]",
      },
      {
        isOpen: false,
        openFromDirection: "top",
        className: "-translate-y-[150%]",
      },
      // shrink the size of the flyout to fit the contents
      {
        openFromDirection: "top",
        fillScreen: false,
        className: "h-fit max-h-screen",
      },
      {
        openFromDirection: "left",
        fillScreen: false,
        className: "h-fit w-fit",
      },
    ],
  }
);

export interface FlyoutProps extends VariantProps<typeof flyoutStyles> {
  children: React.ReactNode;
  /** class added to the relative parent of the flyout */
  className?: string;
  /** class added to the absolutely positioned flyout element */
  flyoutClassName?: string;
  overlayClassName?: string;
  showOverlay?: boolean;
  closeOnOverlayClick?: boolean;
  onClose?: () => void;
}

export function Flyout({
  appearance,
  children,
  className,
  fillScreen = false,
  flyoutClassName,
  overlayClassName,
  isOpen = false,
  openFromDirection,
  showOverlay = true,
  closeOnOverlayClick = false,
  priority,
  onClose,
}: FlyoutProps) {
  return (
    <div
      aria-hidden={!isOpen}
      data-testid={isOpen ? "flyout-open" : "flyout-closed"}
      className={containerStyles({
        className,
        isOpen,
        fillScreen,
        showOverlay,
        priority,
      })}
    >
      <div
        className={extendedTwMerge(
          flyoutStyles({
            appearance,
            fillScreen,
            isOpen,
            openFromDirection,
            showOverlay,
          }),
          flyoutClassName
        )}
      >
        {isOpen ? children : null}
      </div>
      <div
        aria-hidden={!isOpen}
        className={cx(
          overlayStyles({
            isVisible: isOpen && showOverlay,
          }),
          "z-10",
          overlayClassName
        )}
        onClick={() => {
          if (closeOnOverlayClick) {
            onClose?.();
          }
        }}
      ></div>
    </div>
  );
}
