import { useDocument } from "~/lib/client/useDocument";

export const useCookie = (name: string): string => {
  const doc = useDocument();
  const cookies = doc ? doc.cookie : "";
  const parts = `; ${cookies}`.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts[1].split(";").shift()!;
  }
  return "";
};
