import { cx } from "cva";
import NextLink from "next/link";
import bodyStyles from "~/ui-library/shared-styles/text-body";
import { NavigationMenu } from "~/components/NavigationMenu";
import { NavigationMenuGroup } from "~/components/NavigationMenuGroup";
import { Body } from "~/ui-library";
import {
  NavigationGroupItem,
  NavigationItem,
  NavigationResponse,
} from "~/types/asgard/MenuResponse";
import { NavigationMenuLink } from "~/components/NavigationMenuLink";
import { useAnalytics } from "~/components/Analytics";

export type LayoutDesktopToolbarProps = {
  menuData: NavigationResponse;
};

const isNavigationGroupItem = (item: any): item is NavigationGroupItem =>
  Boolean(item.child_items);

const linkStyle = cx(
  bodyStyles({ bold: true }),
  "flex items-center",
  "hover:bg-gray-100",
  "px-xs"
);

type ListItemProps = {
  item: NavigationGroupItem | NavigationItem;
  onItemClick: (item: NavigationItem) => void;
};

const ListItem = ({ item, onItemClick }: ListItemProps) => {
  if (isNavigationGroupItem(item)) {
    return (
      <div>
        <NavigationMenuGroup label={item.label} count={item.child_items.length}>
          {item.child_items.map((link, linkIndex) => (
            <ListItem item={link} key={linkIndex} onItemClick={onItemClick} />
          ))}
        </NavigationMenuGroup>
      </div>
    );
  }
  return (
    <NavigationMenuLink href={item.link} onClick={() => onItemClick(item)}>
      <Body size="small">{item.label}</Body>
    </NavigationMenuLink>
  );
};

const MenuItem = ({ item }: { item: NavigationGroupItem | NavigationItem }) => {
  const { analyticsEnhancedTrack } = useAnalytics();

  const onListItemClick: ListItemProps["onItemClick"] = ({ label }) =>
    analyticsEnhancedTrack("Nav Item Clicked", {
      title: label,
    });

  // use a different layout if there are nested group items
  if (
    isNavigationGroupItem(item) &&
    item.child_items.some((child) => isNavigationGroupItem(child))
  ) {
    return (
      <NavigationMenu buttonLabel={item.label}>
        <div className="flex gap-x-20 pl-m pr-xl">
          {item.child_items.map((link, linkIndex) => (
            <ListItem
              item={link}
              key={linkIndex}
              onItemClick={onListItemClick}
            />
          ))}
        </div>
      </NavigationMenu>
    );
  }

  if (isNavigationGroupItem(item)) {
    return (
      <NavigationMenu buttonLabel={item.label}>
        <NavigationMenuGroup count={item.child_items.length}>
          {item.child_items.map((link, linkIndex) => (
            <ListItem
              item={link}
              key={linkIndex}
              onItemClick={onListItemClick}
            />
          ))}
        </NavigationMenuGroup>
      </NavigationMenu>
    );
  }
  return (
    <NextLink
      href={item.link}
      className={linkStyle}
      onClick={() => onListItemClick(item)}
    >
      <Body bold size="small">
        {item.label}
      </Body>
    </NextLink>
  );
};

export function LayoutDesktopToolbar({ menuData }: LayoutDesktopToolbarProps) {
  return (
    <div className="flex gap-s">
      {menuData.map((item, itemIndex) => (
        <MenuItem key={itemIndex} item={item} />
      ))}
    </div>
  );
}
