import { useRouter } from "next/router";
import React, { createContext, useContext, useRef, useState } from "react";

type TopNavContextType = {
  menuIsOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
  searchIsOpen: boolean;
  openSearch: () => void;
  closeSearch: () => void;
  toggleMenu: () => void;
  toggleSearch: () => void;
  menuButtonRef: React.Ref<HTMLButtonElement>;
  searchButtonRef: React.Ref<HTMLButtonElement>;

  searchValue: string;
  setSearchValue: (val: string) => void;
  /** trigger a router.push() to the search page based on the passed in value */
  search: (value: string) => void;
};

const TopNavContext = createContext<TopNavContextType | undefined>(undefined);

export interface NavigationProviderProps {
  children: React.ReactNode;
  onSetMenuIsOpen?: (nextMenuIsOpen: boolean) => void;
  onSetSearchIsOpen?: (nextSearchIsOpen: boolean) => void;
  initialState?: {
    menuIsOpen?: boolean;
    searchIsOpen?: boolean;
    searchValue?: string;
  };
}

export const NavigationProvider: React.FC<NavigationProviderProps> = ({
  children,
  initialState,
  onSetMenuIsOpen,
  onSetSearchIsOpen,
}) => {
  const router = useRouter();

  /** Simply the UI driven value, this should not be passed to the search function below */
  const [searchValue, setSearchValue] = useState(
    initialState?.searchValue ?? ""
  );

  const search = (searchFor: string) =>
    router.push(`/search?query=${searchFor}`);

  const [menuIsOpen, baseSetMenuIsOpen] = useState(
    () => !!initialState?.menuIsOpen
  );
  const [searchIsOpen, baseSetSearchIsOpen] = useState(
    () => !!initialState?.searchIsOpen
  );

  const setMenuIsOpen = (nextMenuIsOpen: boolean) => {
    if (onSetMenuIsOpen) onSetMenuIsOpen(nextMenuIsOpen);
    baseSetMenuIsOpen(nextMenuIsOpen);
  };
  const setSearchIsOpen = (nextSearchIsOpen: boolean) => {
    if (onSetSearchIsOpen) onSetSearchIsOpen(nextSearchIsOpen);
    baseSetSearchIsOpen(nextSearchIsOpen);
  };

  const menuButtonRef = useRef<HTMLButtonElement>(null);
  const searchButtonRef = useRef<HTMLButtonElement>(null);

  const openMenu = () => {
    setSearchIsOpen(false);
    setMenuIsOpen(true);
  };

  const closeMenu = () => setMenuIsOpen(false);

  const openSearch = () => {
    setMenuIsOpen(false);
    setSearchIsOpen(true);
  };

  const closeSearch = () => setSearchIsOpen(false);

  const toggleSearch = () => (searchIsOpen ? closeSearch() : openSearch());

  const toggleMenu = () => (menuIsOpen ? closeMenu() : openMenu());

  return (
    <TopNavContext.Provider
      value={{
        openMenu,
        openSearch,
        closeMenu,
        closeSearch,
        toggleSearch,
        toggleMenu,
        menuIsOpen,
        searchIsOpen,
        menuButtonRef,
        searchButtonRef,
        searchValue,
        setSearchValue,
        search,
      }}
    >
      {children}
    </TopNavContext.Provider>
  );
};

export function useTopNav() {
  const context = useContext(TopNavContext);
  if (context === undefined) {
    throw new Error("useTopNav must be used within NavigationProvider");
  }
  return context;
}
