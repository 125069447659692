import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useState,
} from "react";

export type GlobalStateData = {
  openModalIds: Set<string>;
  setOpenModalIds: Dispatch<SetStateAction<Set<string>>>;
  shouldShowRegisterAccountModal: boolean;
  setShouldShowRegisterAccountModal: Dispatch<SetStateAction<boolean>>;
};

const GlobalContext = createContext<GlobalStateData | null>(null);

export const useGlobalState = () => {
  const context = useContext(GlobalContext);

  if (context == null) {
    throw new Error(
      "useGlobalState must be wrapped by a GlobalStateProvider component!"
    );
  }

  return context;
};

export const GlobalStateProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [openModalIds, setOpenModalIds] = useState<Set<string>>(new Set());
  const [shouldShowRegisterAccountModal, setShouldShowRegisterAccountModal] =
    useState(false);

  return (
    <GlobalContext.Provider
      value={{
        openModalIds,
        setOpenModalIds,
        shouldShowRegisterAccountModal,
        setShouldShowRegisterAccountModal,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
