export const TrendChartIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7650_93351)">
      <path
        d="M1.73324 22.6667H24.3999V24H0.399902V0H1.73324V17.7233L9.06657 10.39L14.3999 15.7233L18.5949 11.5283L19.5382 12.4717L14.3999 17.61L9.06657 12.2767L1.73324 19.61V22.6667ZM21.0666 5.33333H22.3999C22.5767 5.33333 22.7463 5.40357 22.8713 5.5286C22.9963 5.65362 23.0666 5.82319 23.0666 6C23.0666 6.17681 22.9963 6.34638 22.8713 6.4714C22.7463 6.59643 22.5767 6.66667 22.3999 6.66667H19.7332V8H21.0666V9.33333H22.3999V8C22.9303 8 23.439 7.78929 23.8141 7.41421C24.1892 7.03914 24.3999 6.53043 24.3999 6C24.3999 5.46957 24.1892 4.96086 23.8141 4.58579C23.439 4.21071 22.9303 4 22.3999 4H21.0666C20.8898 4 20.7202 3.92976 20.5952 3.80474C20.4701 3.67971 20.3999 3.51014 20.3999 3.33333C20.3999 3.15652 20.4701 2.98695 20.5952 2.86193C20.7202 2.7369 20.8898 2.66667 21.0666 2.66667H23.7332V1.33333H22.3999V0H21.0666V1.33333C20.5361 1.33333 20.0274 1.54405 19.6524 1.91912C19.2773 2.29419 19.0666 2.8029 19.0666 3.33333C19.0666 3.86377 19.2773 4.37247 19.6524 4.74755C20.0274 5.12262 20.5361 5.33333 21.0666 5.33333Z"
        fill="#4657CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_7650_93351">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.399902)"
        />
      </clipPath>
    </defs>
  </svg>
);
