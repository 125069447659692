import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChevronRegisteredWhite = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 40.6 31.1"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M0 0v11.7l15.5 15.5L31 11.7V0L15.5 15.5zm31.1 31.1V19.4L19.4 31.1zM0 31.1h11.7L0 19.4zM38.1 0c-1.4 0-2.6 1.2-2.6 2.6 0 1.4 1.2 2.6 2.6 2.6 1.4 0 2.6-1.2 2.6-2.6-.1-1.5-1.2-2.6-2.6-2.6m1.3 4.1-.8-1.2c.3-.1.7-.4.7-.9s-.4-1-1-1H37v3h.6V3h.4l.7 1.1zc-.4.3-.8.5-1.3.5-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2c0 .6-.3 1.1-.7 1.5m-1.1-1.7h-.7v-.8h.7c.2 0 .4.2.4.4s-.2.4-.4.4"
      style={{
        fill: "#fff",
      }}
    />
  </svg>
);
export default SvgChevronRegisteredWhite;
