import { cva, VariantProps } from "cva";

const avatarStyles = cva(
  [
    "flex items-center justify-center",
    "size-[40px] rounded-full border-4 p-s",
    "bg-primary-200",
    "border-solid hover:border-gray-300 active:border-primary-300",
    "transition-colors duration-default",
  ],
  {
    variants: {
      active: {
        true: ["border-primary-300"],
        false: ["border-white"],
      },
    },
  }
);

export type AvatarProps<T extends React.ElementType> = {
  as?: T;
  children: React.ReactNode;
} & VariantProps<typeof avatarStyles>;

export const Avatar = <T extends React.ElementType>({
  active,
  as,
  children,
}: AvatarProps<T>) => {
  const Component = as || "div";
  return <Component className={avatarStyles({ active })}>{children}</Component>;
};
