import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgIconLogoWhite = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 83 83"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <circle
      cx={41.5}
      cy={41.5}
      r={41.5}
      style={{
        fill: "#fff",
      }}
    />
    <path
      d="M27 26.8v11.3l15 15 15-15V26.8l-15 15zm30 30V45.6L45.8 56.8zm-30 0h11.2L27 45.6z"
      style={{
        fill: "#231f20",
      }}
    />
  </svg>
);
export default SvgIconLogoWhite;
