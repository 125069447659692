import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMcIntelligenceLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={105}
    height={18}
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      fill="#2B2B2B"
      fillRule="evenodd"
      d="M.5 3v4.514l5.98 5.981 5.981-5.98V3l-5.98 5.98zm12 12v-4.514L7.986 15zm-12 0h4.514L.5 10.486zM15.201 3c-.54 0-1.003.463-1.003 1.003 0 .54.463 1.003 1.003 1.003.54 0 1.003-.463 1.003-1.003C16.166 3.424 15.741 3 15.201 3m.502 1.582-.31-.463c.117-.039.271-.154.271-.347a.387.387 0 0 0-.386-.386h-.502v1.157h.232v-.385h.154l.27.424zc-.154.116-.308.193-.501.193a.774.774 0 0 1-.772-.772c0-.424.348-.771.772-.771.424 0 .772.347.772.771 0 .232-.116.425-.27.579m-.425-.656h-.27v-.309h.27c.077 0 .154.078.154.155a.166.166 0 0 1-.154.154"
      clipRule="evenodd"
    />
    <path
      fill="url(#a)"
      d="M21.474 12V5.33h1.17V12zm8.738 0-3.48-4.86V12h-1.17V5.33h1.2l3.41 4.73V5.33h1.17V12zm5.637 0V6.36h-2.02V5.33h5.21v1.03h-2.02V12zm5.681 0V5.33h4.57v1.03h-3.4v1.73h3.33v1.03H42.7v1.85h3.4V12zm7.272 0V5.33h1.17v5.64h2.94V12zm6.666 0V5.33h1.17v5.64h2.94V12zm6.666 0V5.33h1.17V12zm3.788-3.33c0-2.09 1.57-3.45 3.5-3.45 1.3 0 2.15.62 2.69 1.39l-.96.54c-.35-.5-.98-.89-1.73-.89-1.32 0-2.3 1.01-2.3 2.41 0 1.4.98 2.42 2.3 2.42.68 0 1.28-.32 1.58-.6V9.45h-1.99V8.42h3.16v2.5c-.66.73-1.59 1.21-2.75 1.21-1.93 0-3.5-1.37-3.5-3.46M74.89 12V5.33h4.57v1.03h-3.4v1.73h3.33v1.03h-3.33v1.85h3.4V12zm11.921 0-3.48-4.86V12h-1.17V5.33h1.2l3.41 4.73V5.33h1.17V12zm7.197.12c-1.92 0-3.45-1.4-3.45-3.45 0-2.05 1.53-3.45 3.45-3.45 1.4 0 2.23.7 2.73 1.5l-1 .51c-.32-.55-.96-.97-1.73-.97-1.27 0-2.25 1.01-2.25 2.41 0 1.4.98 2.41 2.25 2.41.77 0 1.41-.41 1.73-.97l1 .51c-.51.8-1.33 1.5-2.73 1.5m5.167-.12V5.33h4.57v1.03h-3.4v1.73h3.33v1.03h-3.33v1.85h3.4V12z"
    />
    <defs>
      <linearGradient
        id="a"
        x1={20.5}
        x2={105.448}
        y1={18}
        y2={8.322}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F18F9" />
        <stop offset={1} stopColor="#0DD6CC" />
      </linearGradient>
    </defs>
  </svg>
);
export default SvgMcIntelligenceLogo;
