import { VariantProps, cva, cx } from "cva";
import { extendedTwMerge } from "~/ui-library/design-tokens/extendedTwMerge";

const dividerStyles = cva("absolute h-[1px] border-b-1 border-gray-300", {
  defaultVariants: {
    inset: "none",
  },
  variants: {
    inset: {
      0: "inset-x-0",
      none: "inset-x-0",
      xxs: "-inset-x-xxs",
      xs: "-inset-x-xs",
      s: "-inset-x-s",
      m: "-inset-x-m",
      l: "-inset-x-l",
      xl: "-inset-x-xl",
      xxl: "-inset-x-xxl",
    },
  },
});

export type DividerProps = {
  className?: string;
  containerClassName?: string;
} & Omit<JSX.IntrinsicElements["div"], "children"> &
  VariantProps<typeof dividerStyles>;

export const Divider = ({
  className,
  containerClassName,
  inset,
  ...rest
}: DividerProps) => (
  <div className={cx(containerClassName, "relative h-[1px]")} {...rest}>
    <div className={extendedTwMerge(dividerStyles({ inset }), className)} />
  </div>
);
