export const PoliticsIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7650_93405)">
      <path
        d="M24.2002 22.6667V24H0.200195V22.6667H24.2002ZM20.8669 20.6667H3.53353V19.3333H5.53353V14.6667H3.53353V14C3.53614 11.8179 4.36101 9.7169 5.84372 8.11589C7.32642 6.51488 9.35803 5.53146 11.5335 5.36167V0H17.5335V4H12.8669V5.36167C15.0424 5.53146 17.074 6.51488 18.5567 8.11589C20.0394 9.7169 20.8643 11.8179 20.8669 14V14.6667H18.8669V19.3333H20.8669V20.6667ZM16.2002 1.33333H12.8669V2.66667H16.2002V1.33333ZM17.5335 14.6667H14.8669V19.3333H17.5335V14.6667ZM10.8669 14.6667V19.3333H13.5335V14.6667H10.8669ZM4.89686 13.3333H19.5035C19.3382 11.5106 18.4972 9.81561 17.1458 8.58132C15.7945 7.34702 14.0304 6.66265 12.2002 6.66265C10.37 6.66265 8.60593 7.34702 7.25457 8.58132C5.90322 9.81561 5.06224 11.5106 4.89686 13.3333ZM6.86686 19.3333H9.53353V14.6667H6.86686V19.3333Z"
        fill="#4657CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_7650_93405">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.200195)"
        />
      </clipPath>
    </defs>
  </svg>
);
