import { SessionContextValue } from "next-auth/react";
import NextLink from "next/link";
import { Divider } from "~/components/Divider";
import {
  navigationMenuButtonStyles,
  outerListStyles,
} from "~/components/Layout/shared-layout-styles";
import { LoginButton } from "~/components/LoginButton";
import { useTopNav } from "~/components/NavigationProvider";
import { commonConfig } from "~/config/common-config";
import { Headline } from "~/ui-library";
import { ButtonLink } from "~/ui-library/components/ButtonLink/ButtonLink";
import { MCIButton } from "~/ui-library/components/MCIButton";
import { buttonStyles } from "~/ui-library/shared-styles/shared-button-styles";
import { LayoutUserActionsSkeleton } from "../LayoutUserActionsSkeleton/LayoutUserActionsSkeleton";

export type LayoutMobileUserToolbarProps = {
  hasMciAccess?: boolean;
  /** The user's current status - This will drive what is rendered for the component */
  status: SessionContextValue["status"] | "subscribed";
};

const SignInToolbarButton: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { closeMenu } = useTopNav();
  return (
    <LoginButton
      behavior={{ type: "redirect" }}
      className={buttonStyles({
        disabled,
        fullWidth: true,
        variant: "default",
      })}
      onClick={closeMenu}
    >
      Sign in
    </LoginButton>
  );
};

const SubscribeButton: React.FC<{ disabled?: boolean }> = ({
  disabled = false,
}) => {
  const { closeMenu } = useTopNav();

  return (
    <ButtonLink
      disabled={disabled}
      fullWidth
      href="/subscribe"
      onClick={closeMenu}
      variant="primary"
    >
      Subscribe
    </ButtonLink>
  );
};

const MyAccount = () => (
  <div>
    <Headline blockCase className="mb-xs p-xs text-gray-800" level={6}>
      My Account
    </Headline>
    <div className={outerListStyles}>
      <NextLink
        className={navigationMenuButtonStyles({ bold: true })}
        href="/account"
      >
        Account Settings
      </NextLink>
    </div>
  </div>
);

const SwitchToMci = () => (
  <>
    <Divider inset="s" />
    <div className="flex flex-col gap-xs">
      <Headline blockCase className="mb-xs p-xs text-gray-800" level={6}>
        Switch To
      </Headline>
      <div>
        <MCIButton
          href={commonConfig.NEXT_PUBLIC_MCI_LOGIN_URL}
          variant="outline"
        />
      </div>
    </div>
  </>
);

/** Component that will render different actionable items for the user to take based on their auth/subscription status */
export const LayoutMobileUserToolbar: React.FC<
  LayoutMobileUserToolbarProps
> = ({ hasMciAccess, status }) => {
  if (status === "authenticated") {
    return (
      <div className="flex flex-col gap-m">
        <MyAccount />
        <SubscribeButton />
        {hasMciAccess ? <SwitchToMci /> : null}
      </div>
    );
  }

  if (status === "subscribed") {
    return (
      <div className="flex flex-col gap-m">
        <MyAccount />
        {hasMciAccess ? <SwitchToMci /> : null}
      </div>
    );
  }

  if (status === "unauthenticated") {
    return (
      <>
        <div className="flex gap-s">
          <SignInToolbarButton />
          <SubscribeButton />
        </div>
      </>
    );
  }

  /** In the event we are loading or are in an unknown state, render a disabled unauthenticated state  */
  return (
    <div className="flex gap-s">
      <LayoutUserActionsSkeleton />
    </div>
  );
};
