export const HotelIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7650_93399)">
      <path
        d="M10 14C10 13.4726 9.8436 12.957 9.55059 12.5185C9.25757 12.0799 8.84109 11.7382 8.35382 11.5363C7.86655 11.3345 7.33038 11.2817 6.81309 11.3846C6.29581 11.4875 5.82066 11.7414 5.44772 12.1144C5.07478 12.4873 4.8208 12.9625 4.71791 13.4798C4.61501 13.997 4.66782 14.5332 4.86965 15.0205C5.07149 15.5078 5.41328 15.9242 5.85181 16.2173C6.29034 16.5103 6.80592 16.6667 7.33333 16.6667C8.04058 16.6667 8.71885 16.3857 9.21895 15.8856C9.71905 15.3855 10 14.7072 10 14ZM6 14C6 13.7363 6.0782 13.4785 6.22471 13.2592C6.37122 13.04 6.57945 12.8691 6.82309 12.7682C7.06672 12.6672 7.33481 12.6408 7.59345 12.6923C7.8521 12.7437 8.08967 12.8707 8.27614 13.0572C8.46261 13.2437 8.5896 13.4812 8.64105 13.7399C8.69249 13.9985 8.66609 14.2666 8.56517 14.5102C8.46426 14.7539 8.29336 14.9621 8.07409 15.1086C7.85483 15.2551 7.59704 15.3333 7.33333 15.3333C6.97971 15.3333 6.64057 15.1929 6.39052 14.9428C6.14048 14.6928 6 14.3536 6 14ZM21.3333 21.3333V18C21.3333 16.7623 20.8417 15.5753 19.9665 14.7002C19.0913 13.825 17.9043 13.3333 16.6667 13.3333H10.6667V17.3333H4V11.3333H2.66667V21.3333H4V18.6667H20V21.3333H21.3333ZM12 14.6667H16.6667C17.4351 14.6665 18.18 14.9319 18.7753 15.4178C19.3705 15.9037 19.7796 16.5804 19.9333 17.3333H12V14.6667ZM24 22.6667V24H0V22.6667H24ZM0.795 9.87167L0 8.795L12 0L24 8.78833L23.2117 9.865L12 1.66667L0.795 9.87167Z"
        fill="#4657CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_7650_93399">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
