import { cva, VariantProps } from "cva";
import React, { ElementType } from "react";
import { extendedTwMerge } from "~/ui-library/design-tokens/extendedTwMerge";

const headlineStyles = cva(["font-semibold"], {
  variants: {
    blockCase: {
      true: ["uppercase"],
    },
    /** The level to render the headline breakpoints at xs+  */
    level: {
      0: ["text-headline0 leading-headline0"],
      1: ["text-headline1 leading-headline1"],
      2: ["text-headline2 leading-headline2"],
      3: ["text-headline3 leading-headline3"],
      4: ["text-headline4 leading-headline4"],
      5: ["text-headline5 leading-headline5"],
      6: ["text-headline6 leading-headline6"],
      7: ["text-headline7 leading-headline7"],
    },
    /** The level to render the headline at breakpoints md+. This will take precedent over the css applied by the level prop.  */
    mdLevel: {
      0: ["md:text-headline0 md:leading-headline0"],
      1: ["md:text-headline1 md:leading-headline1"],
      2: ["md:text-headline2 md:leading-headline2"],
      3: ["md:text-headline3 md:leading-headline3"],
      4: ["md:text-headline4 md:leading-headline4"],
      5: ["md:text-headline5 md:leading-headline5"],
      6: ["md:text-headline6 md:leading-headline6"],
      7: ["md:text-headline7 md:leading-headline7"],
    },
  },
  compoundVariants: [
    {
      blockCase: true,
      level: 0,
      className: "tracking-headline0-block",
    },
    {
      blockCase: true,
      level: 1,
      className: "tracking-headline1-block",
    },
    {
      blockCase: true,
      level: 2,
      className: "tracking-headline2-block",
    },
    {
      blockCase: true,
      level: 3,
      className: "tracking-headline3-block",
    },
    {
      blockCase: true,
      level: 4,
      className: "tracking-headline4-block",
    },
    {
      blockCase: true,
      level: 5,
      className: "tracking-headline5-block",
    },
    {
      blockCase: true,
      level: 6,
      className: "tracking-headline6-block",
    },
    {
      blockCase: true,
      level: 7,
      className: "tracking-headline7-block",
    },
  ],
});

type HeadlineLevel = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7;

export type HeadlineElementType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type HeadlineProps<T extends ElementType = HeadlineElementType> = {
  as?: T;
  children: React.ReactNode;
  className?: string;
  level: HeadlineLevel;
} & React.ComponentPropsWithoutRef<T> &
  VariantProps<typeof headlineStyles>;

export function Headline<T extends ElementType>({
  as,
  blockCase,
  children,
  className,
  mdLevel,
  level,
  ...rest
}: HeadlineProps<T>) {
  const Component = as || "h3";

  return (
    <Component
      {...rest}
      className={extendedTwMerge(
        headlineStyles({ blockCase, mdLevel, level }),
        className
      )}
    >
      {children}
    </Component>
  );
}
