import { NavigationBarGroup } from "@mci/cross-platform-components";
import { SideNavigationListItem } from "~/components/Layout/LayoutSideNavigation/SideNavigationListItem";
import {
  SideNavigationGroupItem,
  SideNavigationItem,
} from "~/components/Layout/LayoutSideNavigation/sideNavMenuData";

export const SideNavigationListGroup = ({
  items,
}: {
  items: (SideNavigationItem | SideNavigationGroupItem)[];
}) => (
  <NavigationBarGroup>
    {items.map((item, idx) => (
      <SideNavigationListItem item={item} key={`${item.label}-${idx}`} />
    ))}
  </NavigationBarGroup>
);
