import { Transition } from "@headlessui/react";
import React from "react";

export type MenuTransitionProps = {
  children: React.ReactNode;
  /** whether or not the menu is open */
  isOpen: boolean;
};

export const MenuTransition: React.FC<MenuTransitionProps> = ({
  children,
  isOpen,
}) => (
  <Transition
    as={React.Fragment}
    enter="transition duration-fast"
    enterFrom="transform scale-95 opacity-0"
    enterTo="transform scale-100 opacity-100"
    leave="transition duration-fast"
    leaveFrom="transform scale-100 opacity-100"
    leaveTo="transform scale-95 opacity-0"
    show={isOpen}
  >
    {children}
  </Transition>
);
