import { Menu } from "@headlessui/react";
import { cx } from "cva";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { Divider } from "~/components/Divider";
import { MenuTransition } from "~/components/Menu";
import { NavigationMenuLink } from "~/components/NavigationMenuLink";
import { Avatar, Body } from "~/ui-library";
import { ArrowRightOnRectangleIcon, Cog6ToothIcon } from "~/ui-library/icons";
import { menuItemsStyles } from "~/ui-library/shared-styles/shared-menu-styles";

export const UserMenuContents = () => {
  const { data } = useSession();
  const router = useRouter();
  return (
    <div className="flex max-w-[250px] flex-col break-words">
      <div>
        <Body className="pb-xxs text-center">
          {data?.user.firstName}&nbsp;{data?.user.lastName}
        </Body>
        <Body className="text-gray-800" size="small">
          {data?.user.email}
        </Body>
      </div>
      <Divider containerClassName="my-m" inset="m" />
      <div className="flex flex-col gap-m">
        <NavigationMenuLink
          href={"/account"}
          key="account-settings"
          SvgIcon={Cog6ToothIcon}
          data-testid="account-link"
        >
          Account Settings
        </NavigationMenuLink>

        <NavigationMenuLink
          href="#"
          key="user-signout"
          onClick={(e) => {
            e.preventDefault();
            router.push("/logout");
          }}
          SvgIcon={ArrowRightOnRectangleIcon}
        >
          Sign out
        </NavigationMenuLink>
      </div>
    </div>
  );
};

export type UserMenuButtonProps = {
  /**
   * @example
   * <UserMenuButton initials="NL" />
   */
  isOpen?: boolean;
  initials: string;
};
export function UserMenuButton({ initials, isOpen }: UserMenuButtonProps) {
  return (
    <Menu>
      {({ open }) => (
        <div className="relative w-fit">
          <Menu.Button>
            <Avatar active={open}>{initials}</Avatar>
          </Menu.Button>
          <MenuTransition isOpen={isOpen ?? open}>
            <Menu.Items className={cx("-right-m gap-m", menuItemsStyles)}>
              <div className="p-m">
                <UserMenuContents />
              </div>
            </Menu.Items>
          </MenuTransition>
        </div>
      )}
    </Menu>
  );
}
