import { commonConfig } from "~/config/common-config";

export const SEOConfig = {
  defaultTitle: "Morning Consult | Decision Intelligence & Custom Research",
  titleTemplate: "%s",
  openGraph: {
    type: "website",
    locale: "en_US",
    url: "https://pro.morningconsult.com/",
    siteName: "Morning Consult Pro",
  },
  dangerouslySetAllPagesToNoIndex:
    commonConfig.NEXT_PUBLIC_ENDIL_ENV !== "production",
  dangerouslySetAllPagesToNoFollow:
    commonConfig.NEXT_PUBLIC_ENDIL_ENV !== "production",
};
