import { extendTailwindMerge } from "tailwind-merge";
import config from "./theme";

export const extendedTwMerge = extendTailwindMerge({
  override: {
    classGroups: {
      "font-size": [
        {
          text: Object.keys(config.theme?.fontSize!),
        },
      ],
    },
  },
  extend: {
    theme: {
      padding: Object.keys(config.theme?.extend?.padding!),
      margin: Object.keys(config.theme?.extend?.margin!),
      spacing: Object.keys(config.theme?.extend?.spacing!),
      colors: Object.keys(config.theme?.colors!),
    },
  },
});
