import { commonConfig } from "~/config/common-config";

const whitelistedUrls = ["https://morningconsult.zoom.us"];

export const isUrlSafeForNavigate = (
  urlToTest: string,
  origin: string = commonConfig.NEXT_PUBLIC_ENDIL_URL
): boolean => {
  try {
    const url = new URL(urlToTest);

    return url.origin === origin || whitelistedUrls.includes(url.origin);
  } catch (err) {
    return true;
  }
};
