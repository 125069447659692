import { useGlobalModals } from "~/components/GlobalState";
import { LoginButton } from "~/components/LoginButton";
import { useRegistrationReminderBanner } from "~/components/PersistentState";
import {
  Body,
  Icon,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "~/ui-library";
import { InformationCircleOutlineIcon } from "~/ui-library/icons";

export const RegistrationReminderBanner: React.FC = () => {
  const { registrationReminderEmail } = useRegistrationReminderBanner();
  const { isModalOpen } = useGlobalModals();

  if (!registrationReminderEmail || isModalOpen) {
    return null;
  }

  return (
    <div className="grid min-h-[64px] w-full grid-cols-[1fr,auto,1fr] bg-brand-teal">
      <div className="col-start-2 flex items-center self-center text-center">
        <Body bold as="span">
          You&apos;re almost done!&nbsp;
        </Body>
        Verify your email to finish registering
        <Tooltip placement="bottom">
          <TooltipTrigger>
            <div className="mb-xxs ml-xxs inline-block md:ml-xs">
              <Icon
                className="fill-transparent"
                SvgIcon={InformationCircleOutlineIcon}
              />
            </div>
          </TooltipTrigger>
          <TooltipContent>
            We sent an email to {registrationReminderEmail}. Check your inbox
            and follow the instructions in the email to continue with your
            account.
          </TooltipContent>
        </Tooltip>
      </div>
      <div className="self-center justify-self-end px-s">
        <LoginButton
          behavior={{ type: "window" }}
          size="extraSmall"
          variant="subtle"
        >
          <span className="whitespace-nowrap">Sign in</span>
        </LoginButton>
      </div>
    </div>
  );
};
