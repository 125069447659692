import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useFeatureFlags } from "~/lib/client/useFeatureFlags";
import { PianoSDKStatus, usePiano } from "~/components/Piano/PianoContext";

interface Sluggable {
  slug: string;
}

interface PianoAuthor extends Sluggable {}
interface PianoTag extends Sluggable {}
interface ContentType extends Sluggable {}
interface Vertical extends Sluggable {}

export interface PianoDetailViewPageProps {
  type: "detail";
  contentType: ContentType;
  authors: PianoAuthor[];
  verticals: Vertical[];
  tags: PianoTag[];
  datePublished: string;
  customParams?: Record<string, string | string[]>;
  customVariables?: Record<string, string>;
}

export interface PianoListViewPageProps {
  type: "list";
  tags: PianoTag[];
  customParams?: Record<string, string | string[]>;
  customVariables?: Record<string, string>;
}

export interface PianoSimpleViewPageProps {
  type: "simple";
  customParams?: Record<string, string | string[]>;
  customVariables?: Record<string, string>;
}

export type ExecutePianoProps =
  | PianoDetailViewPageProps
  | PianoListViewPageProps
  | PianoSimpleViewPageProps;

/**
 * Hook is designed to be called in each page.
 *
 * In general, this hook shouldn't be used directly but rather
 * as part of ../PageMeta
 */
export const useExecutePiano = (
  props: ExecutePianoProps
): {
  pianoExecuted: boolean;
} => {
  const { enable_reload_on_login: enableReloadOnLogin } = useFeatureFlags();
  const { status: sessionStatus, data: sessionData } = useSession();
  const [previousSessionStatus, setPreviousSessionStatus] = useState<
    null | "unauthenticated" | "authenticated" | "loading"
  >(null);
  const { pianoStatus, piano } = usePiano();
  const { asPath: routerPath, isReady } = useRouter();
  const [previousPath, setPreviousPath] = useState<null | string>(null);

  const canAccessTrial = sessionData?.subscription?.canAccessTrial ?? true;

  const pianoToken = sessionData && sessionData.pianoToken;
  useEffect(() => {
    // Set the previous session status if it is authenticated or unauthenticated
    setPreviousSessionStatus(sessionStatus);

    // No further processing if any of the following are true:
    //
    // 1. User not yet loaded
    // 2. Piano not active on page
    // 3. Piano SDK not ready
    if (
      sessionStatus === "loading" ||
      previousSessionStatus === null ||
      previousSessionStatus === "loading" ||
      pianoStatus !== PianoSDKStatus.Ready ||
      !isReady
    ) {
      return;
    }

    setPreviousPath(routerPath);

    // Update/set external JWT
    if (piano.externalJWT !== pianoToken) {
      piano?.setExternalJWT(pianoToken || "");
    }
    // Handle session changes:
    // If the user logs-in / logs-out, re-execute experience
    if (previousSessionStatus && previousSessionStatus !== sessionStatus) {
      if (enableReloadOnLogin) {
        window.location.reload();
        return;
      }

      piano.offer.close();
      piano.template.close();
      return;
    }

    // Otherwise, only execute once when page loads initially
    if (previousPath === routerPath) {
      return;
    }

    // must come first
    piano.setPageURL(window.location.href);

    // eslint-disable-next-line default-case
    switch (props.type) {
      case "list":
        piano.setContentAuthor(null);
        piano.setContentCreated(null);
        piano.setContentSection(null);
        piano.setTags(props.tags.map((tag) => `tag:${tag.slug}`));
        break;
      case "detail":
        piano.setContentAuthor(null);
        piano.setContentCreated(props.datePublished);
        piano.setContentSection(props.contentType.slug);
        piano.setTags([
          ...props.tags.map((tag) => `tag:${tag.slug}`),
          ...props.verticals.map((vertical) => `vertical:${vertical.slug}`),
          // piano only supports a single author: https://docs.piano.io/content-tracking/#cusauthor
          // So, this is a workaround
          ...props.authors.map((author) => `author:${author.slug}`),
        ]);
        break;
      default:
        piano.setContentAuthor(null);
        piano.setContentCreated(null);
        piano.setContentSection(null);
        piano.setTags([]);
        break;
    }

    piano.setCustomVariable("showTrial", canAccessTrial ? "true" : "false");

    piano.setCustomVariable(
      "hasProAccess",
      String(!!sessionData?.user?.pro?.fullAccess)
    );

    piano.setCustomVariable(
      "hasProPlusAccess",
      String(
        !!(
          sessionData?.user?.pro?.consumerPlus ||
          sessionData?.user?.pro?.economicPlus ||
          sessionData?.user?.pro?.politicalPlus
        )
      )
    );

    if (
      "customVariables" in props &&
      typeof props.customVariables === "object"
    ) {
      Object.entries(props.customVariables).forEach(([key, value]) => {
        piano.setCustomVariable(key, value);
      });
    }

    piano.experience.execute();
  }, [
    canAccessTrial,
    enableReloadOnLogin,
    isReady,
    piano,
    pianoStatus,
    pianoToken,
    previousPath,
    previousSessionStatus,
    props,
    routerPath,
    sessionData,
    sessionStatus,
  ]);

  return {
    pianoExecuted:
      previousSessionStatus !== null && previousPath === routerPath,
  };
};
