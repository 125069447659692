import axios from "axios";
import { isTokenNearExpiration } from "~/lib/shared/csrfUtils";
import { GetCSRFTokenResponse } from "~/types/endil/csrf";

type TokenRetriever = () => Promise<void>;

const retrieveQueue: TokenRetriever[] = [];

let token: string | undefined;
let expiration = Date.now();

const getCsrfToken = () =>
  axios.get<GetCSRFTokenResponse>("/api/v1/csrf/token", {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });

/* 
Our CSRF solution needs to keep the token and cookie in sync across multiple windows and
during multiple concurrent invocations of `retrieveCsrfToken()` within the same window.
`retrieveCsrfToken()` uses a queue to avoid a race condition where /api/v1/csrf/token is
called more than once in the same window before responding. Without a queue this race
condition could result in a brief mismatch between the cookie and the token.
 
Separately, it's harder to keep multiple windows in sync while only storing the token in 
local variable. Storing tokens in a local variable is ideal for XSS defense. If we 
weren't concerned about token storage we could use something like LocalStorage or a
BroadcastChannel to handle multi window synchronization.

The /api/v1/csrf/token endpoint will return the token values from a valid request cookie 
that is not nearing expiration (using `isTokenNearExpiration` to determine freshness). The
vast majority of the time this will keep multiple windows in sync. However the possibility
exists for multiple windows to open concurrently, requesting multiple tokens at the same 
time. This could lead to a race condition resulting in a token and cookie mismatch. To 
guard against this condition, in the event of a CSRF error resulting from a mismatch, we
attempt to retrieve another token via the /api/v1/csrf/token endpoint which should then 
use the cookie token value. One concern with having the /api/v1/csrf/token endpoint return
a pre-existing cookies token value is that it could expose the token for use within a 
malicious website. However, the endpoint will be configured to specify a 
`Access-Control-Allow-Origin` header to instruct browsers to block cross origin requests.
*/

export const retrieveCsrfToken = async (skipCache = false): Promise<string> =>
  new Promise((resolve, reject) => {
    const tokenRetriever: TokenRetriever = async () => {
      try {
        if (!skipCache && token && !isTokenNearExpiration(expiration)) {
          resolve(token);
        } else {
          const result = await getCsrfToken();
          token = result.data.token;
          expiration = result.data.expiration;
          resolve(token);
        }
      } catch (err) {
        reject(err);
      } finally {
        retrieveQueue.shift();
        if (retrieveQueue.length) {
          retrieveQueue[0]();
        }
      }
    };

    retrieveQueue.push(tokenRetriever);

    if (retrieveQueue.length === 1) {
      tokenRetriever();
    }
  });
