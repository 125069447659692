import { cx } from "cva";
import { Icon, Input, InputProps } from "~/ui-library";
import { MagnifyingGlassIcon, XMarkIcon } from "~/ui-library/icons";

export interface SearchInputProps extends InputProps {
  onClear?: () => any;
  showClearButton?: boolean;
}

const FloatingIconContainer = ({
  children,
  className,
}: React.PropsWithChildren<{ className: string }>) => (
  <div
    className={cx(
      // float, make them as tall as the input
      "absolute bottom-0 top-0",
      // center its contents
      "flex flex-col items-center justify-center",
      // any additional classes (left/right position, padding)
      className
    )}
  >
    {children}
  </div>
);

export function SearchInput({
  onClear,
  placeholder = "Search for something",
  showClearButton,
  className,
  ...rest
}: SearchInputProps) {
  return (
    <div className={cx(rest.fullWidth ? "relative w-full" : "relative")}>
      <FloatingIconContainer className="left-0 pl-xs">
        <Icon
          className="fill-transparent"
          SvgIcon={MagnifyingGlassIcon}
          title=""
        />
      </FloatingIconContainer>
      <Input
        {...rest}
        autoComplete="off"
        className={cx("pl-[2.5rem]", className)}
        placeholder={placeholder}
      />
      {showClearButton ? (
        <FloatingIconContainer className="right-0 pr-xs">
          <button
            aria-label="Clear Input"
            className="outline-brand"
            onClick={onClear}
          >
            <Icon SvgIcon={XMarkIcon} title="" />
          </button>
        </FloatingIconContainer>
      ) : null}
    </div>
  );
}
