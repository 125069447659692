import { cx } from "cva";

export type ConditionalErrorProps = {
  className?: string;
  error: string | null | undefined;
};

export const ConditionalError: React.FC<ConditionalErrorProps> = ({
  className,
  error,
  /**
   * Used to apply data properties
   * @example
   *
   * <ConditionalError data-testid="foo-bar">Not good!</ConditionalError>
   * expect(screen.getByTestId("foo-bar")).toBeInTheDocument();
   */
  ...rest
}) =>
  error ? (
    <span className={cx(className, "text-danger")} {...rest}>
      {error}
    </span>
  ) : null;
