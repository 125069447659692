export const FacebookIcon = () => (
  <svg
    width="8"
    height="15"
    viewBox="0 0 8 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.59351 0.945496H5.79351C3.74351 0.945496 2.39351 2.2955 2.39351 4.3455V5.9455H0.593506C0.443506 5.9455 0.343506 6.0955 0.343506 6.1955V8.4955C0.343506 8.6455 0.493506 8.7455 0.593506 8.7455H2.44351V14.5455C2.44351 14.6955 2.59351 14.7955 2.69351 14.7955H5.09351C5.24351 14.7955 5.34351 14.6455 5.34351 14.5455V8.79549H7.49351C7.64351 8.79549 7.74351 8.64549 7.74351 8.54549V6.2455C7.74351 6.0955 7.59351 5.9955 7.49351 5.9955H5.39351V4.6455C5.39351 3.9955 5.54351 3.6455 6.39351 3.6455H7.59351C7.74351 3.6455 7.84351 3.4955 7.84351 3.3955V1.1955C7.84351 1.0455 7.74351 0.945496 7.59351 0.945496Z"
      fill="black"
    />
  </svg>
);
