import { useContext } from "react";
import {
  AnalyticsContext,
  AnalyticsContentProperties,
} from "~/components/Analytics/AnalyticsContext";

export const SetAnalyticsDataForContent = (
  properties: AnalyticsContentProperties
) => {
  const { setAnalyticsContentProperties } = useContext(AnalyticsContext);
  setAnalyticsContentProperties(properties);
  return null;
};
