import { useEffect, useState } from "react";

const getMediaQueryList = (value: string) => window.matchMedia(value);

const matchesMediaQuery = (value: string) =>
  // istanbul ignore next
  typeof window !== "undefined" ? getMediaQueryList(value).matches : false;

type MediaQueryTypes = "min-width" | "max-width";
export type MediaQuery = `(${MediaQueryTypes}: ${string}px)`;

/** hook that is a light wrapper around `window.matchMedia */
export const useMediaQuery = (breakpoint: MediaQuery) => {
  const [current, setCurrent] = useState(false);

  useEffect(() => {
    setCurrent(matchesMediaQuery(breakpoint));
    const eventHandler = (e: MediaQueryListEvent) => {
      setCurrent(e.matches);
    };

    const media = getMediaQueryList(breakpoint);
    media.addEventListener("change", eventHandler);
    return () => {
      media.removeEventListener("change", eventHandler);
    };
  }, [breakpoint]);

  return current;
};
