import { useRouter } from "next/router";
import NextImage from "next/image";
import { PageMeta } from "~/components/PageMeta";
import { Body, Button, Headline, Link } from "~/ui-library";

const BackButton = () => {
  const router = useRouter();
  return (
    <Button
      variant="primary"
      onClick={() => {
        router.push("/");
      }}
    >
      Click here to go back
    </Button>
  );
};

export type ErrorPageProps = {
  /** User-fracing string, sits below the subtitle */
  description: string | null;
  /** Title used for SEO */
  seoTitle: string;
  /** User-facing string, sits below the title */
  subtitle: string;
  /** User-facing string, the "h1" of the page */
  title: string;
};

export const ErrorPage: React.FC<ErrorPageProps> = ({
  description,
  seoTitle,
  subtitle,
  title,
}) => (
  <>
    <PageMeta
      seo={{
        title: seoTitle,
      }}
    />

    <div className="mx-[auto] max-w-[848px] bg-white">
      <div className="mx-m my-xl flex flex-col place-items-center gap-m text-center md:p-xl">
        <div className="w-xxl place-items-center md:w-1/2">
          <NextImage
            src="/assets/mcpro-logo-color.png"
            alt="logo"
            width={352}
            height={5}
            className="hidden md:block"
          />
          <NextImage
            src="/assets/mcpro-logo-condensed-color.png"
            alt="logo"
            width={96}
            height={5}
            className="md:hidden"
          />
        </div>
        <div className="text-[80px] font-semibold leading-body text-gray-700 md:text-[128px]">
          {title}
        </div>
        <div>
          <Headline as="h2" level={2} className="pb-m">
            {subtitle}
          </Headline>
          {description ? <Body size="large">{description}</Body> : null}
        </div>

        <BackButton />

        <div className="mt-m md:mt-xxl">
          <Body size="large">
            If you were not expecting to see this page please contact{" "}
            <Link appearance={"bold"} href="mailto:pro@morningconsult.com">
              pro@morningconsult.com
            </Link>
          </Body>
        </div>
      </div>
    </div>
  </>
);

export const ErrorPage403 = () => (
  <ErrorPage
    description={null}
    seoTitle="Not Authorized"
    subtitle="You are not authorized to access this page."
    title="403"
  />
);

export const ErrorPage404 = () => (
  <ErrorPage
    description="We're glad you're looking for insights but you won't find any here."
    seoTitle="Not Found"
    subtitle="The page you requested cannot be found."
    title="404"
  />
);

export const ErrorPage500 = () => (
  <ErrorPage
    description="Please refresh the page or try again later."
    seoTitle="Server Error"
    subtitle="Sorry, we are experiencing issues with our server."
    title="500"
  />
);
