import React from "react";
import { useAnalytics } from "~/components/Analytics/useAnalytics";
import {
  ButtonLink,
  ButtonLinkProps,
} from "~/ui-library/components/ButtonLink/ButtonLink";
import { Icon } from "~/ui-library/components/Icon";
import { ExitAppIcon } from "~/ui-library/icons";

export type MCIButtonProps = ButtonLinkProps & {
  href: string;
};

/** Link that looks like a button that should be used to send the user to Morning Consult Intelligence */
export const MCIButton: React.FC<MCIButtonProps> = ({ onClick, ...rest }) => {
  const val = useAnalytics();
  const { analyticsEnhancedTrack } = val;

  const onButtonClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    analyticsEnhancedTrack("Morning Consult Intelligence Button Clicked");
    onClick?.(e);
  };

  return (
    <ButtonLink
      as="a"
      className="w-fit"
      onClick={onButtonClick}
      size="small"
      target="_blank"
      {...rest}
    >
      <span className="flex items-center gap-xxs whitespace-nowrap">
        <span>Morning Consult Intelligence</span>
        <Icon size="small" SvgIcon={ExitAppIcon} />
      </span>
    </ButtonLink>
  );
};
