import { useCallback } from "react";
import { CloseButtonProps, toast, ToastOptions, Id } from "react-toastify";
import { Icon } from "~/ui-library/components/Icon";

import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  XMarkIcon,
} from "~/ui-library/icons";

const CloseButton: React.FC<CloseButtonProps> = ({ closeToast }) => (
  <button className="p-s" title="dismiss notification" onClick={closeToast}>
    <Icon SvgIcon={XMarkIcon} title="Dismiss" />
  </button>
);

export const useToastManager = () => ({
  dismissToast: useCallback((id: Id) => toast.dismiss(id), []),
  addToast: useCallback(
    (
      variant: "success" | "danger",
      message: string | JSX.Element,
      toastOptions: ToastOptions = {}
    ) =>
      toast(<span className="text-gray-900">{message}</span>, {
        closeOnClick: false,
        closeButton: CloseButton,
        hideProgressBar: true,
        icon:
          variant === "success" ? (
            <Icon
              SvgIcon={CheckCircleIcon}
              title="Success"
              className="fill-success"
            />
          ) : (
            <Icon
              SvgIcon={ExclamationTriangleIcon}
              title="Error"
              className="fill-danger"
            />
          ),
        ...toastOptions,
      }),
    []
  ),
});
