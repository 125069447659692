import { PropsWithChildren, createContext, useContext } from "react";
import { ReportPreviewTemplateIds, TinyPass } from "./piano-types";

export enum PianoSDKStatus {
  /**
   * SDK has not yet been loaded via script tag.
   */
  NotReady = 0,

  /**
   * The SDK is loaded and initialized.
   */
  Ready = 1,
}

export type PianoContextProps =
  | {
      pianoStatus: PianoSDKStatus.NotReady;
      piano: null;
      hasExperienceExecuted: boolean;
    }
  | {
      pianoStatus: PianoSDKStatus.Ready;
      piano: TinyPass;
      paywallShown: boolean;
      promotionalBannerSlug?: string;
      activeSubscriptionName?: string | null;
      hasExperienceExecuted: boolean;
      reportTemplates?: ReportPreviewTemplateIds | null;
    };

const PianoContext = createContext<PianoContextProps>({
  pianoStatus: PianoSDKStatus.NotReady,
  piano: null,
  hasExperienceExecuted: false,
});

export const PianoContextProvider = ({
  children,
  ...props
}: PropsWithChildren<PianoContextProps>): JSX.Element => (
  <PianoContext.Provider value={props}>{children}</PianoContext.Provider>
);

export const usePiano = (): PianoContextProps => useContext(PianoContext);

export const useReportTemplates = ():
  | ReportPreviewTemplateIds
  | null
  | undefined => {
  const ctx = useContext(PianoContext);
  if (ctx.piano && ctx.pianoStatus === PianoSDKStatus.Ready) {
    return ctx.reportTemplates;
  }

  return null;
};
