import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgChevron = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.08 31.08"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M0 0v11.66L15.54 27.2l15.54-15.54V0L15.54 15.54Zm31.08 31.08V19.42L19.43 31.08ZM0 31.08h11.66L0 19.42Z"
      style={{
        fill: "#2b2b2b",
      }}
    />
  </svg>
);
export default SvgChevron;
