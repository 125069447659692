import { AnalyticsContentProperties } from "~/components/Analytics/AnalyticsContext";
import { PostTypeEnum } from "~/types/asgard/PostType";
import { heDecodeOrDefault } from "~/lib/shared/html-utils";
import { Author } from "~/types/asgard/Author";
import { Tag } from "~/types/asgard/Tag";
import { Category } from "~/types/asgard/Category";
import { Tracker } from "~/types/asgard/Tracker";
import { Post } from "~/types/asgard/Post";

/**
 * Covers: Analysis, AnalystReport, Article, InstantIntel, QuestionAnswer, TrendSetter
 */
export type PostCommonContentV1 = {
  title: Post["title"];
  authors: Author[];
  tags: Tag[];
  categories: Category[];
  type: string;
};

const PostTypeToDisplayName = new Map<string, string>([
  [PostTypeEnum.Analysis, "Analysis"],
  [PostTypeEnum.AnalystReport, "Analyst Report"],
  [PostTypeEnum.Article, "Article"],
  [PostTypeEnum.InstantIntel, "Instant Intel"],
  [PostTypeEnum.QuestionAnswer, "Question and Answer"],
  [PostTypeEnum.TrendSetter, "Trend Setter"],
]);

export const apiV1ToAnalyticsContentProperties = (
  apiData: PostCommonContentV1
): AnalyticsContentProperties => {
  try {
    const categories = apiData.categories.map((category) =>
      heDecodeOrDefault(category.name)
    );
    return {
      headline: heDecodeOrDefault(apiData.title.rendered),
      authors: apiData.authors.map((author) =>
        heDecodeOrDefault(author.display_name)
      ),
      primaryCategory: categories[0] || "",
      categories,
      tags: apiData.tags.map((tag) => heDecodeOrDefault(tag.name)),
      contentType: PostTypeToDisplayName.get(apiData.type) || "",
    };
  } catch (err) {
    return {
      headline: "",
      authors: [],
      primaryCategory: "",
      categories: [],
      tags: [],
      contentType: "",
    };
  }
};

export const apiTrackerToAnalyticsContentProperties = (
  apiData: Tracker
): AnalyticsContentProperties => {
  try {
    const categories = apiData.categories.map((category) =>
      heDecodeOrDefault(category.name)
    );

    const primaryCategoryData =
      apiData.categories.find(
        (category) => category.term_id === apiData.acf.primaryCategory
      ) || apiData.categories[0];

    return {
      headline: heDecodeOrDefault(apiData.title.rendered),
      authors: apiData.authors.map((author) =>
        heDecodeOrDefault(author.displayName)
      ),
      primaryCategory: heDecodeOrDefault(primaryCategoryData.name),
      categories,
      tags: apiData.tags.map((tag) => heDecodeOrDefault(tag.name)),
      contentType: "tracker",
    };
  } catch (err) {
    return {
      headline: "",
      authors: [],
      primaryCategory: "",
      categories: [],
      tags: [],
      contentType: "",
    };
  }
};
