import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import React, { useEffect, useMemo, useRef } from "react";
import FocusLock from "react-focus-lock";
import {
  AppLogoLarge,
  AppLogoResponsive,
  LayoutAppLogo,
} from "~/components/Layout/LayoutAppLogo";
import { NavigationAppBar } from "~/components/NavigationAppBar";
import { useTopNav } from "~/components/NavigationProvider";
import { useActiveBreakpoint } from "~/lib/client/useActiveBreakpoint";
import { useSubscriptionStatus } from "~/lib/client/useSubscriptionStatus";
import { nameToInitials } from "~/lib/shared/nameToInitials";
import {
  userHasMcFieldAccess,
  userHasMcIntelAccess,
} from "~/lib/shared/userHasMcAppAccess";
import { IconButton } from "~/ui-library";
import { Flyout } from "~/ui-library/components/Flyout/Flyout";
import { Bars3Icon, MagnifyingGlassIcon, XMarkIcon } from "~/ui-library/icons";
import { LayoutDesktopToolbar } from "../LayoutDesktopToolbar/LayoutDesktopToolbar";
import { LayoutDesktopUserToolbar } from "../LayoutDesktopUserToolbar/LayoutDesktopUserToolbar";
import { LayoutMobileNavigationList } from "../LayoutMobileNavigationList/LayoutMobileNavigationList";
import { LayoutSearchInput } from "../LayoutSearchInput/LayoutSearchInput";
import { apiToMenuDataProps } from "../apiToMenuDataProps";
import {
  desktopNavigationLayoutWithReports,
  mobileNavigationLayoutWithReports,
} from "../menuData";

const useMenuData = () =>
  useMemo(
    () => ({
      desktopMenuData: apiToMenuDataProps(desktopNavigationLayoutWithReports),
      mobileMenuData: apiToMenuDataProps(mobileNavigationLayoutWithReports),
    }),
    []
  );

export interface LayoutAppBarProps<T extends React.ElementType> {
  as?: T;
}

const CaptureFlyoutClose: React.FC = () => {
  const { closeMenu, closeSearch } = useTopNav();

  const isLargeScreen = useActiveBreakpoint("LARGE");

  useEffect(() => {
    if (isLargeScreen) {
      closeMenu();
      closeSearch();
    }
  }, [closeMenu, closeSearch, isLargeScreen]);

  return null;
};

export function LayoutAppBar<T extends React.ElementType = "header">({
  as,
}: LayoutAppBarProps<T>) {
  const router = useRouter();
  const {
    closeMenu,
    closeSearch,
    menuIsOpen,
    toggleMenu,
    toggleSearch,
    searchIsOpen,
  } = useTopNav();

  const { desktopMenuData, mobileMenuData } = useMenuData();

  // Used below to whitelist tab access within the flyout focus locks
  const mobileContainerRef = useRef<HTMLDivElement>(null);

  const { data: session, status: sessionStatus } = useSession();
  const { status: subscriptionStatus } = useSubscriptionStatus();

  const isLoading = [sessionStatus, subscriptionStatus].includes("loading");

  const subscriptionValue =
    subscriptionStatus === "subscribed" ? "subscribed" : sessionStatus;

  const status = isLoading ? "loading" : subscriptionValue;

  const hasMcFieldAccess = userHasMcFieldAccess(session?.team?.permissions);
  const hasMcIntelAccess = userHasMcIntelAccess(session?.team?.permissions);

  const userInitials = nameToInitials(session?.user);

  const closeFlyouts = () => {
    closeMenu();
    closeSearch();
  };

  useEffect(() => {
    if (menuIsOpen || searchIsOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [menuIsOpen, searchIsOpen]);

  useEffect(() => {
    const closeAll = () => {
      closeMenu();
      closeSearch();
    };
    router.events.on("routeChangeStart", closeAll);
    return () => {
      router.events.off("routeChangeStart", closeAll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, router.asPath]);

  return (
    <>
      <NavigationAppBar
        as={as}
        className="sticky inset-0 z-50 flex items-center justify-between print:hidden"
      >
        {/* DESKTOP CONTENTS */}
        <div className="hidden size-full items-center lg:flex">
          {/* Logo, menu buttons */}
          <div className="mr-xs flex grow items-center pl-l">
            <LayoutAppLogo
              className="lg:mr-m"
              data-testid="app-logo-desktop"
              onClick={closeFlyouts}
              logo={<AppLogoLarge />}
            />
            <LayoutDesktopToolbar menuData={desktopMenuData} />
          </div>

          {/* Search bar, CTAS */}
          <LayoutDesktopUserToolbar
            hasFieldAccess={hasMcFieldAccess}
            hasMciAccess={hasMcIntelAccess}
            status={status}
            userInitials={userInitials}
            showSearchBar={!router.asPath.startsWith("/search")}
          />
        </div>

        {/* MOBILE CONTENTS */}
        <div
          className="flex w-full justify-between gap-s p-xs lg:hidden"
          ref={mobileContainerRef}
        >
          <div>
            <IconButton
              className={menuIsOpen ? "shadow-[0_0_0_2px] shadow-gray-300" : ""}
              onClick={toggleMenu}
              size="default"
              SvgIcon={menuIsOpen ? XMarkIcon : Bars3Icon}
              title={
                menuIsOpen ? "Close navigation menu" : "Open navigation menu"
              }
              variant="subtle"
            />
          </div>
          <LayoutAppLogo
            onClick={closeFlyouts}
            data-testid="app-logo-mobile"
            logo={<AppLogoResponsive />}
          />
          <div>
            <IconButton
              className={
                searchIsOpen ? "shadow-[0_0_0_2px] shadow-gray-300" : ""
              }
              iconClassName={searchIsOpen ? "" : "fill-transparent"}
              onClick={toggleSearch}
              size="default"
              SvgIcon={searchIsOpen ? XMarkIcon : MagnifyingGlassIcon}
              title={searchIsOpen ? "Close search" : "Open search"}
              variant="subtle"
            />
          </div>
        </div>
        {/* shards={[mobileContainerRef]} will add all items within the nav bar to the focus lock */}
        <FocusLock disabled={!menuIsOpen} shards={[mobileContainerRef]}>
          <Flyout
            className="z-50 lg:hidden"
            fillScreen
            isOpen={menuIsOpen}
            openFromDirection="left"
            showOverlay={false}
          >
            <LayoutMobileNavigationList
              hasMciAccess={hasMcIntelAccess}
              menuData={mobileMenuData}
              status={status}
              tabIndex={menuIsOpen ? 0 : -1}
            />
          </Flyout>
        </FocusLock>

        {/* shards={[mobileContainerRef]} will add all items within the nav bar to the focus lock */}
        <FocusLock disabled={!searchIsOpen} shards={[mobileContainerRef]}>
          <Flyout
            className="z-50 lg:hidden"
            fillScreen
            isOpen={searchIsOpen}
            openFromDirection="right"
            showOverlay={false}
          >
            <div className="p-s">
              <LayoutSearchInput
                aria-label="Search for articles"
                tabIndex={searchIsOpen ? 0 : -1}
              />
            </div>
          </Flyout>
        </FocusLock>
      </NavigationAppBar>
      <CaptureFlyoutClose />
    </>
  );
}
