import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgEvervizHollywood = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="Layer_1"
    data-name="Layer 1"
    viewBox="0 0 646 45"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <defs>
      <style>{".cls-1{fill:#757575}"}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="MC_Logo_" data-name="MC Logo">
        <path
          d="m142 18.5-9.6-13.1h-4.6v20.8h4.4V12.7l9.9 13.5h4.3V5.4H142Zm-21.1-6.4c0-3.9-2.6-6.7-7-6.7h-9.7v20.8h4.4v-7.4h3.2l4.1 7.4h5.1l-4.6-7.9a6 6 0 0 0 4.53-6.2Zm-7.6 2.9h-4.6V9.3h4.9a2.86 2.86 0 0 1 2.8 2.8v.3a3 3 0 0 1-3.07 2.6Zm40.8 11.2h4.4V5.4h-4.4ZM0 .3V12l15.5 15.5L31 12V.3L15.45 15.8Zm31.1 31.1V19.7L19.35 31.4ZM57.84 18l-5.1-12.6h-6.2v20.8h4.4V11.7l5.9 14.5h1.9l5.9-14.5v14.5H69V5.4h-6.2Zm28.9-13a10.9 10.9 0 1 0 10.89 10.9A10.93 10.93 0 0 0 86.74 5m6.2 10.8c0 3.9-2.5 6.8-6.3 6.8s-6.3-2.9-6.3-6.8 2.5-6.8 6.3-6.8 6.3 2.9 6.3 6.8M0 31.4h11.7L0 19.7Zm309.5-20.3c0-1.3 1.1-2.1 3-2.1a8.82 8.82 0 0 1 6 2.3l2.5-3.2a11.79 11.79 0 0 0-8-2.9c-4.9 0-7.9 2.9-7.9 6.3 0 7.8 12 5.3 12 9 0 1.2-1.2 2.3-3.6 2.3a9.07 9.07 0 0 1-6.6-2.8l-2.4 3.3a12 12 0 0 0 8.8 3.3c5.6 0 8.3-2.9 8.3-6.7-.2-7.8-12.1-5.5-12.1-8.8Zm32.8 6.7a4.5 4.5 0 0 1-4.1 4.9h-1.6a4.5 4.5 0 0 1-4.1-4.9V5.4H328v12.5c0 5.1 3 8.6 9.4 8.6s9.4-3.5 9.4-8.7V5.4h-4.5Zm16.59-12.4h-4.39v20.8h13.19v-3.9h-8.8ZM180.42 18.5l-9.6-13.1h-4.5v20.8h4.4V12.7l9.9 13.5h4.2V5.4h-4.4zM370 9.3h6.1v16.9h4.4V9.3h6V5.4h-16.6v3.9Zm-168.48 9.3H207v2.5a7 7 0 0 1-4.4 1.6h-.4a6.45 6.45 0 0 1-6.1-6.7v-.5a6.44 6.44 0 0 1 6.4-6.4h.1a5.79 5.79 0 0 1 4.9 2.7l3.7-2a9.43 9.43 0 0 0-8.6-4.6c-6.1 0-11.1 4.2-11.1 10.8s5 10.8 11.1 10.8a11.32 11.32 0 0 0 8.8-3.9v-8h-9.9Zm92.08-.1L284 5.4h-4.5v20.8h4.4V12.7l9.89 13.5h4.3V5.4h-4.4Zm-63-3.1a6.4 6.4 0 0 1 6.5-6.4 5.61 5.61 0 0 1 4.9 3l3.8-1.9a9.62 9.62 0 0 0-8.7-5.1C230.91 5 226 9.4 226 15.8s4.89 10.8 11.09 10.8a9.62 9.62 0 0 0 8.7-5.1L242 19.7a5.49 5.49 0 0 1-4.9 3h-.4a6.45 6.45 0 0 1-6.1-6.7zM261.91 5a10.9 10.9 0 1 0 10.9 10.9A10.86 10.86 0 0 0 261.91 5m0 17.7c-3.8 0-6.3-2.9-6.3-6.8s2.5-6.8 6.3-6.8 6.3 2.9 6.3 6.8-2.5 6.8-6.3 6.8M393.49.3a2.65 2.65 0 0 0-2.6 2.6 2.6 2.6 0 1 0 5.2 0 2.48 2.48 0 0 0-2.4-2.6Zm1.3 4.1L394 3.2a1 1 0 0 0 .7-.9.94.94 0 0 0-1-1h-1.3v3h.6v-1h.4l.7 1.1h.7a2.11 2.11 0 0 1-1.3.5 2 2 0 1 1 2-2 1.8 1.8 0 0 1-.71 1.5m-1.1-1.7H393v-.8h.7a.43.43 0 0 1 .4.4.43.43 0 0 1-.41.4"
          className="cls-1"
        />
        <path
          d="M427.18.3v31.1"
          style={{
            fill: "none",
            stroke: "#757575",
            strokeMiterlimit: 10,
            strokeWidth: ".5px",
          }}
        />
      </g>
      <g id="THR_logo" data-name="THR logo">
        <g id="layer1">
          <g id="g9884">
            <g id="g5621">
              <path
                id="path5623"
                d="m566.76 40.3.6-2h-4.7l.6-1.8H569l.6-2h-8.5l-3 9.7h8.5l.6-2h-5.7l.6-2Z"
                className="cls-1"
              />
            </g>
            <g id="g5633">
              <path
                id="path5635"
                d="M554.86 34.5h-5.1l-3 9.7h2.7l1-3.3h2l1.4 3.3h3l-1.7-3.6a3.53 3.53 0 0 0 3.1-2.5l.1-.2c.5-2.1-.8-3.4-3.5-3.4m.6 3.2c-.3 1.1-1 1.4-2.1 1.4h-2.2l.8-2.7h2.4c1-.1 1.4.4 1.1 1.3"
                className="cls-1"
              />
            </g>
            <g id="g5637">
              <path
                id="path5639"
                d="M599.06 34.5h-5.2l-3 9.7h2.7l1-3.3h2l1.4 3.3h3l-1.7-3.6a3.65 3.65 0 0 0 3.1-2.5l.1-.2c.6-2-.7-3.4-3.3-3.4m.5 3.2c-.3 1.1-1 1.4-2.1 1.4h-2.2l.8-2.7h2.4c1-.1 1.4.4 1.1 1.3"
                className="cls-1"
              />
            </g>
            <g id="g5641">
              <path
                id="path5643"
                d="m620.75 40.3.6-2h-4.7l.6-1.8H623l.6-2h-8.5l-2.89 9.7h8.49l.6-2h-5.7l.6-2Z"
                className="cls-1"
              />
            </g>
            <g id="g5645">
              <path
                id="path5647"
                d="M576.46 34.5h-4.8l-2.9 9.7h2.7l.9-3h2.8c2.7 0 4-.9 4.6-2.9l.1-.3c.6-2-.3-3.4-3.4-3.5m.6 3.4-.1.2a1.93 1.93 0 0 1-2.1 1.3h-2l.9-3h2.1c1.2 0 1.5.4 1.2 1.5"
                className="cls-1"
              />
            </g>
            <g id="g5649">
              <path
                id="path5651"
                d="m613 36.5.6-2h-9.4l-.6 2h3.4l-2.4 7.7h2.8l2.4-7.7Z"
                className="cls-1"
              />
            </g>
            <g id="g5653">
              <path
                id="path5655"
                d="M630.65 34.5h-5.1l-3 9.7h2.7l1-3.3h1.9l1.5 3.3h3L631 40.6a3.65 3.65 0 0 0 3.1-2.5l.1-.2c.5-2.1-.8-3.4-3.5-3.4m.6 3.2c-.3 1.1-1 1.4-2.1 1.4h-2.3l.8-2.7h2.4c1.1-.1 1.4.4 1.2 1.3"
                className="cls-1"
              />
            </g>
            <g id="g5657">
              <path
                id="path5659"
                d="M586.86 34.3c-3.3 0-5.1 1.2-6 4l-.3.9c-1 3.4.9 5.1 3.9 5.1 3.3 0 5.1-1.2 6-4l.3-.9c1-3.5-.9-5.1-3.9-5.1m1.4 4.4-.4 1.3a2.64 2.64 0 0 1-2.8 2.2c-1.7 0-2.3-1-2-2.4l.4-1.3a2.62 2.62 0 0 1 2.8-2.2c1.8 0 2.4.9 2 2.4"
                className="cls-1"
              />
            </g>
            <g id="g5661">
              <path
                id="path5663"
                d="M557.46 1.8 558 .2h-6l-2.3 7.5h6l.5-1.6h-3.8l.4-1.3h3l.5-1.6h-3l.4-1.3Z"
                className="cls-1"
              />
            </g>
            <g id="g5665">
              <path
                id="path5667"
                d="m542.07 1.8.5-1.6h-6.7l-.5 1.6h2.4L536 7.7h2.2l1.7-5.9Z"
                className="cls-1"
              />
            </g>
            <g id="g5669">
              <path
                id="path5671"
                d="m548.27.1-.8 2.9H545l.9-2.9h-2.2l-2.3 7.5h2.2l.9-3h2.5l-.9 3h2.2l2.16-7.5Z"
                className="cls-1"
              />
            </g>
            <g id="g5673">
              <path
                id="path5675"
                d="M592.26 9.8c-6.4 0-11.9 6.7-11.9 13.6 0 5 3.5 8.6 7.4 8.6 6.4 0 11.9-6.7 11.9-13.6 0-5.1-3.5-8.6-7.4-8.6m-1.7 20.2a4.82 4.82 0 0 1-3.2 1.5c-1.9 0-3.2-1.1-3.2-4.6 0-4 2.1-11.9 5.3-15.1a4.41 4.41 0 0 1 3.2-1.5c1.9 0 3.2 1.1 3.2 4.6 0 4.1-2.1 11.9-5.3 15.1"
                className="cls-1"
              />
            </g>
            <g id="g5677">
              <path
                id="path5679"
                d="M499.67 9.8c-6.4 0-11.9 6.7-11.9 13.6 0 5 3.5 8.6 7.4 8.6 6.4 0 11.9-6.7 11.9-13.6-.1-5.1-3.6-8.6-7.4-8.6m-1.8 20.2a4.82 4.82 0 0 1-3.2 1.5c-1.9 0-3.2-1.1-3.2-4.6 0-4 2.1-11.9 5.3-15.1a4.62 4.62 0 0 1 3.2-1.5c1.9 0 3.2 1.1 3.2 4.6 0 4-2.1 11.9-5.3 15.1"
                className="cls-1"
              />
            </g>
            <g id="g5681">
              <path
                id="path5683"
                d="M612.76 9.8c-6.4 0-11.9 6.7-11.9 13.6 0 5 3.5 8.6 7.4 8.6 6.39 0 11.89-6.7 11.89-13.6-.1-5.1-3.6-8.6-7.39-8.6M611 30a4.59 4.59 0 0 1-3.1 1.5c-1.9 0-3.2-1.1-3.2-4.6 0-4 2.1-11.9 5.3-15.1a4.62 4.62 0 0 1 3.2-1.5c1.89 0 3.19 1.1 3.19 4.6 0 4-2.1 11.9-5.39 15.1"
                className="cls-1"
              />
            </g>
            <g id="g5685">
              <path
                id="path5687"
                d="M577.06 9.9a2.4 2.4 0 1 0-.2 4.8 2.5 2.5 0 0 0 2.4-1.6 8.08 8.08 0 0 1 .1 1.6c0 7.2-6.2 16.5-10.7 16.5-1.5 0-2.6-.9-1.1-5l4.9-15.7h-4.4l-3.2 10.1c-2.3 7.3-5.7 10.6-7.7 10.6-1.4 0-2.2-1-1.2-4.5l4.9-16.3-.1-.1c-.8.1-2.7.1-4.9.1h-2.6l-.3.3.2.2h3.3l-4.1 13.3c-1.6 5.1.8 7.8 4.3 7.8 2.5 0 5.1-2.7 7-6.9-.9 4.2 1.2 6.9 4.3 6.9 6.4 0 12.2-10.5 12.2-17.5-.2-3.4-1.5-4.6-3.1-4.6"
                className="cls-1"
              />
            </g>
            <g id="g5689">
              <path
                id="path5691"
                d="M486.47 31h-4.09L491.67.6h3.8l.3-.3-.2-.2h-12.29l-.4.3.1.2h3.89l-4.49 14.8h-12.2l4.5-14.8h3.5l.3-.3-.1-.2h-12.5l-.3.3.2.2h4.1l-9.2 30.4h-3.8l-.3.3.2.2h12.3l.3-.3-.1-.2h-3.9L470 15.9h12.2L477.58 31h-3.5l-.3.3.1.2h12.39l.3-.3Z"
                className="cls-1"
              />
            </g>
            <g id="g5693">
              <path
                id="path5695"
                d="m517.47 24-.2.1c-2.6 5.9-4 6.9-4.9 6.9s-1.4-.6-.5-3.8l8.2-27V0c-.8.1-2.7.1-4.9.1h-2.6l-.3.3.2.2h3.2l-7.5 24.7c-1.2 4.5.5 6.7 3.4 6.7 1.8 0 3.6-1.2 6.3-7.7Z"
                className="cls-1"
              />
            </g>
            <g id="g5697">
              <path
                id="path5699"
                d="m528.77 24-.2.1c-2.6 5.9-4 6.9-4.9 6.9s-1.4-.6-.5-3.8l8.2-27-.1-.2c-.8.1-2.7.1-4.9.1h-2.6l-.3.3.1.2h3.2l-7.5 24.7c-1.2 4.5.5 6.7 3.4 6.7 1.8 0 3.7-1.2 6.3-7.7Z"
                className="cls-1"
              />
            </g>
            <g id="g5701">
              <path
                id="path5703"
                d="M543.87 19.4c-2.6 8.6-6 11.7-8.1 11.7-1.4 0-2.1-1.1-1.3-3.9l5.1-16.8-.1-.1c-.8.1-2.7.1-4.9.1h-2.7l-.3.3.2.2H535l-4 13.3c-1.5 5.2.8 7.9 4.3 7.9 2.4 0 4.9-2.3 7-6.7l-3.4 10.8c-1.8 6.1-4 7.8-6.3 7.8s-4.3-.9-5.1-2.2a2.27 2.27 0 0 0 1.6.6 2.51 2.51 0 0 0 .4-5h-.4a2.58 2.58 0 0 0-2.6 2.6v.1c0 2.5 2.5 4.4 6 4.4 4.5 0 9-2.2 11.3-9.9l7.39-24.1h-4.39Z"
                className="cls-1"
              />
            </g>
            <g id="g5705">
              <path
                id="path5707"
                d="m646 .1-.1-.1c-.8.1-2.7.1-4.9.1h-2.6l-.3.3.2.2h3.2l-4.1 13.6c-.1-2.7-1.7-4.3-4-4.3-5.8 0-12.1 7.3-12.1 14.3 0 5.5 3 7.9 5.8 7.9 2.4 0 4.9-1.9 6.8-5.6-.7 3.9 1 5.6 3.5 5.6 1.8 0 3.7-1.2 6.3-7.7l-.2-.3-.2.1c-2.6 5.9-4 6.9-4.9 6.9s-1.4-.6-.5-3.8ZM636.35 17 635 21.6c-2.4 8-5.8 9.9-7.7 9.9-1.7 0-2.3-1.2-2.3-4 0-7.8 5.1-17.1 8.8-17.1 2.1-.1 3.8 2.5 2.6 6.6"
                className="cls-1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgEvervizHollywood;
