import { VariantProps } from "cva";
import Link from "next/link";
import { sharedChipStyles } from "~/ui-library/shared-styles/shared-chip-styles";

export type ChipLinkProps<T extends React.ElementType<any> = "a"> = {
  as?: T;
  children?: React.ReactNode;
  className?: string;
  href: string;
} & React.ComponentPropsWithoutRef<T> &
  VariantProps<typeof sharedChipStyles>;

export const ChipLink: React.FC<ChipLinkProps> = ({
  appearance,
  as,
  children,
  className,
  ...rest
}: ChipLinkProps) => {
  const Component = as || Link;

  return (
    <Component
      {...rest}
      className={sharedChipStyles({ appearance, className })}
    >
      {children}
    </Component>
  );
};
