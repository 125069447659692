export const MegaphoneIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.2002 9.33333C24.2 8.38818 23.8652 7.47361 23.255 6.75179C22.6449 6.02997 21.7988 5.54753 20.8669 5.39V0L19.9669 0.333333C19.8869 0.363333 11.8002 3.33333 0.866862 3.33333H0.200195V15.3333H0.866862C2.0102 15.3333 3.12353 15.3667 4.2002 15.425V21.3333C4.2002 22.0406 4.48115 22.7189 4.98124 23.219C5.48134 23.719 6.15962 24 6.86686 24C7.57411 24 8.25238 23.719 8.75248 23.219C9.25258 22.7189 9.53353 22.0406 9.53353 21.3333V15.94C15.8102 16.81 19.9102 18.31 19.9669 18.3317L20.8669 18.665V13.2767C21.7988 13.1191 22.6449 12.6367 23.255 11.9149C23.8652 11.1931 24.2 10.2785 24.2002 9.33333ZM1.53353 14V4.66667C2.4452 4.65667 3.3352 4.62667 4.2002 4.58167V14.0883C3.3352 14.0433 2.4452 14.0133 1.53353 14ZM8.2002 21.3333C8.2002 21.687 8.05972 22.0261 7.80967 22.2761C7.55962 22.5262 7.22048 22.6667 6.86686 22.6667C6.51324 22.6667 6.1741 22.5262 5.92405 22.2761C5.67401 22.0261 5.53353 21.687 5.53353 21.3333V15.5117C6.45575 15.5817 7.34464 15.6672 8.2002 15.7683V21.3333ZM19.5335 16.775C17.5335 16.13 12.3952 14.6667 5.53353 14.1667V4.5C12.3952 4 17.5335 2.54 19.5335 1.895V16.775ZM20.8669 11.9117V6.755C21.4361 6.90527 21.9396 7.23967 22.2988 7.70607C22.658 8.17246 22.8529 8.74462 22.8529 9.33333C22.8529 9.92204 22.658 10.4942 22.2988 10.9606C21.9396 11.427 21.4361 11.7614 20.8669 11.9117Z"
      fill="#4657CE"
    />
  </svg>
);
