import { getRouteFromPostType } from "~/lib/shared/getRouteFromPostType";
import {
  NavigationGroupItem,
  NavigationItem,
  NavigationResponse,
} from "~/types/asgard/MenuResponse";
import { PostTypeEnum } from "~/types/asgard/PostType";
import { ReportHorizontal } from "~/types/asgard/ReportLibrary";

const REPORTS: NavigationItem[] = [
  {
    label: "All Reports",
    link: "/reports",
  },
  {
    label: "Industry Reports",
    link: getRouteFromPostType(
      PostTypeEnum.ReportLibrary,
      ReportHorizontal.industry
    ),
  },
  {
    label: "Political Reports",
    link: getRouteFromPostType(
      PostTypeEnum.ReportLibrary,
      ReportHorizontal.political
    ),
  },
  {
    label: "Economic Reports",
    link: getRouteFromPostType(
      PostTypeEnum.ReportLibrary,
      ReportHorizontal.economic
    ),
  },
];

const TRENDING_TOPICS: NavigationItem[] = [
  {
    label: "Gen Z",
    link: "/tags/gen-z",
  },
  {
    label: "Artificial Intelligence",
    link: "/tags/artificial-intelligence",
  },
  {
    label: "China",
    link: "/tags/china",
  },
  {
    label: "Consumer Spending",
    link: "/tags/consumer-spending",
  },
  {
    label: "Streaming",
    link: "/tags/streaming",
  },
  {
    label: "2024 Elections",
    link: "/tags/2024-elections",
  },
  {
    label: "Influencers",
    link: "/tags/influencers",
  },
  {
    label: "Workforce Trends",
    link: "/tags/workers",
  },
  {
    label: "Brand Loyalty",
    link: "/tags/brand-loyalty",
  },
];

const INDUSTRIES: NavigationItem[] = [
  {
    label: "Brands & Marketing",
    link: "/verticals/brands",
  },
  {
    label: "Finance",
    link: "/verticals/finance",
  },
  {
    label: "Food & Beverage",
    link: "/verticals/food-and-beverage",
  },
  {
    label: "Health",
    link: "/verticals/health",
  },
  {
    label: "Media & Entertainment",
    link: "/verticals/media-entertainment",
  },
  {
    label: "Retail & E-Commerce",
    link: "/verticals/retail-e-commerce",
  },
  {
    label: "Sports",
    link: "/verticals/sports",
  },
  {
    label: "Tech",
    link: "/verticals/tech",
  },
  {
    label: "Travel & Hospitality",
    link: "/verticals/travel-hospitality",
  },
];
const POLITICS_AND_ECONOMICS: NavigationItem[] = [
  {
    label: "Economics",
    link: "/verticals/economics",
  },
  {
    label: "Global Politics",
    link: "/verticals/geopolitics",
  },
  {
    label: "U.S. Politics",
    link: "/verticals/us-politics",
  },
];

export const AllItems = [
  ...TRENDING_TOPICS,
  ...INDUSTRIES,
  ...POLITICS_AND_ECONOMICS,
  ...REPORTS,
];

const mobileIndusties = {
  label: "Industries",
  child_items: INDUSTRIES,
};

const mobilePolitics = {
  label: "Politics & Economics",
  child_items: POLITICS_AND_ECONOMICS,
};

const mobileTopics = {
  label: "Topics",
  child_items: TRENDING_TOPICS,
};

const mobileNewsletters = {
  label: "Newsletters",
  link: "/newsletters",
};

export const mobileNavigationLayoutWithReports: Required<
  NavigationGroupItem | NavigationItem
>[] = [
  mobileIndusties,
  mobilePolitics,
  mobileTopics,
  {
    label: "Reports",
    child_items: REPORTS,
  },
  mobileNewsletters,
];

const INDUSTRY_GROUP = {
  label: "Industries",
  child_items: [
    {
      label: "Industries",
      child_items: INDUSTRIES,
    },
    {
      label: "Politics & Economics",
      child_items: POLITICS_AND_ECONOMICS,
    },
  ],
};

const TOPICS = {
  label: "Topics",
  child_items: TRENDING_TOPICS,
};

const NEWSLETTERS = {
  label: "Newsletters",
  link: "/newsletters",
};

export const desktopNavigationLayoutWithReports: NavigationResponse = [
  INDUSTRY_GROUP,
  TOPICS,
  {
    label: "Reports",
    child_items: REPORTS,
  },
  NEWSLETTERS,
];
