export const sharedPillStyles = [
  "w-fit",
  "border-1",
  "rounded-[50px]",
  "whitespace-nowrap",
];

export const pillAppearances = {
  default: ["bg-primary-50", "border-gray-700", "text-gray-800"],
  primary: ["bg-primary-100", "border-primary-300", "text-primary"],
  selected: ["bg-gray-700", "border-gray-700", "text-white"],
  subtle: ["bg-transparent", "border-gray-300", "text-gray-800"],
  success: ["bg-success-100", "border-success-900", "text-success-900"],
};
