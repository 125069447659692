import { commonConfig } from "~/config/common-config";
import { useSubscriptionStatus } from "~/lib/client/useSubscriptionStatus";
import { usePianoResourceAccessList } from "./usePianoResourceAccessList";
import { useIsPaywallShown } from "./useIsPaywallShown";

/**
 * Determine whether to gate content.
 *
 * Do not gate content in following scenarios:
 *
 * 1. Piano indicatees the user has access
 * 2. Active subscription via JWT
 * 3. Meter has not expired
 *
 * @returns boolean
 */
export const useShouldGateContent = (): boolean => {
  /**
   * Only individuals or teams who subscribe via Piano / self-service
   * will have the resource
   */
  const list = usePianoResourceAccessList();
  const hasResourceAccess = list.includes(
    commonConfig.NEXT_PUBLIC_PIANO_PRO_RESOURCE
  );

  /**
   * Self-service and enterprise users can have active subscriptions.
   *
   * This depends on the Okta JWT.
   */
  const hasActiveSubscription = useSubscriptionStatus().status === "subscribed";
  const isPaywallShown = useIsPaywallShown();

  return !(hasResourceAccess || hasActiveSubscription) && isPaywallShown;
};
