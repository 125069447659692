import { NextSeoProps } from "next-seo";
import { AsgardLink } from "~/types/asgard/AsgardLink";
import { ImageDetails } from "~/types/asgard/ImageDetails";
import { PostDetails } from "~/types/asgard/PostDetails";
import { PostSummary } from "~/types/asgard/PostSummary";
import { StructureDataProps } from "~/types/asgard/StructuredData";

type ReportLibraryTab = {
  categoryPosts: PostDetails[];
  category: {
    termId: number;
    name: string;
    slug: string;
  };
};

export enum ReportHorizontal {
  industry = "industry",
  economic = "economic",
  political = "political",
}

export type ReportLibraryTopic = {
  title: string;
  slug: string;
  horizontal: ReportHorizontal;
};

export type ReportLibraryEntryPagePayload = {
  featured: {
    posts: PostDetails[];
  };
  subscribe: {
    title: string;
    description: string;
    valuePropCta: AsgardLink;
    backgroundImage: ImageDetails | null;
  };
  industryReportsPromo: {
    description: string;
    title: string;
    tabs: ReportLibraryTab[];
  };
  contentPromo: {
    title: string;
    posts: PostSummary[];
  }[];
  seo: NextSeoProps;
  structuredData: StructureDataProps;
};

export type ReportPackageDetail = {
  postId: string;
  postTitle: string;
  publishDate: string | null;
  attachmentId: string;
  slug: string;
};

export type ReportLibraryPackage = {
  /** Human-readable label */
  date: string;
  entries: ReportPackageDetail[];
};

export type UpcomingReport = {
  date: string;
  title: string;
};

export type UpcomingReportsPayload = {
  posts: UpcomingReport[] | [];
  schedule: string | null;
};

export type ReportLibraryHorizontalPagePayload = {
  hero: {
    kicker: string;
    title: string;
    description: string;
    backgroundImage: ImageDetails;
  };
  heroCtas: {
    title?: string;
    description?: string;
    primaryCta: {
      title: string;
      url: string;
    };
    secondaryCta: {
      title: string;
      url: string;
    } | null;
  } | null;
  featured: {
    featuredPost: PostDetails;
    featuredDescription: string;
  };
  authors: { id: string; name: string; count: string }[];
  tags: { id: string; name: string }[];
  categories: { id: string; name: string; count: string }[];
  upcomingReports: UpcomingReportsPayload;
  reportPackages: ReportLibraryPackage[] | null;
  seo: NextSeoProps;
  structuredData: StructureDataProps;
};
