export type ChartPackIconProps = {};

export const ChartPackIcon: React.FC<ChartPackIconProps> = (
  props: JSX.IntrinsicElements["svg"]
) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1674_8925)">
      <path
        d="M4.32617 7.76562V9.93548"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M8.78125 3.24219V9.93449"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M6.58789 5.74219V9.93344"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M11.9202 1.24219H1.24219V11.9202H11.9202V1.24219Z"
        strokeLinejoin="round"
      />
      <path
        d="M12.0467 3.89062H14.5706V14.5686H3.89258V11.9191"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath>
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
);
