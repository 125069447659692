import { cx } from "cva";
import { PropsWithChildren } from "react";
import { useShouldGateContent } from "~/components/Piano/useShouldGateContent";

export const GatedContent = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => {
  const shouldGateForPaywall = useShouldGateContent();
  return (
    // eslint-disable-next-line tailwindcss/no-custom-classname
    <div className="gated relative">
      {/* Render piano registration wall anchor off screen 
  so that if there's timing issues with callbacks and renders
  Piano's template always has an anchor. */}
      <div
        className={cx(
          "pointer-events-none absolute z-20 h-[400px] w-full lg:-left-m lg:max-w-[960px]",
          "bg-gradient-to-t from-white from-0% to-transparent to-100%",
          shouldGateForPaywall ? "" : "invisible"
        )}
        aria-hidden={shouldGateForPaywall ? undefined : true}
        data-testid="registration-wall"
      >
        <div
          className="pointer-events-auto h-[400px]"
          id="piano-registration-wall-anchor"
        ></div>
      </div>
      <div className={shouldGateForPaywall ? "h-[400px] overflow-hidden" : ""}>
        {children}
      </div>
    </div>
  );
};
