import { cx } from "cva";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { useShouldGateContent } from "~/components/Piano/useShouldGateContent";
import { useSubscriptionStatus } from "~/lib/client/useSubscriptionStatus";

type FooterPaywallProps = JSX.IntrinsicElements["div"];

export const FooterPaywall: React.FC<FooterPaywallProps> = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { events } = useRouter();

  const shouldGateForPaywall = useShouldGateContent();
  const { status } = useSubscriptionStatus();

  useEffect(() => {
    const handleRouteChange = () => {
      ref.current?.classList.add("hidden");
    };
    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      events.on("routeChangeComplete", handleRouteChange);
    };
  }, [events]);

  return (
    <div
      className={cx(
        "sticky bottom-0 z-30 w-full",
        !shouldGateForPaywall || status !== "not-subscribed" ? "invisible" : ""
      )}
      id="footer-paywall-container"
      data-testid="footer-paywall-container"
      ref={ref}
    >
      <div id="piano-footer-paywall"></div>
    </div>
  );
};
