import { datadogRum } from "@datadog/browser-rum";
import { getSession, useSession } from "next-auth/react";
import { useCallback } from "react";
import { useAnalytics } from "~/components/Analytics";
import { syncPermissions } from "~/lib/client/endilApiCalls";

export const useSyncPermissions = () => {
  const { data, status, update } = useSession();
  const { analyticsRefreshUserIdentify } = useAnalytics();
  return useCallback(
    async (
      shouldHaveProFullAccess: boolean,
      maxRefreshAttempts = 3,
      refreshDelay = 1000
    ): Promise<boolean> => {
      let refreshAttempts = 0;
      let hasProFullAccess = Boolean(data?.user.isActiveSubscriber);
      if (status !== "authenticated") {
        return false;
      }
      // Poll for updated permissions as a safeguard against sequencing issues with Piano, Kairuku, and accounts
      while (
        refreshAttempts < maxRefreshAttempts &&
        hasProFullAccess !== shouldHaveProFullAccess
      ) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await syncPermissions();
          // eslint-disable-next-line no-await-in-loop
          await update();
          // eslint-disable-next-line no-await-in-loop
          const refreshSessionData = await getSession();
          hasProFullAccess = Boolean(
            refreshSessionData?.user.isActiveSubscriber
          );
          // Adding this condition here ensures analyticsRefreshUserIdentify is only called
          // if a sync is needed (while loop is skipped otherwise) and successful
          if (hasProFullAccess === shouldHaveProFullAccess) {
            analyticsRefreshUserIdentify();
          }
          // eslint-disable-next-line no-await-in-loop
          await new Promise((resolve) => {
            setTimeout(resolve, refreshDelay);
          });
        } catch (err) {
          datadogRum.addError(err, {
            message: `An error occurred when attempting to sync permissions`,
            user_id: data?.mcAccountId,
          });
        }
        refreshAttempts += 1;
      }
      if (hasProFullAccess !== shouldHaveProFullAccess) {
        datadogRum.addError({
          message: `Failed to sync permissions after ${maxRefreshAttempts} attempts`,
          user_id: data?.mcAccountId,
        });
        return false;
      }
      return true;
    },
    [analyticsRefreshUserIdentify, data, status, update]
  );
};
