import { useMemo } from "react";
import { z } from "zod";
import { commonConfig } from "~/config/common-config";
import { useCookie } from "~/lib/client/useCookie";

const schema = z.object({
  iss: z.enum(["https://www.piano.io"]),
  aud: z.enum([commonConfig.NEXT_PUBLIC_PIANO_APPLICATION_ID]),
  sub: z.preprocess(
    // Hard to get preprocess step to give a non-string
    /* istanbul ignore next */
    (str) => (typeof str === "string" ? JSON.parse(str) : {}),
    z.object({
      u: z.string(),
      al: z
        .object({
          [commonConfig.NEXT_PUBLIC_PIANO_APPLICATION_ID]: z
            .object({
              r: z.string(),
              e: z.number().optional(),
              ia: z.boolean().optional(),
              t: z.string().optional(),
            })
            .array(),
        })
        .optional()
        .default({
          [commonConfig.NEXT_PUBLIC_PIANO_APPLICATION_ID]: [],
        }),
    })
  ),
  exp: z.number(),
  iat: z.number(),
});

export type PianoAccessListJWTPayload = z.infer<typeof schema>;

/**
 * This method returns the list of _resources_ that the
 * user has access to. This list is encoded in the
 * __tac = "tinypass access list" cookie.
 *
 * @returns string[] the list of piano resource IDs
 */
export const usePianoResourceAccessList = (): string[] => {
  const cookie = useCookie("__tac");
  return useMemo((): string[] => {
    try {
      if (!cookie) {
        return [];
      }
      const decoded = JSON.parse(atob(cookie.split(".")[1]));
      const parsed = schema.safeParse(decoded);
      if (parsed.success && Date.now() < parsed.data.exp * 1000) {
        return parsed.data.sub.al[
          commonConfig.NEXT_PUBLIC_PIANO_APPLICATION_ID
        ].map((accessListItem) => accessListItem.r);
      }
    } catch (err) {
      console.error("cannot parse __tac", err);
    }
    return [];
  }, [cookie]);
};
