import { useMediaQuery } from "~/lib/client/useMediaQuery";
import { ScreenSize } from "~/ui-library/design-tokens";

/**
 * **Do not use this hook to conditionally render the UI, as it is has a high likelihood to result in layout shift.**
 * The reason for this is because we cannot determine which breakpoint the user is at in the UI on the server,
 * so we should only use this to execute code based on breakpoint changes.
 *
 * @returns `true` when the window screen width is beyond a given breakpoint's width,
 * and `false` when it is below the given breakpoint's width.
 *
 * @example
 * import { useActiveBreakpoint } from "~/lib/client/useActiveBreakpoint";
 *
 * // Controlled drawer component which will close when going into desktop screen
 * export const MyMobileDrawer = ({ open, isOpen }) => {
 *   const isDesktopScreen = useActiveBreakpoint("LARGE");
 *
 *   useEffect(() => {
 *     if (isDesktopScreen) {
 *       setOpen(false);
 *     }
 *   }, [isDesktopScreen]);
 *
 *   return <Drawer isOpen={isOpen} />
 * }
 */
export const useActiveBreakpoint = (screenSize: keyof typeof ScreenSize) =>
  useMediaQuery(`(min-width: ${ScreenSize[screenSize]}px)`);
