export const EnergyIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7650_93357)">
      <path
        d="M20.4018 11.8667L15.2668 10.0983V0H14.6001C13.3624 0 12.1754 0.491665 11.3003 1.36683C10.4251 2.242 9.93343 3.42899 9.93343 4.66667V10.3017L0.600098 14.655L0.881764 15.2583C1.40464 16.3794 2.35122 17.247 3.51349 17.6705C4.67575 18.0939 5.9586 18.0386 7.0801 17.5167L11.9334 15.2533V22.6667H9.26676V24H15.9334V22.6667H13.2668V15.05L23.0784 18.4283L23.2951 17.7983C23.4946 17.2189 23.578 16.6058 23.5406 15.9941C23.5032 15.3824 23.3456 14.7841 23.0769 14.2333C22.8083 13.6825 22.4337 13.19 21.9747 12.7839C21.5157 12.3778 20.9812 12.0662 20.4018 11.8667ZM12.6001 13.3333C12.3364 13.3333 12.0786 13.2551 11.8593 13.1086C11.6401 12.9621 11.4692 12.7539 11.3683 12.5102C11.2673 12.2666 11.2409 11.9985 11.2924 11.7399C11.3438 11.4812 11.4708 11.2437 11.6573 11.0572C11.8438 10.8707 12.0813 10.7437 12.34 10.6923C12.5986 10.6408 12.8667 10.6672 13.1103 10.7682C13.354 10.8691 13.5622 11.04 13.7087 11.2592C13.8552 11.4785 13.9334 11.7363 13.9334 12C13.9334 12.3536 13.793 12.6928 13.5429 12.9428C13.2929 13.1929 12.9537 13.3333 12.6001 13.3333ZM11.2668 4.66667C11.2666 3.89823 11.532 3.15334 12.0179 2.55806C12.5038 1.96279 13.1805 1.55369 13.9334 1.4V9.63833L13.4684 9.47167C13.1445 9.35699 12.8011 9.30723 12.458 9.32525C12.1148 9.34327 11.7786 9.4287 11.4684 9.57667L11.2668 9.68V4.66667ZM6.51676 16.3083C5.82084 16.6321 5.03451 16.7057 4.29056 16.5169C3.54662 16.328 2.89065 15.8882 2.43343 15.2717L9.93343 11.7717V12C9.93393 12.4288 10.0378 12.8511 10.2363 13.2312C10.4347 13.6112 10.7219 13.9379 11.0734 14.1833L6.51676 16.3083ZM22.1834 16.7117L14.3451 14.0117C14.6338 13.7619 14.8654 13.453 15.0244 13.1059C15.1834 12.7589 15.2661 12.3817 15.2668 12V11.5L19.9684 13.1183C20.6965 13.3687 21.3155 13.8635 21.7202 14.5185C22.1249 15.1735 22.2903 15.9485 22.1884 16.7117H22.1834Z"
        fill="#4657CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_7650_93357">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.600098)"
        />
      </clipPath>
    </defs>
  </svg>
);
