import { Dialog, Transition } from "@headlessui/react";
import { Fragment, ReactNode, useEffect, useId } from "react";
import { useGlobalModals } from "~/components/GlobalState";
import { overlayStyles } from "~/ui-library/shared-styles/shared-overlay-styles";

export type ModalProps = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onOverlayClick?: () => void;
};

export const Modal: React.FC<ModalProps> = ({
  children,
  className,
  isOpen,
  onOverlayClick = () => {},
}) => {
  const modalId = useId();
  const { openModal, closeModal } = useGlobalModals();

  useEffect(() => {
    if (isOpen) {
      openModal(modalId);
    } else {
      closeModal(modalId);
    }
  }, [isOpen, modalId, openModal, closeModal]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-100" onClose={onOverlayClick}>
          <div className="fixed inset-0 overflow-y-auto">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-default"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-slow"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0"
            >
              <div
                className={overlayStyles({ isVisible: isOpen })}
                data-testid="pro-modal-overlay"
              ></div>
            </Transition.Child>
            <div className="grid min-h-full place-items-center p-s">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-slow"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={className}
                  data-testid="pro-modal-content"
                >
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
