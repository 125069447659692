import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMcProCondensedLogoColor = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={66}
    height={16}
    fill="none"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g clipPath="url(#a)">
      <path
        fill="url(#b)"
        d="M27.553 3.003h4.256c2.128 0 3.307 1.444 3.307 3.192 0 1.748-1.216 3.192-3.307 3.192h-2.926v4.219h-1.33V3.003Zm4.104 1.178h-2.774v4.028h2.774c1.254 0 2.09-.836 2.09-2.014s-.874-2.014-2.09-2.014M42.49 9.349h-2.09v4.219h-1.33V3.003h4.256c1.938 0 3.306 1.254 3.306 3.192 0 1.938-1.292 2.964-2.736 3.078l2.85 4.295h-1.558l-2.698-4.219m.684-5.206h-2.775V8.17h2.775c1.254 0 2.09-.836 2.09-2.014s-.874-2.014-2.09-2.014m12.58-1.33c3.116 0 5.244 2.356 5.244 5.472 0 3.117-2.128 5.473-5.245 5.473-3.116 0-5.244-2.356-5.244-5.473 0-3.116 2.09-5.472 5.244-5.472m0 1.178c-2.395 0-3.877 1.824-3.877 4.294 0 2.47 1.52 4.295 3.876 4.295s3.877-1.825 3.877-4.295c0-2.47-1.52-4.294-3.877-4.294Z"
      />
      <path
        fill="#2B2B2B"
        d="M0 0v6.023l8.005 8.005 8.006-8.005V0L8.005 8.005zm16.049 16.049v-6.023l-6.023 6.023h6.023M0 16.049h6.023L0 10.026v6.023M62.821 2.737h-.266v-1.9h-.608V.57h1.482v.266h-.608v1.9M65.596.95l-.722 1.787h-.076L64.076.95v1.787h-.266V.57h.418l.646 1.559L65.52.57h.418v2.167h-.266V.95h-.076"
      />
    </g>
    <defs>
      <linearGradient
        id="b"
        x1={32.546}
        x2={53.156}
        y1={18.599}
        y2={-2.01}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4F18F6" />
        <stop offset={0.136} stopColor="#482AF2" />
        <stop offset={0.41} stopColor="#385BE6" />
        <stop offset={0.794} stopColor="#1CA7D4" />
        <stop offset={1} stopColor="#0DD3C9" />
      </linearGradient>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h65.9v16H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgMcProCondensedLogoColor;
