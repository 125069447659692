import React, { Component } from "react";
import { Body } from "~/ui-library";
import { datadogRum } from "@datadog/browser-rum";

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallback?: React.ReactNode;
  context?: Record<string, unknown>;
}

interface State {
  error: Error | null;
  hasError: boolean;
}

const ErrorBoundaryDefault = () => (
  <div className="flex flex-col gap-xl">
    <h1>Sorry, this page is not available.</h1>
    <Body size="large">Try searching for a topic or visit the homepage.</Body>
  </div>
);

export default class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  State
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
      hasError: false,
    };
  }

  static getDerivedStateFromError(error: Error): State {
    return { error, hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: string | Error): void {
    datadogRum.addError(error, {
      ...this.props.context,
      message: "Error caught in ErrorBoundary component",
    });
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      return this.props.fallback || <ErrorBoundaryDefault />;
    }

    return this.props.children;
  }
}
