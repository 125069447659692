import { VariantProps } from "cva";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { layoutBannerStyles } from "~/components/Layout/shared-layout-styles";

type HeaderBannerProps = JSX.IntrinsicElements["div"] &
  Required<VariantProps<typeof layoutBannerStyles>>;

export const HeaderBanner: React.FC<HeaderBannerProps> = ({
  usingNewNavigation,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { events } = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      ref.current?.classList.add("hidden");
    };
    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      events.on("routeChangeComplete", handleRouteChange);
    };
  }, [events]);

  return (
    <div className={layoutBannerStyles({ usingNewNavigation })} {...rest}>
      <div
        id="header-banner-container"
        data-testid="header-banner-container"
        ref={ref}
      >
        <div id="piano-header-banner"></div>
      </div>
    </div>
  );
};
