import he from "he";

export const heDecodeOrDefault = (
  valueToDecode: any,
  defaultValue: any = ""
) => {
  if (typeof valueToDecode !== "string") {
    return defaultValue;
  }
  return he.decode(valueToDecode);
};
