import React from "react";
import { useLoginBehavior, LoginBehavior } from "~/lib/client/useLoginBehavior";

export type LoginWrapperProps<T extends React.ElementType> = {
  as?: T;
  behavior?: LoginBehavior;
} & React.ComponentPropsWithoutRef<T>;

export function LoginWrapper<T extends React.ElementType>({
  as,
  onClick,
  behavior = {
    type: "window",
  },
  children,
  ...rest
}: LoginWrapperProps<T>): JSX.Element {
  const Component = as || "button";
  const handler = useLoginBehavior();
  return (
    <Component
      {...rest}
      onClick={(event) => {
        if (onClick !== undefined) {
          onClick(event);
        }
        if (event.defaultPrevented) {
          return;
        }
        handler(behavior);
      }}
    >
      {children}
    </Component>
  );
}
