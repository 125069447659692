import { useSession, signOut } from "next-auth/react";
import { useEffect } from "react";
import { useLoginBehavior } from "~/lib/client/useLoginBehavior";
import { useToastManager } from "~/components/ToastManager";
import { Button } from "~/ui-library";

export const SessionManager = (): null => {
  const { data } = useSession();
  const login = useLoginBehavior();
  const { addToast, dismissToast } = useToastManager();

  const sessionError = data?.error;
  useEffect(() => {
    if (sessionError === "RefreshAccessTokenError") {
      signOut({ redirect: false });
      const id = addToast(
        "danger",
        <div className="flex flex-row items-center">
          <span>Your session has expired</span>
          <Button
            variant="primary"
            className="break-normal"
            onClick={() => {
              login({ type: "window" });
              dismissToast(id);
            }}
          >
            Login
          </Button>
        </div>,
        {
          autoClose: false,
        }
      );
    }
  }, [sessionError, login, addToast, dismissToast]);
  return null;
};
