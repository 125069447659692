import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgMcProCondensedLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={173.4}
    height={42.1}
    data-name="Layer 2"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g data-name="Layer 1">
      <g fill="#2b2b2b">
        <path d="M72.5 7.9h11.2c5.6 0 8.7 3.8 8.7 8.4s-3.2 8.4-8.7 8.4H76v11.1h-3.5V7.9ZM83.3 11H76v10.6h7.3c3.3 0 5.5-2.2 5.5-5.3S86.5 11 83.3 11M111.8 24.6h-5.5v11.1h-3.5V7.9H114c5.1 0 8.7 3.3 8.7 8.4s-3.4 7.8-7.2 8.1l7.5 11.3h-4.1l-7.1-11.1Zm1.8-13.7h-7.3v10.6h7.3c3.3 0 5.5-2.2 5.5-5.3s-2.3-5.3-5.5-5.3ZM146.7 7.4c8.2 0 13.8 6.2 13.8 14.4s-5.6 14.4-13.8 14.4-13.8-6.2-13.8-14.4 5.5-14.4 13.8-14.4Zm0 3.1c-6.3 0-10.2 4.8-10.2 11.3s4 11.3 10.2 11.3 10.2-4.8 10.2-11.3-4-11.3-10.2-11.3" />
      </g>
      <path
        fill="#2b2b2b"
        d="M0 0v15.8l21 21 21-21V0L21 21zm42.1 42.1V26.3L26.3 42.1zM0 42.1h15.8L0 26.3z"
        data-name="Logo - Full - Black"
      />
      <path
        fill="#2b2b2b"
        d="M165.3 7.2h-.7v-5H163v-.7h3.9v.7h-1.6v5Zm7.3-4.7-1.9 4.7h-.2l-1.9-4.7v4.7h-.7V1.5h1.1l1.7 4.1 1.7-4.1h1.1v5.7h-.7V2.5Z"
      />
    </g>
  </svg>
);
export default SvgMcProCondensedLogo;
