import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { constants } from "~/common/constants";
import { JSONData } from "~/types/utility-types";

export type PersistentStateData = {
  isPersistentStateLoading: boolean;
  showFirstLoginModal: boolean;
  setShowFirstLoginModal: Dispatch<SetStateAction<boolean>>;
  registrationReminderEmail: string | null;
  setRegistrationReminderEmail: Dispatch<SetStateAction<string | null>>;
  processLoginStart: number | null;
  setProcessLoginStart: Dispatch<SetStateAction<number | null>>;
  processLoginSuccessID: string | null;
  setProcessLoginSuccessID: Dispatch<SetStateAction<string | null>>;
  isNavigationExpanded: boolean;
  setIsNavigationExpanded: Dispatch<SetStateAction<boolean>>;
};

type LocalStorageState = Pick<
  PersistentStateData,
  | "showFirstLoginModal"
  | "registrationReminderEmail"
  | "processLoginStart"
  | "processLoginSuccessID"
  | "isNavigationExpanded"
>;

const PersistentContext = createContext<PersistentStateData | null>(null);

export const usePersistentState = () => {
  const context = useContext(PersistentContext);

  if (context == null) {
    throw new Error(
      "usePersistentState must be wrapped by a PersistentStateProvider component!"
    );
  }

  return context;
};

// Provides persistent, cross window, global application state
export const PersistentStateProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [isPersistentStateLoading, setIsPersistentStateLoading] =
    useState(true);
  const [showFirstLoginModal, setShowFirstLoginModal] = useState(false);
  const [registrationReminderEmail, setRegistrationReminderEmail] = useState<
    null | string
  >(null);
  const [processLoginStart, setProcessLoginStart] = useState<number | null>(
    null
  );
  const [processLoginSuccessID, setProcessLoginSuccessID] = useState<
    string | null
  >(null);

  const [isNavigationExpanded, setIsNavigationExpanded] = useState(true);

  const processStorage = useCallback(() => {
    const stateKeyData: [keyof LocalStorageState, JSONData, Dispatch<any>][] = [
      ["showFirstLoginModal", showFirstLoginModal, setShowFirstLoginModal],
      [
        "registrationReminderEmail",
        registrationReminderEmail,
        setRegistrationReminderEmail,
      ],
      ["processLoginStart", processLoginStart, setProcessLoginStart],
      [
        "processLoginSuccessID",
        processLoginSuccessID,
        setProcessLoginSuccessID,
      ],
      ["isNavigationExpanded", isNavigationExpanded, setIsNavigationExpanded],
    ];

    const storageData = window.localStorage.getItem(
      constants.PERSISTENT_STATE_STORAGE_KEY
    );

    const storageState: LocalStorageState = (storageData &&
      JSON.parse(storageData)) || {
      showFirstLoginModal,
      registrationReminderEmail,
      processLoginStart,
      processLoginSuccessID,
      isNavigationExpanded,
    };

    // Only update local state if it is different from local storage. This uses
    // stringify for deep comparison of non-primitive (composite) types.
    stateKeyData.forEach(([keyName, currentValue, setNewValue]) => {
      if (storageState[keyName] === currentValue) {
        return;
      }
      /* The JSON.stringify comparison was added below to make it easy to extend 
      persistent state with non-primitive state in the future. Since there is no
      non-primitive state currently, this logic can't be tested and
      "istanbul ignore next" was added accordingly. Once any of non-primitive 
      state is added, the tests should be updated to exercise this logic and the 
      "istanbul ignore next" should be removed  */
      // istanbul ignore next
      if (
        JSON.stringify(storageState[keyName]) === JSON.stringify(currentValue)
      ) {
        return;
      }
      setNewValue(storageState[keyName]);
    });
  }, [
    showFirstLoginModal,
    setShowFirstLoginModal,
    registrationReminderEmail,
    setRegistrationReminderEmail,
    processLoginStart,
    setProcessLoginStart,
    processLoginSuccessID,
    setProcessLoginSuccessID,
    isNavigationExpanded,
    setIsNavigationExpanded,
  ]);

  // Process local storage the first time this loads. Loading state
  // lets us set defaults that avoid hydration errors.
  useEffect(() => {
    processStorage();
    setIsPersistentStateLoading(false);
  }, []);

  // Any change to the local state, writes to local storage. Other windows
  // will call processStorage which will complete a deep comparison before
  // updating state.
  useEffect(() => {
    if (isPersistentStateLoading) {
      return;
    }
    window.localStorage.setItem(
      constants.PERSISTENT_STATE_STORAGE_KEY,
      JSON.stringify({
        showFirstLoginModal,
        registrationReminderEmail,
        processLoginStart,
        processLoginSuccessID,
        isNavigationExpanded,
      })
    );
  }, [
    isNavigationExpanded,
    isPersistentStateLoading,
    showFirstLoginModal,
    registrationReminderEmail,
    processLoginStart,
    processLoginSuccessID,
  ]);

  useEffect(() => {
    const storageEventHandler = (e: StorageEvent) => {
      if (e.key === constants.PERSISTENT_STATE_STORAGE_KEY) {
        processStorage();
      }
    };
    // "storage" events are only triggered by local storage changes that originate in in OTHER tabs/windows
    // see: https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event
    window.addEventListener("storage", storageEventHandler);
    return () => window.removeEventListener("storage", storageEventHandler);
  }, [processStorage]);

  return (
    <PersistentContext.Provider
      value={{
        // isPersistentStateLoading should be exposed by any hooks that cannot use the default state
        isPersistentStateLoading,
        showFirstLoginModal,
        setShowFirstLoginModal,
        registrationReminderEmail,
        setRegistrationReminderEmail,
        processLoginStart,
        setProcessLoginStart,
        processLoginSuccessID,
        setProcessLoginSuccessID,
        isNavigationExpanded,
        setIsNavigationExpanded,
      }}
    >
      {children}
    </PersistentContext.Provider>
  );
};
