import { cx } from "cva";
import { Menu as BaseMenu } from "@headlessui/react";
import Link from "next/link";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Icon, IconProps } from "~/ui-library";

export type NavigationMenuLinkProps = {
  children: React.ReactNode;
  className?: string;
  href: string;
  onClick?: (e: React.MouseEvent) => void;
  /** icon to prepend the navigation item */
  SvgIcon?: IconProps["SvgIcon"];
};

export function NavigationMenuLink({
  children,
  className,
  href,
  onClick,
  SvgIcon,
}: NavigationMenuLinkProps) {
  const [width, setWidth] = useState<string | undefined>("");
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    // istanbul ignore if
    if (ref.current && ref.current.clientWidth > 0) {
      setWidth(`${ref.current.clientWidth}px`);
    }
  }, [ref.current?.clientWidth]);

  return (
    <BaseMenu.Item as={Fragment}>
      {({ active }) => (
        <Link
          className={cx(
            "flex items-center whitespace-nowrap border-none",
            className,
            /* istanbul ignore next */
            active ? "font-semibold text-primary" : "",
            "focus-visible:outline-none"
          )}
          href={href}
          legacyBehavior={false}
          onClick={onClick}
        >
          {SvgIcon ? (
            <Icon
              className="mr-xs fill-transparent"
              SvgIcon={SvgIcon}
              size="small"
            />
          ) : null}
          <div
            className="text-body-small leading-body"
            ref={ref}
            style={{ width }}
          >
            {children}
          </div>
        </Link>
      )}
    </BaseMenu.Item>
  );
}
