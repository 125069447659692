import { Attribute } from "parse5/dist/common/token";
import { sanitizeUrl } from "@braintree/sanitize-url";
import { commonConfig } from "~/config/common-config";

export interface AttrToProps {
  (attribute: Attribute): { [key: string]: any };
}

const isEventHandlerRegex = /^on/i;

const validateAttributeIsNotEventHandler = (attributeName: string): void => {
  if (isEventHandlerRegex.test(attributeName)) {
    throw new Error(
      `Event handlers may not be converted to props. ${attributeName} is invalid!`
    );
  }
};

// See: https://html.spec.whatwg.org/multipage/common-microsyntaxes.html#boolean-attributes
export const createBooleanConverter = (attributeName: string): AttrToProps => {
  validateAttributeIsNotEventHandler(attributeName);
  return (attribute) => {
    if (attribute.name !== attributeName) {
      return {};
    }
    return { [attributeName]: true };
  };
};

export const createSetValueConverter = (
  attributeName: string,
  values: Set<string>
): AttrToProps => {
  validateAttributeIsNotEventHandler(attributeName);
  return (attribute) => {
    if (attribute.name !== attributeName) {
      return {};
    }
    if (!values.has(attribute.value)) {
      return {};
    }
    return {
      [attributeName]: attribute.value,
    };
  };
};

export const createTextConverter = (attributeName: string): AttrToProps => {
  validateAttributeIsNotEventHandler(attributeName);
  return (attribute) => {
    if (attribute.name !== attributeName) {
      return {};
    }
    return {
      [attributeName]: attribute.value,
    };
  };
};

export const createTextOrBooleanConverter = (
  attributeName: string
): AttrToProps => {
  validateAttributeIsNotEventHandler(attributeName);
  return (attribute) => {
    if (attribute.name !== attributeName) {
      return {};
    }
    if (attribute.value === "") {
      return { [attributeName]: true };
    }
    return {
      [attributeName]: attribute.value,
    };
  };
};

export const createURLConverter = (attributeName: string): AttrToProps => {
  validateAttributeIsNotEventHandler(attributeName);
  return (attribute) => {
    if (attribute.name !== attributeName) {
      return {};
    }
    if (attribute.value === "") {
      return { [attribute.name]: "" };
    }
    const url = sanitizeUrl(attribute.value);
    return {
      [attributeName]: url.startsWith(commonConfig.NEXT_PUBLIC_ENDIL_URL)
        ? url
            .replace(commonConfig.NEXT_PUBLIC_ENDIL_URL, "")
            .replace(/^\/?/, "/")
        : url,
    };
  };
};

const whitelistedProperties = [
  "color",
  "font-weight",
  "font-style",
  "text-align",
];

/** When finding these keywords in a style attribute, throw the entire attribute out */
const unsafeStyleInlineKeywords = ["url", "script"];

export const createStyleConverter = (): AttrToProps => (attribute) => {
  if (attribute.name !== "style" || attribute.value === "") {
    return {};
  }

  const properties = attribute.value
    .split(";")
    .filter((cssString) => cssString.includes(":"));

  const stylesAsObject = properties.reduce(
    (acc, rawValue) => {
      const [cssPropertyName, cssPropertyValue] = rawValue.split(":");
      const normalizedPropertyName = cssPropertyName.trim().toLowerCase();

      if (
        !whitelistedProperties.includes(normalizedPropertyName) ||
        unsafeStyleInlineKeywords.some((keyword) =>
          rawValue.toLowerCase().includes(keyword)
        )
      ) {
        return acc;
      }

      return {
        ...acc,
        [normalizedPropertyName]: cssPropertyValue.trim(),
      };
    },
    {} as Record<string, string>
  );

  return { style: stylesAsObject };
};
