import { Dialog } from "@headlessui/react";
import { useState } from "react";
import { Button, Card, IconButton, Logo, Body } from "~/ui-library";
import { McProCondensedLogoColor } from "~/ui-library/logos";
import { ArrowLeftIcon, XMarkIcon } from "~/ui-library/icons";
import {
  RegisterAccountForm,
  RegisterAccountFormProps,
} from "~/components/RegisterAccountForm";
import {
  RegisterAccountSelectIDP,
  RegisterAccountSelectIDPProps,
} from "~/components/RegisterAccountSelectIDP";
import { useRegisterAccountModal } from "~/components/GlobalState";
import { Modal } from "~/components/Modal";
import { useRouter } from "next/router";

type Closeable = {
  onClose: () => void;
};

const ContinueWithAccountView: React.FC<
  RegisterAccountSelectIDPProps & Closeable
> = ({ onEmailSignUp, onClose, postLoginRedirect }) => (
  <>
    <div className="grid grid-cols-3 border-b-1 border-gray-300 p-s">
      <Logo
        LogoComponent={McProCondensedLogoColor}
        className="col-start-2 place-self-center"
      />
      <IconButton
        onClick={onClose}
        size="default"
        SvgIcon={XMarkIcon}
        title="Close registration modal"
        variant="subtle"
        className="justify-self-end"
      />
    </div>
    <div className="p-xl pt-l">
      <Dialog.Title as="div" className="text-gray-900">
        <Body size="large" bold className="mb-m text-center">
          Create an Account
        </Body>
      </Dialog.Title>
      <RegisterAccountSelectIDP
        onEmailSignUp={onEmailSignUp}
        postLoginRedirect={postLoginRedirect}
      />
    </div>
  </>
);

const CreateAccountView: React.FC<
  RegisterAccountFormProps & Closeable & { onBackClick: () => void }
> = ({ onSubmit, onBackClick, onClose, postLoginRedirect }) => (
  <>
    <div className="grid grid-cols-[min-content,1fr,min-content] border-b-1 border-gray-300 px-s py-m">
      <IconButton
        onClick={onBackClick}
        size="default"
        SvgIcon={ArrowLeftIcon}
        title="Back"
        variant="subtle"
        className="justify-self-start"
        iconClassName="fill-primary"
      />
      <Dialog.Title as="div" className="place-self-center text-gray-900">
        <Body bold>Create an Account</Body>
      </Dialog.Title>
      <IconButton
        onClick={onClose}
        size="default"
        SvgIcon={XMarkIcon}
        title="Close registration modal"
        variant="subtle"
        className="justify-self-end"
      />
    </div>
    <div className="px-xl py-l">
      <RegisterAccountForm
        onSubmit={onSubmit}
        postLoginRedirect={postLoginRedirect}
      />
    </div>
  </>
);

const VerifyEmailView: React.FC<{ email: string } & Closeable> = ({
  email,
  onClose,
}) => (
  <>
    <div className="grid grid-cols-1 border-b-1 border-gray-300 px-s py-m">
      <Dialog.Title as="div" className="place-self-center text-gray-900">
        <Body bold>Check your inbox and verify your email address</Body>
      </Dialog.Title>
    </div>
    <div className="grid grid-cols-1 justify-items-stretch px-xl py-l">
      <Body className="mb-m text-center">
        We sent an email to <span className="text-gray-800">{email}</span>.
        Follow the instructions in the email to continue setting up your
        account.
      </Body>
      <Button onClick={onClose}>Close</Button>
    </div>
  </>
);

export const RegisterAccountModal: React.FC = () => {
  const { shouldShowRegisterAccountModal, setShouldShowRegisterAccountModal } =
    useRegisterAccountModal();
  const { asPath } = useRouter();
  const [isRegisterStep, setIsRegisterStep] = useState<boolean>(false);
  const [registeredEmail, setRegisteredEmail] = useState<string | null>(null);
  const onClose = () => {
    setShouldShowRegisterAccountModal(false);
    setRegisteredEmail(null);
    setIsRegisterStep(false);
  };

  let CurrentStep = (
    <ContinueWithAccountView
      onEmailSignUp={() => setIsRegisterStep(true)}
      onClose={onClose}
      postLoginRedirect={asPath}
    />
  );

  if (isRegisterStep) {
    CurrentStep = (
      <CreateAccountView
        onSubmit={({ email }) => {
          setRegisteredEmail(email);
          setIsRegisterStep(false);
        }}
        onBackClick={() => setIsRegisterStep(false)}
        onClose={onClose}
        postLoginRedirect={asPath}
      />
    );
  }

  if (registeredEmail) {
    CurrentStep = <VerifyEmailView email={registeredEmail} onClose={onClose} />;
  }

  return (
    <Modal
      isOpen={shouldShowRegisterAccountModal}
      className="grid grid-cols-[minmax(100%,480px)]"
    >
      <Card elevation={2} className="justify-self-stretch">
        {CurrentStep}
      </Card>
    </Modal>
  );
};
