import { useCallback } from "react";
import { useAnalytics } from "~/components/Analytics";

export const useSideNavigationAnalytics = () => {
  const { analyticsEnhancedTrack } = useAnalytics();
  return useCallback(
    (label: string) => {
      analyticsEnhancedTrack("Clicked Navigation", {
        "Clicked Item": label,
        "Nav Type": "Side Nav",
      });
    },
    [analyticsEnhancedTrack]
  );
};
