import {
  BeakerIcon,
  BookOpenIcon,
  ChartBarIcon,
  CurrencyDollarIcon,
  EnvelopeIcon,
  GlobeAmericasIcon,
  HomeIcon,
  MagnifyingGlassIcon,
  Squares2X2Icon,
  TagIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";

export type SideNavigationItem = {
  label: string;
  link: string;
  icon: React.ReactNode;
};

export type SideNavigationGroupItem = {
  label: string;
  icon: React.ReactNode;
  childItems: Omit<SideNavigationItem, "icon">[];
};

export const HOME: SideNavigationItem = {
  label: "Home",
  link: "/",
  icon: <HomeIcon aria-hidden="true" />,
};

export const SEARCH: SideNavigationItem = {
  label: "Search",
  link: "/search",
  icon: <MagnifyingGlassIcon />,
};

export const OUR_PRODUCTS: SideNavigationItem = {
  label: "Our Products",
  link: "/our-products",
  icon: <Squares2X2Icon aria-hidden="true" />,
};

export const RESEARCH_BLOG: SideNavigationItem = {
  label: "Research Blog",
  link: "/tags/research-blog",
  icon: <BeakerIcon aria-hidden="true" />,
};

export const CONSUMER_TRENDS: SideNavigationGroupItem = {
  label: "Consumer Trends",
  icon: <UsersIcon aria-hidden="true" />,
  childItems: [
    {
      label: "Brands & Marketing",
      link: "/verticals/brands",
    },
    {
      label: "Finance",
      link: "/verticals/finance",
    },
    {
      label: "Food & Beverage",
      link: "/verticals/food-and-beverage",
    },
    {
      label: "Media & Entertainment",
      link: "/verticals/media-entertainment",
    },
    {
      label: "Retail & E-Commerce",
      link: "/verticals/retail-e-commerce",
    },
    {
      label: "Sports",
      link: "/verticals/sports",
    },
    {
      label: "Tech",
      link: "/verticals/tech",
    },
    {
      label: "Travel & Hospitality",
      link: "/verticals/travel-hospitality",
    },
    {
      label: "Industry Reports",
      link: "/reports/industry",
    },
  ],
};

export const ECONOMIC_TRENDS: SideNavigationGroupItem = {
  label: "Economic Trends",
  icon: <CurrencyDollarIcon aria-hidden="true" />,
  childItems: [
    {
      label: "All Economic Analysis",
      link: "/verticals/economics",
    },
    {
      label: "U.S. Consumer Spending",
      link: "/trackers/consumer-spending-tracker",
    },
    {
      label: "U.S. Inflation and Supply Chains",
      link: "/trackers/inflation-and-supply-chains-tracker",
    },

    {
      label: "U.S. Jobs and Labor",
      link: "/trackers/jobs-and-labor-tracker",
    },
    {
      label: "Global Consumer Confidence",
      link: "/trackers/consumer-confidence-dashboard",
    },
    {
      label: "Economic Reports",
      link: "/reports/economic",
    },
  ],
};

export const POLITICAL_TRENDS: SideNavigationGroupItem = {
  label: "Political Trends",
  icon: <GlobeAmericasIcon aria-hidden="true" />,
  childItems: [
    {
      label: "U.S. Politics",
      link: "/verticals/us-politics",
    },
    {
      label: "Global Politics",
      link: "/verticals/geopolitics",
    },
    {
      label: "Political Reports",
      link: "/reports/political",
    },
  ],
};

export const TOPICS: SideNavigationGroupItem = {
  label: "Topics",
  icon: <TagIcon aria-hidden="true" />,
  childItems: [
    {
      label: "2024 Elections",
      link: "/tags/2024-elections",
    },
    {
      label: "Artificial Intelligence",
      link: "/tags/artificial-intelligence",
    },
    {
      label: "Brand Loyalty",
      link: "/tags/brand-loyalty",
    },
    {
      label: "China",
      link: "/tags/china",
    },
    {
      label: "Consumer Spending",
      link: "/tags/consumer-spending",
    },
    {
      label: "Gen Z",
      link: "/tags/gen-z",
    },
    {
      label: "Influencers",
      link: "/tags/influencers",
    },
    {
      label: "Key Consumer Groups",
      link: "/tags/morning-consult-audience",
    },
    {
      label: "Streaming",
      link: "/tags/streaming",
    },
    {
      label: "Workforce Trends",
      link: "/tags/workers",
    },
  ],
};

export const NEWSLETTERS: SideNavigationItem = {
  label: "Newsletters",
  link: "/newsletters",
  icon: <EnvelopeIcon aria-hidden="true" />,
};

export const REPORTS: SideNavigationItem = {
  label: "Reports",
  link: "/reports",
  icon: <BookOpenIcon aria-hidden="true" />,
};

export const TRACKERS: SideNavigationItem = {
  label: "Trackers",
  icon: <ChartBarIcon aria-hidden="true" />,
  link: "/trackers",
};
