import { VariantProps } from "cva";
import React from "react";
import { sharedChipStyles } from "~/ui-library/shared-styles/shared-chip-styles";

export interface ChipProps<T extends React.ElementType>
  extends VariantProps<typeof sharedChipStyles> {
  as?: T;
  children: React.ReactNode;
  className?: string;
}

export function Chip<T extends React.ElementType>({
  as,
  children,
  className,
  appearance,
  size,
  ...rest
}: ChipProps<T>): JSX.Element {
  const Component = as || "span";
  return (
    <Component
      {...rest}
      className={sharedChipStyles({ appearance, className, size })}
    >
      {children}
    </Component>
  );
}
