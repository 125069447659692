import { useRouter } from "next/router";
import { useEffect } from "react";
import { useAnalytics } from "~/components/Analytics";
import { useTopNav } from "~/components/NavigationProvider";
import { SearchInput, SearchInputProps } from "~/components/SearchInput";

export type LayoutSearchInputProps = {} & Omit<
  SearchInputProps,
  "onKeyDown" | "showClearButton" | "onChange" | "onClear" | "value"
>;

export function LayoutSearchInput({
  defaultValue,
  ...rest
}: LayoutSearchInputProps) {
  const router = useRouter();
  const { searchValue, setSearchValue, search } = useTopNav();
  const { analyticsEnhancedTrack } = useAnalytics();

  // On route transition, clear the input out.
  // Having the value persist in the search bar
  // could cause confusion when navigation across multiple pages
  useEffect(() => {
    if (!router.asPath.startsWith("/search")) {
      setSearchValue("");
    }
  }, [router.asPath, setSearchValue]);

  return (
    <SearchInput
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          analyticsEnhancedTrack("Search", { query: searchValue });
          search(searchValue);
        }
      }}
      showClearButton={Boolean(searchValue)}
      onChange={(e) => setSearchValue(e.target.value)}
      onClear={() => {
        setSearchValue("");
      }}
      value={searchValue}
      {...rest}
    />
  );
}
