import * as React from "react";
import type { SVGProps } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}
const SvgIconLogo = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 83 83"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <g data-name="Group 761">
      <g data-name="Group 762">
        <circle cx={41.5} cy={41.5} r={41.5} />
        <path
          d="M291.86 381.38v11.26l15 15 15-15v-11.26l-15 15Zm30 30v-11.25l-11.25 11.25Zm-30 0h11.25l-11.25-11.25Z"
          style={{
            fill: "#fff",
          }}
          transform="translate(-264.86 -354.57)"
        />
      </g>
    </g>
  </svg>
);
export default SvgIconLogo;
