export const HealthIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7650_93381)">
      <path
        d="M17.0666 7.33333V0H7.73324V7.33333H0.399902V16.6667H7.73324V24H17.0666V16.6667H24.3999V7.33333H17.0666ZM23.0666 15.3333H15.7332V22.6667H9.06657V15.3333H1.73324V8.66667H9.06657V1.33333H15.7332V8.66667H23.0666V15.3333Z"
        fill="#4657CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_7650_93381">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.399902)"
        />
      </clipPath>
    </defs>
  </svg>
);
