import { useSession } from "next-auth/react";
import { useUserSubscriptions } from "~/lib/client/useUserSubscriptions";

export type SubscriberStatus = "loading" | "subscribed" | "not-subscribed";

export type SubscriberStatusResult = {
  status: SubscriberStatus;

  /** iso string of the start of the current active subscription */
  subscriptionStartDate: string | null;

  /** iso string of the last known trial end date */
  proTrialEndDate: string | null;

  /** Whether or not the user has an active trial  */
  isInTrial: boolean;
};

export const useSubscriptionStatus = (): SubscriberStatusResult => {
  const { status, data: sessionData } = useSession();

  const { data: subscriptionData, isLoading: isLoadingSubscriptionData } =
    useUserSubscriptions({
      enabled: Boolean(sessionData),
      userId: sessionData?.mcAccountId,
    });

  let subscriptionStatus: SubscriberStatus;

  if (status === "loading" || isLoadingSubscriptionData) {
    subscriptionStatus = "loading";
  } else if (status === "authenticated") {
    subscriptionStatus = sessionData.user.isActiveSubscriber
      ? "subscribed"
      : "not-subscribed";
  } else {
    subscriptionStatus = "not-subscribed";
  }

  const proTrialEndDate = subscriptionData.proTrialEndDate
    ? new Date(subscriptionData.proTrialEndDate).toISOString()
    : null;

  if (!subscriptionData?.activeProSubscription) {
    return {
      isInTrial: false,
      proTrialEndDate,
      status: subscriptionStatus,
      subscriptionStartDate: null,
    };
  }

  return {
    isInTrial: subscriptionData.activeProSubscription.isInTrial,
    proTrialEndDate,
    subscriptionStartDate: new Date(
      subscriptionData.activeProSubscription.startDate
    ).toISOString(),
    status: subscriptionStatus,
  };
};
