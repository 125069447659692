import { VariantProps, cva } from "cva";
import Link from "next/link";
import React from "react";
import { extendedTwMerge } from "~/ui-library/design-tokens/extendedTwMerge";
import {
  GetButtonStyleProps,
  getButtonStyles,
} from "~/ui-library/shared-styles/shared-button-styles";

const buttonLinkStyles = cva(["flex"], {
  variants: {
    align: {
      start: ["items-start justify-start"],
      center: ["items-center justify-center"],
    },
  },
  defaultVariants: {
    align: "center",
  },
});

// Allow any native anchor props, along with all props needed to style as a button
export type ButtonLinkProps<T extends React.ElementType<any> = "a"> = {
  as?: T;
  href: string;
} & React.ComponentPropsWithoutRef<T> &
  VariantProps<typeof buttonLinkStyles> &
  GetButtonStyleProps;

export const ButtonLink: React.FC<ButtonLinkProps> = (props) => {
  const {
    align,
    as,
    children,
    className,
    disabled,
    fullWidth,
    size,
    variant,
    ...rest
  } = props;
  const Component = as || Link;
  return (
    <Component
      {...rest}
      className={extendedTwMerge(
        "flex items-center justify-center",
        getButtonStyles({
          disabled,
          fullWidth,
          size,
          variant,
        }),
        className
      )}
    >
      {children}
    </Component>
  );
};
