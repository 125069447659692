import { Menu } from "@headlessui/react";
import { cx } from "cva";
import ReactFocusLock from "react-focus-lock";
import { LayoutSearchInput } from "~/components/Layout/LayoutSearchInput/LayoutSearchInput";
import { MenuTransition } from "~/components/Menu";
import { NavigationMenuGroup } from "~/components/NavigationMenuGroup";
import { IconButton } from "~/ui-library";
import { MagnifyingGlassIcon } from "~/ui-library/icons";
import { menuItemsStyles } from "~/ui-library/shared-styles/shared-menu-styles";

export type SearchMenuProps = {};

export function SearchMenu() {
  return (
    <Menu>
      {({ open }) => (
        <div className="relative w-fit">
          <Menu.Button
            aria-label="Open the search menu"
            as={IconButton}
            iconClassName="fill-transparent"
            SvgIcon={MagnifyingGlassIcon}
            variant="subtle"
          />
          <MenuTransition isOpen={open}>
            <Menu.Items
              className={cx(
                "-right-m min-w-[350px] gap-m p-xxs",
                menuItemsStyles
              )}
            >
              <NavigationMenuGroup className="w-full" count={1}>
                <ReactFocusLock disabled={!open}>
                  <LayoutSearchInput
                    fullWidth
                    key="floating-search-input"
                    className="min-w-[250px]"
                  />
                </ReactFocusLock>
              </NavigationMenuGroup>
            </Menu.Items>
          </MenuTransition>
        </div>
      )}
    </Menu>
  );
}
