import {
  NavigationGroupItem,
  NavigationItem,
  NavigationResponse,
} from "~/types/asgard/MenuResponse";

const isNavigationGroupItem = (item: any): item is NavigationGroupItem =>
  Boolean(item.child_items);

export const apiToMenuDataProps = (
  apiData: NavigationResponse
): (NavigationGroupItem | NavigationItem)[] =>
  apiData.map((menuItem) => {
    if (isNavigationGroupItem(menuItem)) {
      return {
        label: menuItem.label,
        child_items: apiToMenuDataProps(menuItem.child_items),
      } satisfies NavigationGroupItem;
    }
    return {
      label: menuItem.label,
      link: menuItem.link,
    } satisfies NavigationItem;
  });
