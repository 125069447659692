import { useRouter } from "next/router";
import React, { useEffect } from "react";
import FocusLock from "react-focus-lock";
import {
  AppLogoResponsive,
  LayoutAppLogo,
} from "~/components/Layout/LayoutAppLogo";
import { LayoutSideNavigation } from "~/components/Layout/LayoutSideNavigation/LayoutSideNavigation";
import { NavigationAppBar } from "~/components/NavigationAppBar";
import { useTopNav } from "~/components/NavigationProvider";
import { useActiveBreakpoint } from "~/lib/client/useActiveBreakpoint";
import { IconButton } from "~/ui-library";
import { Flyout } from "~/ui-library/components/Flyout/Flyout";
import { Bars3Icon, XMarkIcon } from "~/ui-library/icons";

export interface LayoutAppBarProps<T extends React.ElementType> {
  as?: T;
}

const CaptureFlyoutClose: React.FC = () => {
  const { closeMenu } = useTopNav();

  const isLargeScreen = useActiveBreakpoint("LARGE");

  useEffect(() => {
    if (isLargeScreen) {
      closeMenu();
    }
  }, [closeMenu, isLargeScreen]);

  return null;
};

export function LayoutMobileNavigation<T extends React.ElementType = "header">({
  as,
}: LayoutAppBarProps<T>) {
  const router = useRouter();
  const { closeMenu, menuIsOpen, toggleMenu } = useTopNav();

  useEffect(() => {
    if (menuIsOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [menuIsOpen]);

  useEffect(() => {
    const closeAll = () => {
      closeMenu();
    };
    router.events.on("routeChangeStart", closeAll);
    return () => {
      router.events.off("routeChangeStart", closeAll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, router.asPath]);

  return (
    <>
      <NavigationAppBar
        as={as}
        className="sticky inset-0 z-[51] flex items-center justify-between print:hidden"
      >
        {/* MOBILE CONTENTS */}
        <div className="flex w-full justify-between gap-s p-xs lg:hidden">
          <div>
            <IconButton
              className={menuIsOpen ? "shadow-[0_0_0_2px] shadow-gray-300" : ""}
              onClick={toggleMenu}
              size="default"
              SvgIcon={menuIsOpen ? XMarkIcon : Bars3Icon}
              title={
                menuIsOpen ? "Close navigation menu" : "Open navigation menu"
              }
              variant="subtle"
            />
          </div>
          <LayoutAppLogo
            data-testid="app-logo-mobile"
            logo={<AppLogoResponsive />}
          />

          {/* placeholder icon to offset the logo to the center */}
          <div>
            <IconButton
              className={"invisible"}
              size="default"
              SvgIcon={XMarkIcon}
              variant="subtle"
            />
          </div>
        </div>
      </NavigationAppBar>
      <FocusLock disabled={!menuIsOpen}>
        <Flyout
          className="z-20 lg:hidden"
          fillScreen={false}
          isOpen={menuIsOpen}
          openFromDirection="left"
          closeOnOverlayClick
          showOverlay
          onClose={closeMenu}
        >
          <LayoutSideNavigation
            navigationBarClassName="xs:h-mobile-nav pt-s"
            showProductMenu={false}
          />
        </Flyout>
      </FocusLock>
      <CaptureFlyoutClose />
    </>
  );
}
