import { VariantProps, cva } from "cva";
import { extendedTwMerge } from "~/ui-library/design-tokens/extendedTwMerge";

const iconSizes = {
  extraSmall: 12,
  small: 16,
  default: 24,
  large: 32,
  scale: undefined,
};

export type IconSize = keyof typeof iconSizes;

const iconStyles = cva(["fill-current"], {
  variants: {
    display: {
      block: "block",
      inline: "inline",
    },
  },
  defaultVariants: {
    display: "inline",
  },
});

export type IconProps = React.SVGProps<SVGSVGElement> &
  VariantProps<typeof iconStyles> & {
    SvgIcon: React.ComponentType<React.ComponentPropsWithoutRef<"svg">>;
    /** Use a raw number for the icon's height and width. This will take precedent over the `size` prop */
    iconSize?: number;
    size?: IconSize;
    title?: string;
  };

export const Icon = ({
  SvgIcon,
  size,
  className,
  display,
  iconSize,
  ...rest
}: IconProps) => (
  <SvgIcon
    className={extendedTwMerge(iconStyles({ className, display }))}
    width={iconSize ?? iconSizes[size || "default"]}
    height={iconSize ?? iconSizes[size || "default"]}
    {...rest}
  />
);
