import { VariantProps, cva, cx } from "cva";
import { Body } from "~/ui-library";

const navMenuGroupStyles = cva(["grid grid-flow-col gap-x-l gap-y-m"], {
  defaultVariants: {
    rowCount: 5,
  },
  variants: {
    rowCount: {
      1: ["grid-rows-[repeat(1,_minmax(0,_min-content))]"],
      2: ["grid-rows-[repeat(2,_minmax(0,_min-content))]"],
      3: ["grid-rows-[repeat(3,_minmax(0,_min-content))]"],
      4: ["grid-rows-[repeat(4,_minmax(0,_min-content))]"],
      5: ["grid-rows-[repeat(5,_minmax(0,_min-content))]"],
    },
  },
});

type ValidRowCount = VariantProps<typeof navMenuGroupStyles>["rowCount"];

export type NavigationMenuGroupProps = {
  children: React.ReactNode;
  className?: string;
  count?: number;
  maxCount?: number;
  label?: string;
};

export function NavigationMenuGroup({
  children,
  className,
  label,
  count = 5,
  maxCount = 5,
}: NavigationMenuGroupProps) {
  // Allow at most 5 rows
  const rowCount = Math.min(maxCount, Math.max(0, count)) as ValidRowCount;

  return (
    <div className={cx(className)}>
      {label ? (
        <Body bold className="whitespace-nowrap pb-m" size="small">
          {label}
        </Body>
      ) : null}
      <div className={navMenuGroupStyles({ rowCount })}>{children}</div>
    </div>
  );
}
