import { Disclosure, Transition } from "@headlessui/react";
import { navigationMenuButtonStyles } from "~/components/Layout/shared-layout-styles";
import { Icon } from "~/ui-library";
import { ChevronDownIcon, ChevronUpIcon } from "~/ui-library/icons";

export type LayoutMobileGroupButtonProps = {
  children: React.ReactNode;
  label: string | undefined | null;
};

/** Component that will toggle collapse/expand state on click
 * Uses headlessui Disclosure for functionality
 */
export const LayoutMobileGroupButton: React.FC<
  LayoutMobileGroupButtonProps
> = ({ children, label }) => (
  <Disclosure>
    {({ open }) => (
      <div>
        <Disclosure.Button
          className={navigationMenuButtonStyles({
            active: open,
            bold: true,
            className: "outline-none",
          })}
        >
          <div className="flex items-center gap-xxs">
            <div>{label}</div>
            <Icon
              SvgIcon={open ? ChevronUpIcon : ChevronDownIcon}
              size="small"
            />
          </div>
        </Disclosure.Button>
        <Transition
          enter="transition ease-out duration-fast"
          enterFrom="opacity-0 -translate-y-m"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-fast"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-m"
          unmount={false}
        >
          <Disclosure.Panel unmount={false}>{children}</Disclosure.Panel>
        </Transition>
      </div>
    )}
  </Disclosure>
);
