import { cx } from "cva";
import { useEffect, useState } from "react";
import Turnstile, { type TurnstileProps } from "react-turnstile";
import { commonConfig } from "~/config/common-config";

export type TurnstileWidgetProps = Partial<TurnstileProps> &
  Required<Pick<TurnstileProps, "onVerify">>;

export const TurnstileWidget: React.FC<TurnstileWidgetProps> = ({
  className,
  ...props
}) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    setInit(true);
  }, []);

  return init ? (
    <Turnstile
      appearance="always"
      fixedSize
      {...props}
      sitekey={commonConfig.NEXT_PUBLIC_CLOUDFLARE_TURNSTILE_SITEKEY}
      // eslint-disable-next-line tailwindcss/no-custom-classname
      className={cx("turnstile-widget-container", className)}
    />
  ) : null;
};
