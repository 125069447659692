const target = new EventTarget();

type EventNames = "auto-renew-disabled";

export type AnalyticsEventCallback = {
  (event: Event): void;
};

type RemoveEventListener = {
  (): void;
};

export const dispatchEvent = (event: EventNames) => {
  target.dispatchEvent(new Event(event));
};

export const removeEventListener = (
  name: EventNames,
  cb: AnalyticsEventCallback
): void => {
  target.removeEventListener(name, cb);
};

export const addEventListener = (
  name: EventNames,
  cb: AnalyticsEventCallback
): RemoveEventListener => {
  target.addEventListener(name, cb);
  return () => removeEventListener(name, cb);
};
