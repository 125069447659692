/* eslint-disable max-classes-per-file */
/*
These custom Errors use Node's error "code" convention:
https://nodejs.org/docs/latest-v18.x/api/errors.html#errorcode

The max-classes-per-file rule is disabled above because all of these error classes
are related and should be grouped together for convenience. This approach also enables 
automatic unit testing of any new error classes added to this file to ensure conformity.

NOTE: only error classes and type information should be exported from this file
*/

type JSONCompatible =
  | null
  | number
  | string
  | boolean
  | JSONCompatible[]
  | { [key: string]: JSONCompatible };

/**
 * An base type for our error instances to use with type guards
 */
export type EndilError<Metadata extends JSONCompatible> = {
  /**
   * A custom error code used to identify the specific type of error instance.
   */
  readonly code: string;

  /**
   * HTTP status code that should be used when responding with this error.
   */
  readonly httpStatusCode: number;

  /**
   * A simple boolean property used to identify custom "Endil" errors.
   */
  readonly isEndilError: true;

  /**
   * Error instance metadata that is compatible with JSON
   */
  readonly metadata: Metadata | null;
} & Error;

/**
 * This method creates custom Endil Error classes. These classes are intended to be
 * used on both the client and server. No platform specific code should be introduced
 * into this file!
 */
const createEndilError = <
  Metadata extends JSONCompatible,
  CustomErrorCode extends string,
  HTTPStatusCode extends number,
>(
  customErrorCode: CustomErrorCode,
  httpStatusCode: HTTPStatusCode
) =>
  class extends Error implements EndilError<Metadata> {
    constructor(
      message?: string,
      customOptions?: {
        metadata?: Metadata;
      } & ErrorOptions
    ) {
      super(message, customOptions);
      this.metadata = customOptions?.metadata || null;
    }

    /**
     * A static custom error code used to identify the specific type of error instance.
     * This static property is especially useful when used on the client to determine
     * an error type after its been serialized to JSON.
     */
    static readonly code: CustomErrorCode = customErrorCode;

    /**
     * A static HTTP status code that should be used when responding with this error.
     */
    static readonly httpStatusCode: HTTPStatusCode = httpStatusCode;

    readonly code: CustomErrorCode = customErrorCode;

    readonly httpStatusCode: HTTPStatusCode = httpStatusCode;

    readonly isEndilError = true;

    readonly metadata: Metadata | null;
  };

/**
 * EndilSystemError should be used for any errors that originate on
 * the server where a more specific error class does not exist.
 */
export class EndilSystemError extends createEndilError(
  "ENDIL_SYSTEM_ERROR",
  500
) {}

/**
 * EndilValidationError should be used when a client provides invalid data.
 */
export class EndilValidationError extends createEndilError<
  {
    validationErrors: {
      [key: string]: string;
    };
  },
  "ENDIL_VALIDATION_ERROR",
  400
>("ENDIL_VALIDATION_ERROR", 400) {}

/**
 * EndilNotFoundError should be used when a requested resource cannot be found.
 */
export class EndilNotFoundError extends createEndilError<
  { baseURL: string | null; url: string | null },
  "ENDIL_NOT_FOUND_ERROR",
  404
>("ENDIL_NOT_FOUND_ERROR", 404) {}

/**
 * EndilMethodNotAllowedError should be used when a request method is not supported.
 */
export class EndilMethodNotAllowedError extends createEndilError<
  { baseURL: string | null; url: string | null },
  "ENDIL_METHOD_NOT_ALLOWED_ERROR",
  405
>("ENDIL_METHOD_NOT_ALLOWED_ERROR", 405) {}

/**
 * EndilNotAuthorizedError should be used when a client makes a request for a resource they do not have permission to access.
 */
export class EndilNotAuthorizedError extends createEndilError(
  "ENDIL_NOT_AUTHORIZED_ERROR",
  403
) {}

/**
 * EndilCsrfTokenError should be used when a request fails because the CSRF token is not valid
 */
export class EndilCsrfTokenError extends createEndilError(
  "ENDIL_CSRF_TOKEN_ERROR",
  403
) {}

/**
 * EndilNotAuthenticatedError should be used when a client makes a request for a protected resource without having been authenticated.
 */
export class EndilNotAuthenticatedError extends createEndilError(
  "ENDIL_NOT_AUTHENTICATED_ERROR",
  401 // Note: this status code can be confusing. See https://developer.mozilla.org/en-US/docs/Web/HTTP/Status#client_error_responses
) {}
