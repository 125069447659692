import { useCallback } from "react";
import { useGlobalState } from "~/components/GlobalState/GlobalStateProvider";

export const useRegisterAccountModal = () => {
  const { shouldShowRegisterAccountModal, setShouldShowRegisterAccountModal } =
    useGlobalState();
  return { shouldShowRegisterAccountModal, setShouldShowRegisterAccountModal };
};

// This is an incomplete approach to modal management due largely to both Piano
// and Endil components being able to launch modals. If we run into more complex
// multi modal sequencing scenarios we will need to implement something much
// more complex to handle queuing.
export const useGlobalModals = () => {
  const { openModalIds, setOpenModalIds } = useGlobalState();

  const openModal = useCallback(
    (modalId: string) => {
      setOpenModalIds((currentOpenIds) => {
        const newOpenIds = new Set(currentOpenIds);
        newOpenIds.add(modalId);
        return newOpenIds;
      });
    },
    [setOpenModalIds]
  );

  const closeModal = useCallback(
    (modalId: string) => {
      setOpenModalIds((currentOpenIds) => {
        const newOpenIds = new Set(currentOpenIds);
        newOpenIds.delete(modalId);
        return newOpenIds;
      });
    },
    [setOpenModalIds]
  );

  const closeAllModals = useCallback(() => {
    setOpenModalIds(new Set());
  }, [setOpenModalIds]);

  return {
    openModal,
    closeModal,
    closeAllModals,
    isModalOpen: openModalIds.size > 0,
    modalCount: openModalIds.size,
  };
};
