import { cva } from "cva";

// eslint-disable-next-line tailwindcss/no-custom-classname
export const overlayStyles = cva(
  [
    "flyout-overlay fixed inset-0 size-full bg-black/50 transition-opacity duration-default",
  ],
  {
    variants: {
      isVisible: {
        true: ["pointer-events-auto"],
        false: ["pointer-events-none opacity-0"],
      },
    },
  }
);
