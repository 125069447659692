import * as uuid from "uuid";

const MC_SESSION_ID_KEY = "mc_analytics_session_id";
const MC_PREVIOUS_SESSION_ID = "mc_previous_session_id";

export type AdditionalUserTraits = {
  isReturningUser: boolean;
  mcSessionId: string;
  device: {
    screen: { width: number; height: number };
  };
};

export const getAdditionalUserTraits = (): AdditionalUserTraits => {
  let mcSessionId = window.sessionStorage.getItem(MC_SESSION_ID_KEY);
  const previousSessionId = window.localStorage.getItem(MC_PREVIOUS_SESSION_ID);
  let isReturningUser = true;

  if (!mcSessionId) {
    mcSessionId = uuid.v4();
    window.sessionStorage.setItem(MC_SESSION_ID_KEY, mcSessionId);
  }
  if (!previousSessionId || mcSessionId === previousSessionId) {
    isReturningUser = false;
    window.localStorage.setItem(MC_PREVIOUS_SESSION_ID, mcSessionId);
  }
  return {
    isReturningUser,
    mcSessionId,
    device: {
      screen: { width: window.screen.width, height: window.screen.height },
    },
  };
};
