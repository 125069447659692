// istanbul ignore file

/** Explicitly re-export icons as we need */
export {
  ArrowDownTrayIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  BoltIcon,
  CalendarIcon,
  CheckCircleIcon,
  ExclamationTriangleIcon,
  UserIcon,
} from "@heroicons/react/24/solid";

export {
  CheckIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronUpIcon,
  LockClosedIcon,
  LockOpenIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";

export {
  ArrowDownTrayIcon as ArrowDownTrayOutlineIcon,
  ArrowPathIcon,
  ArrowRightCircleIcon,
  ArrowRightOnRectangleIcon,
  ArrowUturnRightIcon,
  Bars3Icon,
  BellIcon,
  BookmarkIcon,
  BuildingOfficeIcon,
  CheckCircleIcon as CheckCircleOutlineIcon,
  Cog6ToothIcon,
  CreditCardIcon,
  DocumentDuplicateIcon,
  FaceSmileIcon,
  InformationCircleIcon as InformationCircleOutlineIcon,
  MagnifyingGlassIcon,
  MinusIcon,
  MinusSmallIcon,
  PencilSquareIcon,
  PlusIcon,
  PlusSmallIcon,
  UserCircleIcon,
  UserGroupIcon,
  UserIcon as OutlineUserIcon,
} from "@heroicons/react/24/outline";

export { AtomIcon } from "./AtomIcon";
export { BeverageIcon } from "./BeverageIcon";
export { ChartPackIcon } from "./ChartPackIcon";
export { CircleOutlineIcon } from "./CircleOutlineIcon";
export { TrendChartIcon } from "./TrendChartIcon";
export { EmailIcon } from "./EmailIcon";
export { EnergyIcon } from "./EnergyIcon";
export { ExitAppIcon } from "./ExitAppIcon";
export { FacebookIcon } from "./FacebookIcon";
export { FinanceIcon } from "./FinanceIcon";
export { GoogleIcon } from "./GoogleIcon";
export { HealthIcon } from "./HealthIcon";
export { HotelIcon } from "./HotelIcon";
export { InstagramIcon } from "./InstagramIcon";
export { GlobeIcon } from "./GlobeIcon";
export { LinkedInIcon } from "./LinkedInIcon";
export { MegaphoneIcon } from "./MegaphoneIcon";
export { MicrosoftIcon } from "./MicrosoftIcon";
export { PeopleIcon } from "./PeopleIcon";
export { PoliticsIcon } from "./PoliticsIcon";
export { RecentIcon } from "./RecentIcon";
export { ShoppingCartIcon } from "./ShoppingCartIcon";
export { StrategyIcon } from "./StrategyIcon";
export { TrainIcon } from "./TrainIcon";
export { TriangleIcon } from "./TriangleIcon";
export { TwitterIcon } from "./TwitterIcon";
export { UpdatingIndicatorIcon } from "./UpdatingIndicatorIcon";
