import { cx } from "cva";
import React from "react";

export interface NavigationAppBarProps<T extends React.ElementType> {
  as?: T;
  children: React.ReactNode;
  className?: string;
}

export function NavigationAppBar<T extends React.ElementType = "header">({
  as,
  children,
  className,
}: NavigationAppBarProps<T>) {
  const Component = as || "header";
  return (
    <Component className={cx("min-h-[64px] bg-white shadow-0", className)}>
      {children}
    </Component>
  );
}
