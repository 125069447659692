import { Menu as BaseMenu } from "@headlessui/react";
import React from "react";
import { buttonStyles } from "~/ui-library/shared-styles/shared-button-styles";
import { ChevronDownIcon, ChevronUpIcon } from "~/ui-library/icons";
import { Body, Icon } from "~/ui-library";
import { cva, cx } from "cva";
import { MenuTransition } from "~/components/Menu";
import { cardStyles } from "~/ui-library/shared-styles/shared-card-styles";

export interface NavigationMenuProps {
  buttonLabel: React.ReactNode;
  /** This should be passed NavigationMenuGroups as children */
  children: React.ReactNode;
  /** Manually open/close the menu
   *
   * By default, the button is uncontrolled, and passing true/false will enable controlled state
   *
   * @example
   * const [isOpen, setIsOpen] = useState(false);
   * return <NavigationMenu isOpen onOpen={setIsOpen} />
   */
  isOpen?: boolean;
  /** onOpen to be used with `isOpen` to control the state. This can also be used when uncontrolled */
  onOpen?: (isOpen: boolean) => void;
}

const navigationMenuButtonStyles = cva(["p-xs hover:bg-gray-100"], {
  variants: {
    isOpen: {
      true: ["bg-gray-100"],
      false: [],
    },
  },
});

/** Menu component that handles both a controlled and uncontrolled state. */
export const NavigationMenu = ({
  buttonLabel,
  children,
  isOpen: controlledIsOpen,
  onOpen,
}: NavigationMenuProps) => (
  <div>
    <BaseMenu>
      {({ open }) => {
        const isOpen = controlledIsOpen ?? open;

        const chevron = (
          <Icon
            SvgIcon={isOpen ? ChevronUpIcon : ChevronDownIcon}
            size="small"
            title=""
          />
        );

        return (
          <div className="relative">
            <BaseMenu.Button
              className={navigationMenuButtonStyles({
                className: buttonStyles({
                  size: "none",
                  variant: "none",
                }),
                isOpen,
              })}
              onClick={() => {
                onOpen?.(!isOpen);
              }}
            >
              <div className="flex items-center gap-[.125rem]">
                <Body bold className="whitespace-nowrap" size="small">
                  {buttonLabel}
                </Body>
                {chevron}
              </div>
            </BaseMenu.Button>
            <MenuTransition isOpen={isOpen}>
              <BaseMenu.Items
                className={cx(
                  cardStyles({ className: "-left-s min-w-[calc(100%+32px)]" }),
                  "absolute p-m duration-fast ease-out"
                )}
              >
                {children}
              </BaseMenu.Items>
            </MenuTransition>
          </div>
        );
      }}
    </BaseMenu>
  </div>
);
