import { MouseEventHandler, ReactNode } from "react";
import NextLink from "next/link";
import { cx } from "cva";

interface FooterLinkProps {
  children: ReactNode;
  className?: string;
  href: string;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export function FooterLink({
  children,
  className,
  href,
  onClick,
}: FooterLinkProps) {
  const isExternalLink = href?.startsWith("http");

  return (
    <NextLink
      href={href}
      className={cx(className, "justify-center text-body-xsmall leading-body")}
      target={isExternalLink ? "_blank" : undefined}
      onClick={onClick}
    >
      {children}
    </NextLink>
  );
}
