import { cx, VariantProps } from "cva";
import { useRouter } from "next/router";
import { useEffect, useRef } from "react";
import { layoutBannerStyles } from "~/components/Layout/shared-layout-styles";
import { useShouldGateContent } from "~/components/Piano/useShouldGateContent";
import { useSubscriptionStatus } from "~/lib/client/useSubscriptionStatus";

type HeaderPaywallProps = JSX.IntrinsicElements["div"] &
  Required<VariantProps<typeof layoutBannerStyles>>;

export const HeaderPaywall: React.FC<HeaderPaywallProps> = ({
  usingNewNavigation,
  ...rest
}) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { events } = useRouter();

  const shouldGateForPaywall = useShouldGateContent();
  const { status } = useSubscriptionStatus();

  useEffect(() => {
    const handleRouteChange = () => {
      ref.current?.classList.add("hidden");
    };
    events.on("routeChangeComplete", handleRouteChange);
    return () => {
      events.on("routeChangeComplete", handleRouteChange);
    };
  }, [events]);

  return (
    <div
      className={cx(
        layoutBannerStyles({ usingNewNavigation }),
        "w-full",
        !shouldGateForPaywall || status !== "not-subscribed" ? "invisible" : ""
      )}
      id="header-paywall-container"
      data-testid="header-paywall-container"
      ref={ref}
      {...rest}
    >
      <div id="piano-header-paywall"></div>
    </div>
  );
};
