import { ScreenSize } from "~/ui-library/design-tokens";

const getCurrentBreakpoint = () => {
  if (window.matchMedia(`(min-width: ${ScreenSize.XLARGE}px)`).matches) {
    return "extra-large";
  }
  if (window.matchMedia(`(min-width: ${ScreenSize.LARGE}px)`).matches) {
    return "large";
  }
  if (window.matchMedia(`(min-width: ${ScreenSize.MEDIUM}px)`).matches) {
    return "medium";
  }
  /* Small breakpoint is min 320px but everything that isn't one of 
  the min-widths above should be small */
  return "small";
};

/**
 * The percentage scroll depth of the page represented as a number. This
 * measurement will never be 0 because it includes the window height in
 * the initial measurement, however it can get all the way to 100.
 *
 * window.innerHeight: interior height of the window measured in number pixels
 * window.scrollY: the number of pixels the window is scrolled from the origin (top of page)
 * window.document.body.offsetHeight: the total height in pixels of the body element
 */
export const getScrollDepth = () => ({
  scrollDepth: Math.round(
    ((window.innerHeight + window.scrollY) /
      window.document.body.offsetHeight) *
      100
  ),
  pageHeight: window.document.body.offsetHeight,
});

export const getDeviceBreakpoint = () => ({
  deviceBreakpoint: getCurrentBreakpoint(),
});
