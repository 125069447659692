import { Button } from "~/ui-library";

export const LayoutUserActionsSkeleton: React.FC = () => (
  <>
    <Button className="pointer-events-none" disabled variant="default">
      {/* Keep buttons same size as unauth state to prevent any layout shift */}
      <span className="bg-transparent text-transparent"> Sign in</span>
    </Button>
    <Button className="pointer-events-none" disabled variant="default">
      {/* Keep buttons same size as unauth state to prevent any layout shift */}
      <span className="bg-transparent text-transparent"> Subscribe</span>
    </Button>
  </>
);
