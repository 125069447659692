import { Session } from "next-auth";
import { MCPermissions } from "~/common/constants";

/**
 * @param allowedPermissions
 * @param permissions The list of user permissions that have been given by the auth server
 * @returns true if the user can access the permission specified
 */
const userHasMCAppAccess = (
  allowedPermissions: string[],
  permissions?: string[]
) => {
  if (!permissions?.length) {
    return false;
  }

  return allowedPermissions.some((permission) =>
    permissions.includes(permission)
  );
};

const mcFieldAccessPermissions = ["application:field"];
export const userHasMcFieldAccess = (permissions?: string[]) =>
  userHasMCAppAccess(mcFieldAccessPermissions, permissions);

const mcIntelAccessPermissions = [
  MCPermissions.AUDIENCE_PROFILE_PERM,
  MCPermissions.BRANDS_PERM,
  MCPermissions.DELIVERABLES_HUB_PERM,
  MCPermissions.ECONOMIC_PERM,
  MCPermissions.POLITICAL_PERM,
];
export const userHasMcIntelAccess = (permissions?: string[]) =>
  userHasMCAppAccess(mcIntelAccessPermissions, permissions);

export const getUserPermissionsForMci = (
  session?: Pick<Session, "user"> | null
) => {
  if (!session) {
    return [];
  }

  return [
    session.user.applications?.audienceProfile
      ? MCPermissions.AUDIENCE_PROFILE_PERM
      : null,
    session.user.applications?.brands ? MCPermissions.BRANDS_PERM : null,
    session.user.applications?.deliverablesHub
      ? MCPermissions.DELIVERABLES_HUB_PERM
      : null,
    session.user.applications?.economic ? MCPermissions.ECONOMIC_PERM : null,
    session.user.applications?.political ? MCPermissions.POLITICAL_PERM : null,
  ].filter(Boolean) as MCPermissions[];
};
