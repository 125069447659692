import { cva } from "cva";
import { buttonStyles } from "~/ui-library/shared-styles/shared-button-styles";

export const navigationMenuButtonStyles = cva(
  [
    buttonStyles({
      size: "none",
      variant: "none",
    }),
    "flex items-center gap-xs",
    "border-none text-black hover:bg-gray-100",
    "px-xs py-xxs",
    "cursor-pointer",
    "w-fit",
  ],
  {
    variants: {
      active: {
        true: ["bg-gray-100"],
        false: [],
      },
      bold: {
        true: ["font-semibold"],
        false: [],
      },
    },
  }
);

export const outerListStyles = "flex flex-col gap-s list-none";

export const layoutBannerStyles = cva("sticky z-30", {
  variants: {
    usingNewNavigation: {
      true: "top-[64px] lg:top-0",
      false: "top-[64px]",
    },
  },
});
