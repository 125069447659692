export const BeverageIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7650_93375)">
      <path
        d="M0.200195 12C0.200308 13.1221 0.604723 14.2066 1.33935 15.0548C2.07397 15.9029 3.08961 16.458 4.2002 16.6183V22.6667H2.2002V24H7.53353V22.6667H5.53353V16.6183C6.64411 16.458 7.65975 15.9029 8.39438 15.0548C9.129 14.2066 9.53342 13.1221 9.53353 12V6.66667H0.200195V12ZM1.53353 8H8.2002V12C8.2002 12.8841 7.84901 13.7319 7.22388 14.357C6.59876 14.9821 5.75092 15.3333 4.86686 15.3333C3.98281 15.3333 3.13496 14.9821 2.50984 14.357C1.88472 13.7319 1.53353 12.8841 1.53353 12V8ZM20.8669 7.16667V0H16.2002V7.16667C15.2073 7.61711 14.365 8.3438 13.774 9.25996C13.1829 10.1761 12.868 11.243 12.8669 12.3333V24H24.2002V12.3333C24.1991 11.243 23.8842 10.1761 23.2931 9.25996C22.702 8.3438 21.8597 7.61711 20.8669 7.16667ZM17.0885 8.245L17.5335 8.08833V1.33333H19.5335V8.09L19.9785 8.24667C20.8227 8.54679 21.5533 9.1006 22.0704 9.83221C22.5875 10.5638 22.8657 11.4374 22.8669 12.3333V12.6667H14.2002V12.3333C14.2014 11.4374 14.4796 10.5638 14.9966 9.83221C15.5137 9.1006 16.2444 8.54679 17.0885 8.24667V8.245ZM22.8669 14V18.6667H14.2002V14H22.8669ZM14.2002 22.6667V20H22.8669V22.6667H14.2002Z"
        fill="#4657CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_7650_93375">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.200195)"
        />
      </clipPath>
    </defs>
  </svg>
);
