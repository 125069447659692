import {
  ArrowRightEndOnRectangleIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import {
  UserMenu,
  UserMenuGroup,
  UserMenuItem,
} from "@mci/cross-platform-components";
import { useSession } from "next-auth/react";
import Link from "next/link";
import { useSideNavigationAnalytics } from "~/components/Layout/LayoutSideNavigation/useSideNavigationAnalytics";

export const SideNavigationUserMenu = ({
  isOpen,
  setIsOpen,
}: {
  setIsOpen: (value: boolean) => void;
  isOpen: boolean;
}) => {
  const trackNavItemClicked = useSideNavigationAnalytics();
  const { data: session, status } = useSession();
  const userFullName = `${session?.user.firstName} ${session?.user.lastName}`;

  // istanbul ignore if
  if (status !== "authenticated") {
    return null;
  }

  return (
    <UserMenu isOpen={isOpen} setIsOpen={setIsOpen} userName={userFullName}>
      <UserMenuGroup>
        <UserMenuItem
          primaryText={userFullName}
          secondaryText={session?.user.email}
        />
      </UserMenuGroup>

      <UserMenuGroup>
        <UserMenuItem
          as={Link}
          href="/account"
          onClick={() => {
            trackNavItemClicked("Account Settings");
          }}
          primaryText="Account Settings"
          StartAdornmentIcon={Cog6ToothIcon}
          isClickable
        />
      </UserMenuGroup>

      <UserMenuGroup>
        <UserMenuItem
          as={Link}
          href="/logout"
          onClick={() => {
            trackNavItemClicked("Sign out");
          }}
          primaryText="Sign out"
          StartAdornmentIcon={ArrowRightEndOnRectangleIcon}
          isClickable
        />
      </UserMenuGroup>
    </UserMenu>
  );
};
