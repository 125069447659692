export const FinanceIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_7650_93369)">
      <path
        d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9965 8.81849 22.7311 5.76829 20.4814 3.51862C18.2317 1.26894 15.1815 0.00352914 12 0ZM12 22.6667C9.89034 22.6667 7.82805 22.0411 6.07392 20.869C4.3198 19.6969 2.95263 18.031 2.14529 16.082C1.33796 14.1329 1.12672 11.9882 1.5383 9.91904C1.94987 7.84991 2.96577 5.94929 4.45753 4.45753C5.94929 2.96577 7.84991 1.94987 9.91904 1.53829C11.9882 1.12671 14.1329 1.33795 16.082 2.14529C18.031 2.95262 19.6969 4.31979 20.869 6.07392C22.0411 7.82804 22.6667 9.89033 22.6667 12C22.6636 14.828 21.5388 17.5393 19.5391 19.5391C17.5393 21.5388 14.828 22.6636 12 22.6667ZM16 14C16 14.7072 15.7191 15.3855 15.219 15.8856C14.7189 16.3857 14.0406 16.6667 13.3333 16.6667H12.6667V18.6667H11.3333V16.6667H9.00001V15.3333H13.3333C13.687 15.3333 14.0261 15.1929 14.2761 14.9428C14.5262 14.6928 14.6667 14.3536 14.6667 14C14.6667 13.6464 14.5262 13.3072 14.2761 13.0572C14.0261 12.8071 13.687 12.6667 13.3333 12.6667H10.6667C9.95943 12.6667 9.28115 12.3857 8.78105 11.8856C8.28096 11.3855 8.00001 10.7072 8.00001 10C8.00001 9.29276 8.28096 8.61448 8.78105 8.11438C9.28115 7.61428 9.95943 7.33333 10.6667 7.33333H11.3333V5.33333H12.6667V7.33333H15V8.66667H10.6667C10.3131 8.66667 9.97391 8.80714 9.72386 9.05719C9.47381 9.30724 9.33334 9.64638 9.33334 10C9.33334 10.3536 9.47381 10.6928 9.72386 10.9428C9.97391 11.1929 10.3131 11.3333 10.6667 11.3333H13.3333C14.0406 11.3333 14.7189 11.6143 15.219 12.1144C15.7191 12.6145 16 13.2928 16 14Z"
        fill="#4657CE"
      />
    </g>
    <defs>
      <clipPath id="clip0_7650_93369">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
