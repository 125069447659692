import { useRouter } from "next/router";
import { useCallback } from "react";

/**
 * Creates a new, small window at a specific URL. The
 * window should be centered on the user's screen.
 */
const popupNewWindow = (url: string, title: string) => {
  const dualScreenLeft = window.screenLeft;
  const dualScreenTop = window.screenTop;
  const width = window.innerWidth;
  const height = window.innerHeight;

  const systemZoom = width / window.screen.availWidth;

  const left = (width - 500) / 2 / systemZoom + dualScreenLeft;
  const top = (height - 550) / 2 / systemZoom + dualScreenTop;

  const newWindow = window.open(
    url,
    title,
    `width=${500 / systemZoom},height=${
      550 / systemZoom
    },top=${top},left=${left}`
  );

  if (newWindow) {
    // pull user's focus to new window -
    // important for accessibility and general UX
    newWindow.focus();
  }
};

/**
 * What behavior to use when authenticating the user.
 *
 * The default behavior is to open a new window with the
 * Okta authentication flow.
 *
 * "redirect" behavior will redirect the current window
 * to Okta's login and back to the current page after
 * successful authentication.
 */
export type LoginBehavior =
  | {
      type: "window";
      popupTitle?: string;
    }
  | {
      type: "redirect";
      /**
       * By default, the redirect authentication flow will redirect back to the
       * current path after successful authentication.
       *
       * Use this prop to override the default behavior by supplying
       * a path to redirect to after successful authentication.
       */
      redirectOverride?: string;
    };

export const useLoginBehavior = () => {
  const router = useRouter();
  return useCallback(
    (
      behavior: LoginBehavior = {
        type: "window",
      }
    ) => {
      if (behavior.type === "redirect") {
        router.push(
          `/login?redirect=${behavior.redirectOverride || router.asPath}`
        );
      } else {
        popupNewWindow(
          `/login?source=${router.asPath}`,
          behavior.popupTitle || "Sign In"
        );
      }
    },
    [router]
  );
};
