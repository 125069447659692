import { parseFragment } from "parse5";
import {
  ChildNode,
  Element,
  TextNode,
} from "parse5/dist/tree-adapters/default";
import { ReactNode } from "react";

import {
  TagToClassNameMap,
  defaultTagToReactConverters,
  TagToReactConverters,
  TagConverterOptions,
} from "./tag-to-react-converters";

interface NodeWithChildren {
  childNodes: ChildNode[];
}

const hasChildren = (node: any): node is NodeWithChildren =>
  "childNodes" in node;

const isTextNode = (node: ChildNode): node is TextNode =>
  node.nodeName === "#text";

const isElement = (node: ChildNode): node is Element => "tagName" in node;

const mergeAdjacentTextNodes = (childrenResult: ReactNode[]) => {
  /* The array has to be flattened here because some converters unwrap their 
  children in tagToReactConverters creating a nested array */
  const flattenedChildren = childrenResult.flat();
  return flattenedChildren.reduce(
    (mergedResult: ReactNode[], currentNode: ReactNode, index: number) => {
      const previousNode = mergedResult[index - 1];
      if (typeof currentNode === "string" && typeof previousNode === "string") {
        // disabled for performance reasons
        // eslint-disable-next-line no-param-reassign
        mergedResult[index - 1] = previousNode + currentNode;
      } else {
        mergedResult.push(currentNode);
      }
      return mergedResult;
    },
    []
  );
};

// Needed for closure over TagToClassNameMap
const getNodeReducer = (
  tagToClassNameMap: TagToClassNameMap,
  tagToReactConverters: TagToReactConverters,
  options: TagConverterOptions
) => {
  let key = 0;
  /* This is recursive so it risks a stack overflow. However it's extremely unlikely
  that we will have thousands of nested dom nodes and this is more readable than
  traversing the tree via a loop/stack. */
  const nodeReducer = (childrenResult: ReactNode[], node: ChildNode) => {
    const children = hasChildren(node)
      ? node.childNodes.reduce(nodeReducer, [])
      : [];

    if (isTextNode(node)) {
      childrenResult.push(node.value);
      return childrenResult;
    }
    if (!isElement(node)) {
      return childrenResult;
    }
    const converter = tagToReactConverters.get(
      // "as" is being used to force compatibility between a parse5 Element tagName (string) and keyof JSX.IntrinsicElements
      node.tagName as keyof JSX.IntrinsicElements
    );
    if (!converter) {
      return childrenResult;
    }
    // Simple incrementing key ensures no duplicates even when nested tags are unwrapped
    key += 1;
    const convertResult = converter(
      node,
      children,
      tagToClassNameMap,
      key,
      options
    );
    if (convertResult) {
      childrenResult.push(convertResult);
    }
    return mergeAdjacentTextNodes(childrenResult);
  };

  return nodeReducer;
};

/**
 * convertHTMLToComponents converts a string of HTML into a React component
 * tree. convertHTMLToComponents does not provide robust XSS protection!
 * The expectation is that we are properly sanitizing user generated
 * content on input and using Content-Security-Policy headers to mitigate
 * XSS. That said, it's best to have layers of defense so there is limited
 * protection provided by creating a React component tree rather than using
 * dangerouslySetInnerHTML.
 */
export const convertHTMLToComponents = (
  html: string,
  tagToClassNameMap: TagToClassNameMap = new Map(),
  tagToReactConverters: TagToReactConverters = defaultTagToReactConverters,
  options: TagConverterOptions = {}
): ReactNode | ReactNode[] => {
  const result = parseFragment(html);
  const children = mergeAdjacentTextNodes(
    result.childNodes.reduce(
      getNodeReducer(tagToClassNameMap, tagToReactConverters, options),
      []
    )
  );

  if (children.length === 0) {
    return null;
  }

  if (children.length === 1) {
    return children[0];
  }

  return children;
};
