import { cva, cx, VariantProps } from "cva";
import { useRef } from "react";
import { LayoutMobileNavigation } from "~/components/Layout/LayoutMobileNavigation/LayoutMobileNavigation";
import { LayoutSideNavigation } from "~/components/Layout/LayoutSideNavigation/LayoutSideNavigation";
import { FooterPaywall } from "~/components/Piano/FooterPaywall";
import { HeaderBanner } from "~/components/Piano/HeaderBanner";
import { HeaderPaywall } from "~/components/Piano/HeaderPaywall";
import { RegistrationReminderBanner } from "~/components/RegistrationReminderBanner";
import { useFeatureFlags } from "~/lib/client/useFeatureFlags";
import { LayoutAppBar } from "./LayoutAppBar/LayoutAppBar";
import { LayoutFooter } from "./LayoutFooter/LayoutFooter";

const contentContainerStyle = cva([], {
  defaultVariants: {
    parentLayout: "flexGrow",
  },
  variants: {
    parentLayout: {
      contents: "contents",
      flexGrow: "flex-grow",
    },
  },
});

export type LayoutProps = {
  children: React.ReactNode;
  hideFooter?: boolean;
  hideTopNavigation?: boolean;
} & VariantProps<typeof contentContainerStyle>;

export function Layout({
  hideFooter,
  hideTopNavigation,
  children,
  parentLayout,
}: LayoutProps) {
  const ref = useRef<HTMLDivElement | null>(null);
  const { enableNewNavigation } = useFeatureFlags();
  return (
    <div
      className={cx(
        "flex min-h-screen",
        enableNewNavigation ? "flex-col lg:flex-row " : "flex-col"
      )}
      data-testid={enableNewNavigation ? "side-nav" : "top-nav"}
    >
      {enableNewNavigation && (
        <LayoutSideNavigation className="relative z-10 hidden lg:block " />
      )}
      {enableNewNavigation && <LayoutMobileNavigation />}
      {!enableNewNavigation && !hideTopNavigation && (
        <LayoutAppBar as="header" />
      )}
      <div className="flex min-h-screen w-full flex-col">
        <HeaderBanner usingNewNavigation={enableNewNavigation} />
        <HeaderPaywall usingNewNavigation={enableNewNavigation} />
        <RegistrationReminderBanner />
        <div className="flex h-full flex-1 flex-col" ref={ref}>
          <main
            className={contentContainerStyle({
              parentLayout,
            })}
          >
            {children}
          </main>
          {!hideFooter && (
            <>
              <FooterPaywall />
              <LayoutFooter as="footer" />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
