import { useEffect, useState } from "react";
import { useSession } from "next-auth/react";
import { PianoSDKStatus, usePiano } from "~/components/Piano/PianoContext";
import { useSyncPermissions } from "~/components/Piano/useSyncPermissions";
import { CommonIntervals } from "~/common/constants";

/**
 * This Component serves as a failsafe against incorrect permissions
 * that could occur if Piano fails to call Kairuku when a subscription
 * ends. This may also help in scenarios where users redeem a seat on a
 * multi seat subscription.
 */
export const PermissionsSync: React.FC = () => {
  const [lastSyncCheckTime, setLastSyncCheckTime] = useState<null | number>(
    null
  );
  const { data: sessionData, status: sessionStatus } = useSession();
  const ctx = usePiano();
  const syncPermissions = useSyncPermissions();

  const { pianoStatus, hasExperienceExecuted } = ctx;
  const hasActivePianoSubscription = ctx.piano
    ? Boolean(ctx.activeSubscriptionName)
    : false;
  const hasMCSubscriptionPermission = Boolean(
    sessionData?.user.isActiveSubscriber
  );
  const isMCTeamSubscriber = Boolean(sessionData?.user.isTeamSubscriber);
  const syncCheckRecencyAllowed =
    lastSyncCheckTime === null ||
    Date.now() > lastSyncCheckTime + CommonIntervals.ONE_DAY_IN_MS;

  useEffect(() => {
    if (
      pianoStatus === PianoSDKStatus.Ready &&
      hasExperienceExecuted &&
      sessionStatus === "authenticated" &&
      // if user has team level subscription, we don't need to sync because they were added outside of Piano
      !isMCTeamSubscriber &&
      syncCheckRecencyAllowed
    ) {
      setLastSyncCheckTime(Date.now());
      if (hasMCSubscriptionPermission !== hasActivePianoSubscription) {
        syncPermissions(hasActivePianoSubscription, 1);
      }
    }
  }, [
    syncCheckRecencyAllowed,
    hasActivePianoSubscription,
    hasExperienceExecuted,
    hasMCSubscriptionPermission,
    isMCTeamSubscriber,
    lastSyncCheckTime,
    pianoStatus,
    sessionStatus,
    setLastSyncCheckTime,
    syncPermissions,
  ]);

  useEffect(() => {
    if (sessionStatus === "unauthenticated") {
      setLastSyncCheckTime(null);
    }
  }, [sessionStatus, setLastSyncCheckTime]);

  return null;
};
