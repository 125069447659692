import { Plugin, Context } from "@segment/analytics-next";
import { z } from "zod";

const emailSchema = z.string().email();

export const getQueryStringWithoutPii = (queryString?: string) => {
  const params = new URLSearchParams(queryString);
  return Array.from(params.entries()).reduce((acc, [key, value], idx) => {
    const prefix = idx === 0 ? "?" : "&";
    if (emailSchema.safeParse(value).success) {
      const domain = value.split("@").at(1);
      return `${acc}${prefix}${key}=___redacted___@${domain}`;
    }
    return `${acc}${prefix}${key}=${value}`;
  }, "");
};

const defaultSanitize = (ctx: Context) => {
  if (ctx.event.properties?.search) {
    ctx.event.properties.search = getQueryStringWithoutPii(
      ctx.event.properties.search
    );
  }
  return ctx;
};

/** segment analytics plugin that will remove pii from query string search params  */
export const analyticsPiiSanitizePlugin: Plugin = {
  name: "Remove pii from query string",
  version: "1.0.0",
  type: "enrichment",

  isLoaded: () => true,
  load: async () => {},
  page: (ctx: Context) => {
    if (ctx.event.context?.page) {
      ctx.event.context.page.search = getQueryStringWithoutPii(
        ctx.event.context.page.search
      );
    }
    return defaultSanitize(ctx);
  },
  alias: defaultSanitize,
  track: defaultSanitize,
  identify: defaultSanitize,
  group: defaultSanitize,
};
