import { Dialog } from "@headlessui/react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { Modal } from "~/components/Modal";
import { useFirstLoginModal } from "~/components/PersistentState";
import { RegistrationPanel } from "~/components/RegistrationCommon/RegistrationPanel";
import { Body, Button, Card } from "~/ui-library";

// Note: pathnames are the name of the path matching in our pages directory
// "/verticals/[verticalsParam]" is an example of pathname matching verticals page
const excludedPathnames = new Set([
  "/403",
  "/404",
  "/500",
  "/complete-logout",
  "/login",
  "/logout",
  "/subscription-checkout",
]);

export const FirstLoginModal: React.FC = () => {
  const { showFirstLoginModal, setShowFirstLoginModal } = useFirstLoginModal();
  const { pathname } = useRouter();
  const { status } = useSession();

  const isShowingDialog =
    showFirstLoginModal &&
    !excludedPathnames.has(pathname) &&
    status === "authenticated";

  return (
    <Modal
      isOpen={isShowingDialog}
      className="flex flex-col items-center text-center"
    >
      <Card className="flex w-[440px] flex-col items-center" elevation={2}>
        <RegistrationPanel.Header />
        <RegistrationPanel.Divider />
        <Dialog.Title as="div" className="text-gray-900">
          <RegistrationPanel.Title as="h6" level={6}>
            Your account has been created!
          </RegistrationPanel.Title>
        </Dialog.Title>
        <Body className="px-l" size="small">
          You were signed in successfully. Welcome to Morning Consult.
        </Body>

        <Button
          className="mb-l mt-xl w-fit px-xl py-xs"
          onClick={() => setShowFirstLoginModal(false)}
          size="none"
          variant="primary"
        >
          Continue
        </Button>
      </Card>
    </Modal>
  );
};
