import { cva } from "cva";

export const sharedChipStyles = cva(
  [
    "rounded-3",
    "font-semibold",
    "outline-none",
    "focus-visible:shadow-chip",
    "transition-colors",
    "duration-default",
  ],
  {
    defaultVariants: {
      appearance: "default",
      size: "default",
    },
    variants: {
      appearance: {
        default: ["border-1 border-primary-200 bg-primary-100"],
        warning: ["border-1 border-solid border-warning-200 bg-warning-100"],
        danger: ["border-1 border-solid border-danger-200 bg-danger-100"],
        success: ["border-1 border-solid border-success-200 bg-success-100"],
        static: ["border-gray-200 bg-gray-200"],
      },
      size: {
        default: ["px-xs py-xxs text-body-small"],
        small: ["px-xs py-0 text-body-xsmall lg:py-xxs lg:text-body-small"],
        none: [],
      },
    },
  }
);
