import { VariantProps, cva } from "cva";
import { Headline, HeadlineProps, Logo } from "~/ui-library";
import { Chevron } from "~/ui-library/logos";

export type RegistrationPanelProps = {
  children: React.ReactNode;
  containerClassName?: string;
};

const dotStyles = cva("size-[8px] rounded-[50%]", {
  variants: {
    appearance: {
      filled: "bg-primary",
      outlined: "border-[1.5px] border-primary",
      "filled-gray": "bg-gray-300",
    },
  },
});

type RegistrationDotProps = Required<VariantProps<typeof dotStyles>>;

const ProgressDot: React.FC<RegistrationDotProps> = ({ appearance }) => (
  <span className={dotStyles({ appearance })} />
);

type RegistrationPanelComponents = {
  Header: () => JSX.Element;
  Divider: () => JSX.Element;
  Title: (
    props: Partial<Pick<HeadlineProps, "as" | "children" | "level">>
  ) => JSX.Element;
  ProgressDot: typeof ProgressDot;
  ProgressDots: (props: React.PropsWithChildren) => JSX.Element;
};

export const RegistrationPanel: RegistrationPanelComponents = {
  Header: () => (
    <div className="flex items-center justify-center py-m">
      <div className="w-s">
        <Logo LogoComponent={Chevron} />
      </div>
    </div>
  ),
  Divider: () => <div className="h-[1px] w-full border-b-1 border-gray-300" />,
  Title: ({ as = "h5", children, level = 5 }) => (
    <Headline as={as} className="mb-s pt-s text-center" level={level}>
      {children}
    </Headline>
  ),
  ProgressDots: ({ children }) => (
    <div className="flex justify-center gap-xxs pb-m pt-xl">{children}</div>
  ),
  ProgressDot,
};
