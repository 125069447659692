import {
  LoginWrapper,
  LoginWrapperProps,
} from "~/components/LoginButton/LoginWrapper";
import { Button } from "~/ui-library";

export type LoginButtonProps = LoginWrapperProps<typeof Button>;

export const LoginButton: React.FC<LoginButtonProps> = (props): JSX.Element => (
  <LoginWrapper as={Button} {...props} />
);
