import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { CommonIntervals } from "~/common/constants";
import { useAnalytics } from "~/components/Analytics";

export const useStickyPromotionFlyout = ({
  slug,
  storageKey,
  analyticsShowMessage,
  analyticsDismissMessage,
}: {
  slug?: string;
  storageKey: string;
  analyticsShowMessage: string;
  analyticsDismissMessage: string;
}) => {
  const [isShowingFlyout, setIsShowingFlyout] = useState(false);
  const router = useRouter();
  const { status, data: session } = useSession();
  const { analyticsEnhancedTrack } = useAnalytics();
  const init = useRef(false);

  useEffect(() => {
    const storageValue = window.localStorage.getItem(storageKey);

    if (!slug) {
      return;
    }

    if (
      !init.current &&
      (!storageValue || parseInt(storageValue, 10) < Date.now())
    ) {
      setIsShowingFlyout(true);
      analyticsEnhancedTrack(analyticsShowMessage, {
        slug,
      });
      init.current = true;
    }
  }, [
    status,
    router.asPath,
    analyticsEnhancedTrack,
    storageKey,
    analyticsShowMessage,
    slug,
  ]);

  const closeFlyout = () => {
    const newDate = Date.now() + CommonIntervals.ONE_DAY_IN_MS;

    window.localStorage.setItem(storageKey, newDate.toString());
    setIsShowingFlyout(false);
    analyticsEnhancedTrack(analyticsDismissMessage, {
      slug,
    });
  };

  return {
    isShowingFlyout,
    closeFlyout,
    status,
    session,
  };
};
