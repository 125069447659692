import useSWR from "swr";
import { fetchUserSubscriptions } from "~/lib/client/endilApiCalls";
import { SubscriptionResponse } from "~/pages/api/piano/user-subscriptions-helpers";

/**
 * Loads the list of current and historical subscriptions for the active user.
 * If there is no user on the session, do not make a request
 */
export const useUserSubscriptions = ({
  enabled,
  userId,
}: {
  enabled: boolean;
  userId: string | undefined;
}) => {
  const key = ["load-user-subscriptions", userId];
  return useSWR(
    // setting the key to null will prevent swr from sending the request
    enabled ? key : null,
    /* istanbul ignore next, this is a callback invoked by swr when key is not null */
    () => fetchUserSubscriptions().then(({ data }) => data),
    {
      fallbackData: {
        activeProSubscription: null,
        activeSubscriptions: [],
        inactiveSubscriptions: [],
        proTrialEndDate: null,
        proSubscriptionEndDate: null,
        subscriptionCount: 0,
      } satisfies SubscriptionResponse,
    }
  );
};
