import { signIn } from "next-auth/react";
import { IdentityProviderNames, constants } from "~/common/constants";
import { Divider } from "~/components/Divider";
import { commonConfig } from "~/config/common-config";
import { createLoginRedirectCallbackUrl } from "~/lib/client/signUpUtils";
import { useFeatureFlags } from "~/lib/client/useFeatureFlags";
import { Body, Button, Headline, Icon, Link } from "~/ui-library";
import { ButtonLink } from "~/ui-library/components/ButtonLink/ButtonLink";
import { GoogleIcon, LinkedInIcon, MicrosoftIcon } from "~/ui-library/icons";

export type RegisterAccountSelectIDPProps = {
  onEmailSignUp?: () => void;
  postLoginRedirect: string;
};

const signInWithIDP = ({
  idp,
  idpName,
  postLoginRedirect,
}: {
  idp: string;
  idpName: IdentityProviderNames;
  postLoginRedirect: string;
}) => {
  window.localStorage.setItem(
    constants.REGISTRATION_IDP_NAME_STORAGE_KEY,
    idpName
  );
  signIn(
    commonConfig.NEXT_PUBLIC_ENDIL_IDENTITY_PROVIDER,
    {
      callbackUrl: createLoginRedirectCallbackUrl(postLoginRedirect),
    },
    {
      idp,
    }
  );
};

const makeIdentityProviderPropsList = (enableNewIdentityProviders: boolean) => [
  {
    idp: commonConfig.NEXT_PUBLIC_GOOGLE_IDP,
    idpName: IdentityProviderNames.GOOGLE,
    icon: GoogleIcon,
    text: "Continue with Google",
  },
  ...(enableNewIdentityProviders
    ? [
        {
          idp: commonConfig.NEXT_PUBLIC_LINKEDIN_IDP,
          idpName: IdentityProviderNames.LINKEDIN,
          icon: () => <LinkedInIcon color="#0077B5" />,
          text: "Continue with LinkedIn",
        },
        {
          idp: commonConfig.NEXT_PUBLIC_MICROSOFT_IDP,
          idpName: IdentityProviderNames.MICROSOFT,
          icon: MicrosoftIcon,
          text: "Continue with Microsoft",
        },
      ]
    : []),
];

export const RegisterAccountSelectIDP: React.FC<
  RegisterAccountSelectIDPProps
> = ({ onEmailSignUp, postLoginRedirect }) => {
  const { enable_new_identity_providers: enableNewIdentityProviders } =
    useFeatureFlags();
  const identityProviderPropsList = makeIdentityProviderPropsList(
    enableNewIdentityProviders
  );

  const emailSignUpHandler = () => {
    window.localStorage.removeItem(constants.REGISTRATION_IDP_NAME_STORAGE_KEY);
    if (onEmailSignUp) {
      onEmailSignUp();
    }
  };

  return (
    <>
      <div className="flex flex-col gap-s">
        {identityProviderPropsList.map(({ icon, idpName, idp, text }) => (
          <ButtonLink
            className="gap-xs xs:text-body-small md:text-body-regular"
            fullWidth
            href="#"
            key={idpName}
            onClick={(e) => {
              e.preventDefault();
              signInWithIDP({
                idp,
                idpName,
                postLoginRedirect,
              });
            }}
            variant="outlineBlack"
          >
            <div className="mb-[2px]">
              <Icon SvgIcon={icon} size="extraSmall" />
            </div>
            <Body bold>{text}</Body>
          </ButtonLink>
        ))}
      </div>

      <Divider containerClassName="mt-m" />
      <div className="relative -top-[1px] mb-m flex h-0 justify-center">
        <Headline
          blockCase
          className="relative -top-[0.5em] h-fit bg-white px-m"
          level={7}
        >
          or
        </Headline>
      </div>

      <Button
        className="mb-m whitespace-nowrap xs:text-body-small md:text-body-regular"
        fullWidth
        onClick={emailSignUpHandler}
        variant="outlineBlack"
      >
        Continue with email
      </Button>
      <Body size="small">
        By continuing, you consent to Morning Consult processing your name and
        email address, pursuant to our{" "}
        <Link href="https://morningconsult.com/privacy-policy/">
          Privacy Policy
        </Link>
        , so that we may enable access to our products and send you emails about
        our content. You further confirm that you have read, and understand and
        agree to, our{" "}
        <Link href="https://morningconsult.com/privacy-policy/">
          Privacy Policy
        </Link>{" "}
        and the{" "}
        <Link href="/terms-and-conditions-of-use">
          Morning Consult Pro Terms and Conditions of Use
        </Link>
        .
      </Body>
    </>
  );
};
