import NextLink from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useAnalytics } from "~/components/Analytics";
import { outerListStyles } from "~/components/Layout/shared-layout-styles";
import {
  NavigationGroupItem,
  NavigationItem,
  NavigationResponse,
} from "~/types/asgard/MenuResponse";
import { Button } from "~/ui-library";
import { LayoutMobileGroupButton } from "../LayoutMobileGroupButton/LayoutMobileGroupButton";
import {
  LayoutMobileUserToolbar,
  LayoutMobileUserToolbarProps,
} from "../LayoutMobileUserToolbar/LayoutMobileUserToolbar";

export type LayoutMobileNavigationListProps = {
  hasMciAccess?: boolean;
  menuData: NavigationResponse;
  status: LayoutMobileUserToolbarProps["status"];
  tabIndex: number;
};

const isNavigationGroupItem = (item: any): item is NavigationGroupItem =>
  Boolean(item.child_items);

const ListItem = ({
  item,
  onClick,
  tabIndex,
}: {
  item: NavigationItem;
  onClick: () => void;
  tabIndex: number;
}): JSX.Element => (
  <li key={item.link}>
    <NextLink
      className="w-fit pl-xs font-semibold hover:underline"
      href={item.link}
      tabIndex={tabIndex}
      onClick={onClick}
    >
      {item.label}
    </NextLink>
  </li>
);

/** Render a button for a given navigation item with child items on it */
const GroupedItem: React.FC<{
  item: NavigationGroupItem;
  onListItemClick: (title: string) => void;
  tabIndex: number;
}> = ({ item, onListItemClick, tabIndex }) => (
  <LayoutMobileGroupButton label={item.label}>
    <ul className="flex flex-col gap-m px-m pt-s">
      {item.child_items
        .filter((childItem) => !isNavigationGroupItem(childItem))
        .flatMap((link, linkIndex) => (
          <ListItem
            key={`${link.label}-${linkIndex}`}
            item={link as NavigationItem}
            tabIndex={tabIndex}
            onClick={() => onListItemClick(link.label)}
          />
        ))}
    </ul>
  </LayoutMobileGroupButton>
);

const NavigationListItems: React.FC<{
  items: NavigationResponse;
  onListItemClick: (title: string) => void;
  tabIndex: number;
}> = ({ items, onListItemClick, tabIndex }) => (
  <>
    {items.map((item, menuIndex) => {
      if (isNavigationGroupItem(item)) {
        return (
          <li key={menuIndex}>
            <GroupedItem
              item={item}
              tabIndex={tabIndex}
              onListItemClick={onListItemClick}
            />
          </li>
        );
      }
      return (
        <li key={item.link}>
          <NextLink
            className="w-fit pl-xs font-semibold hover:underline"
            href={item.link}
            tabIndex={tabIndex}
            onClick={() => onListItemClick(item.label)}
          >
            {item.label}
          </NextLink>
        </li>
      );
    })}
  </>
);

const allowedSignOutStates: LayoutMobileUserToolbarProps["status"][] = [
  "authenticated",
  "subscribed",
];

const showSignButton = (status: LayoutMobileUserToolbarProps["status"]) =>
  allowedSignOutStates.includes(status);

/** Component to render navigation in a list, along with user actions at the bottom. */
export const LayoutMobileNavigationList: React.FC<
  LayoutMobileNavigationListProps
> = ({ hasMciAccess, menuData, status, tabIndex }) => {
  const router = useRouter();
  const { analyticsEnhancedTrack } = useAnalytics();

  const onMenuItemClick = (title: string) => {
    analyticsEnhancedTrack("Nav Item Clicked", {
      title,
    });
  };

  return (
    <div className="flex size-full flex-col justify-between py-s">
      <div>
        <div className="px-s">
          <ul className={outerListStyles}>
            <NavigationListItems
              tabIndex={tabIndex}
              items={menuData}
              onListItemClick={onMenuItemClick}
            />
          </ul>
        </div>

        <div className="my-m px-s">
          <div className="h-[1px] w-full border-t-1 border-gray-300" />
        </div>

        <div className="flex flex-col justify-between px-s">
          <LayoutMobileUserToolbar
            hasMciAccess={hasMciAccess}
            status={status}
          />
        </div>
      </div>
      {showSignButton(status) ? (
        <div className="mt-xl px-s pb-s">
          <Button
            fullWidth
            onClick={() => router.push("/logout")}
            variant="subtle"
          >
            Sign out
          </Button>
        </div>
      ) : null}
    </div>
  );
};
