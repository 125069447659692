export const TwitterIcon = () => (
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.24102 13.081C10.891 13.081 13.991 8.38096 13.991 4.33096V3.93096C14.591 3.48096 15.091 2.98096 15.491 2.33096C14.891 2.53096 14.341 2.73096 13.691 2.83096C14.341 2.43096 14.791 1.83096 15.091 1.18096C14.441 1.53096 13.791 1.83096 13.091 1.98096C12.491 1.33096 11.641 0.980957 10.791 0.980957C9.09102 0.980957 7.74102 2.38096 7.74102 4.03096C7.74102 4.23096 7.74102 4.48096 7.84102 4.68096C5.34102 4.58096 3.04102 3.43096 1.49102 1.53096C1.24102 2.03096 1.09102 2.58096 1.09102 3.13096C1.09102 4.23096 1.59102 5.13096 2.49102 5.73096C1.99102 5.68096 1.54102 5.53096 1.09102 5.33096C1.09102 6.78096 2.14102 8.03096 3.54102 8.28096C3.29102 8.38096 2.99102 8.43096 2.74102 8.38096C2.49102 8.38096 2.34102 8.38096 2.14102 8.28096C2.54102 9.53096 3.69102 10.331 5.04102 10.331C3.94102 11.131 2.64102 11.581 1.24102 11.631H0.541016C1.94102 12.631 3.59102 13.081 5.24102 13.081Z"
      fill="black"
    />
  </svg>
);
