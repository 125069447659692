import { featureFlagSchema } from "~/lib/edge/feature-flags/schema";
import { delimiter, equality } from "~/lib/edge/feature-flags/settings";

export const parseFeatureFlagsCookie = (cookie: string) => {
  const rawResult = cookie
    .split(delimiter)
    .map((kv) => decodeURIComponent(kv).split(equality))
    .reduce(
      (acc, [key, value]) => {
        acc[key] = value;
        return acc;
      },
      {} as Record<string, string>
    );

  return featureFlagSchema.parse(rawResult);
};
