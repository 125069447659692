export const EmailIcon = () => (
  <svg
    width="14"
    height="12"
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.36152 0.51001C0.611523 0.51001 0.0615234 1.11001 0.0615234 1.86001V9.96001C0.0615234 10.71 0.661523 11.31 1.36152 11.31H12.6115C13.3615 11.31 13.9115 10.71 13.9115 9.96001V1.86001C13.9115 1.11001 13.3115 0.51001 12.6115 0.51001H1.36152ZM12.3615 3.36001L6.96152 6.66001L1.56152 3.36001V2.06001L6.96152 5.36001L12.3615 2.06001C12.3615 2.06001 12.3615 3.36001 12.3615 3.36001Z"
      fill="black"
    />
  </svg>
);
