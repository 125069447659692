export const nameToInitials = ({
  firstName,
  lastName,
}: {
  firstName?: string;
  lastName?: string;
} = {}): string =>
  [(firstName || "").slice(0, 1), (lastName || "").slice(0, 1)]
    .filter(Boolean)
    .join("")
    .toUpperCase();
