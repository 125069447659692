import { useEffect, useState } from "react";
import { AnalyticsBrowser, User } from "@segment/analytics-next";
import { commonConfig } from "~/config/common-config";
import { analyticsPiiSanitizePlugin } from "~/lib/client/analyticsPiiSanitizePlugin";

// The analytics object is not exported to prevent altering the configuration
const analytics = new AnalyticsBrowser();

if (commonConfig.NEXT_PUBLIC_SEGMENT_WRITE_KEY) {
  const apiUrl = new URL(commonConfig.NEXT_PUBLIC_ENDIL_URL);
  analytics.load(
    {
      writeKey: commonConfig.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
      plugins: [analyticsPiiSanitizePlugin],
      ...(commonConfig.NEXT_PUBLIC_SEGMENT_SHOULD_PROXY
        ? {
            cdnURL: `${commonConfig.NEXT_PUBLIC_ENDIL_URL}/api/v1/cdn`,
          }
        : {}),
    },
    {
      // Prevent the automatic pageview event on load. We handle this separately in the SegmentAnalytics component
      initialPageview: false,
      ...(commonConfig.NEXT_PUBLIC_SEGMENT_SHOULD_PROXY
        ? {
            integrations: {
              "Segment.io": {
                // POST https://MY-CUSTOM-API-PROXY.com/v1/t --> proxies to
                // https://api.segment.io/v1/t
                apiHost: `${apiUrl.host}/api/v1/events`,
                protocol: apiUrl.protocol.replaceAll(":", ""),
              },
            },
          }
        : {}),
    }
  );
}

/**
 * Calls Segment's identify method used to identify users
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
 */
export const analyticsIdentify = (
  ...identifyArgs: Parameters<typeof analytics.identify>
) => analytics.identify(...identifyArgs);

/**
 * Calls Segment's track method used to track custom events
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
 */
export const analyticsTrack = (
  ...trackArgs: Parameters<typeof analytics.track>
) => analytics.track(...trackArgs);

/**
 * Calls Segment's page method used to track navigation changes
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
 */
export const analyticsPage = (...pageArgs: Parameters<typeof analytics.page>) =>
  analytics.page(...pageArgs);

/**
 * Calls Segment's reset method used to track navigation changes
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#reset-or-log-out
 */
export const analyticsReset = () => analytics.reset();

/**
 * A hook that returns an Analytics user. The user will be null until it loads.
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#user-and-group-information
 */
export const useAnalyticsUser = () => {
  const [analyticsUser, setAnalyticsUser] = useState<User | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const getAnalyticsUserAndSetState = async () => {
      try {
        const user = await analytics.user();
        setAnalyticsUser(user);
      } catch (err) {
        setError(err as Error);
      }
    };
    getAnalyticsUserAndSetState();
  }, []);

  return { analyticsUser, error };
};
