import { useRouter } from "next/router";
import { useCallback } from "react";
import { isUrlSafeForNavigate } from "~/lib/shared/isUrlSafeForNavigate";

type UtmParams = {
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
};

const utmValues: Array<keyof UtmParams> = [
  "utm_source",
  "utm_medium",
  "utm_campaign",
  "utm_term",
  "utm_content",
];

export const useRouteWithUTMParams = () => {
  const router = useRouter();

  return useCallback(
    (path: string, additionalParams: UtmParams = {}) => {
      if (!isUrlSafeForNavigate(path)) {
        return;
      }
      const [rawPathname, hashVal] = path.split("#");
      const [pathname, rawPathParams] = rawPathname.split("?");
      const hash = hashVal ? `#${hashVal}` : "";
      // Note: not using next-router here since the behavior
      //       of supplying current utm_params from the URL
      //       was not predictable
      const params = new URLSearchParams(window.location.search);
      const utmParams = new URLSearchParams(rawPathParams);
      utmValues.forEach((key) => {
        if (additionalParams[key]) {
          utmParams.append(key, additionalParams[key]!);
        }
        if (params.has(key)) {
          utmParams.append(key, params.get(key)!);
        }
      });

      router.push(`${pathname}?${utmParams.toString()}${hash}`);
    },
    [router]
  );
};
