import { useSession } from "next-auth/react";
import { useCallback, useState } from "react";
import { useTurnstile } from "react-turnstile";
import { useAnalytics } from "~/components/Analytics";

export const useTurnstileHelper = () => {
  const turnstile = useTurnstile();
  const { data: session } = useSession();
  const enableTurnstile = !session;

  const { analyticsEnhancedTrack } = useAnalytics();
  const [turnstileResult, setTurnstileResult] = useState<string | null>(null);

  const trackTurnstileEvent = useCallback(
    ({ success }: { success: boolean }) => {
      analyticsEnhancedTrack("Turnstile Result", {
        success,
      });
    },
    [analyticsEnhancedTrack]
  );

  return {
    resetTurnstile: () => {
      try {
        turnstile.reset();
      } catch (err) {
        // no-op on failed reset, means that there is no turnstile widget on screen
      }
    },
    enableTurnstile,
    setTurnstileResult: useCallback(
      (token: string) => {
        if (!enableTurnstile) {
          return;
        }
        trackTurnstileEvent({ success: true });
        setTurnstileResult(token);
      },
      [enableTurnstile, trackTurnstileEvent]
    ),
    trackTurnstileError: useCallback(() => {
      if (!enableTurnstile) {
        return;
      }

      trackTurnstileEvent({ success: false });
    }, [enableTurnstile, trackTurnstileEvent]),
    turnstileResult,
  };
};
