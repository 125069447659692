import { convertHTMLToComponents } from "./html-to-components";
import { extractTagToReactConverters } from "./tag-to-react-converters";

/**
 * This method takes in a string of HTML and extracts the text content, discarding the tags
 */
export const extractTextFromHTML = (html: string): string => {
  /* This logic is just here to satisfy typescript. This invocation of 
  convertHTMLToComponents will always return a string or null */
  const result = convertHTMLToComponents(
    html,
    new Map(),
    extractTagToReactConverters
  );
  if (typeof result === "string") {
    return result;
  }
  return "";
};
