import {
  ArticleJsonLd,
  CollectionPageJsonLd,
  NewsArticleJsonLd,
  NextSeo,
  NextSeoProps,
  SocialProfileJsonLd,
  WebPageJsonLd,
} from "next-seo";
import { LandingPageV2 } from "~/components/LandingPageV2/schemas";
import { LandingPage } from "~/types/asgard/LandingPage";
import { Post } from "~/types/asgard/Post";
import { StructureDataProps } from "~/types/asgard/StructuredData";
import { Vertical } from "~/types/asgard/Vertical";
import { PostV2 } from "~/types/asgard/v2/PostV2";
import { ExecutePianoProps } from "../Piano";

/** Used by helper functions below, but not actually used by the PageMeta component */
export type PianoMetadata = {
  piano: ExecutePianoProps;
};

export type PostMetadata = PianoMetadata & {
  seo: NextSeoProps;
  structuredData: StructureDataProps;
};

export const apiVerticalToPageMetaData = (
  vertical: Vertical
): PostMetadata => ({
  seo: vertical.seo,
  piano: {
    type: "detail",
    contentType: {
      slug: vertical.type,
    },
    datePublished: vertical.header.columnCenter.posts[0].post.dateGmt,
    verticals: [],
    authors: [],
    tags: [],
  },
  structuredData: vertical.structuredData,
});

export const apiLandingPageToPageMeta = (
  landingPage: LandingPage | LandingPageV2
): PostMetadata => ({
  seo: landingPage.seo,
  piano: {
    type: "simple",
  },
  structuredData: landingPage.structuredData,
});

export const apiPostToPageMetadata = (post: Post): PostMetadata => ({
  seo: post.seo,
  piano: {
    type: "detail",
    contentType: {
      slug: post.type,
    },
    datePublished: post.date_gmt,
    verticals: post.categories.map((category) => ({ slug: category.slug })),
    authors: post.authors.map((author) => ({ slug: author.author_nicename })),
    tags: post.tags.map((tag) => ({ slug: tag.slug })),
  },
  structuredData: post.structuredData,
});

export const apiPostV2ToPageMetadata = (post: PostV2): PostMetadata => ({
  seo: post.seo,
  piano: {
    type: "detail",
    contentType: {
      slug: post.type,
    },
    datePublished: post.dateGmt,
    verticals: post.categories.map((category) => ({ slug: category.slug })),
    authors: post.authors.map((author) => ({ slug: author.authorNicename })),
    tags: post.tags.map((tag) => ({ slug: tag.slug })),
  },
  structuredData: post.structuredData,
});

type ExtendJsonLdProps = {
  extraProps: StructureDataProps;
  /**
   * A "rendered" next-seo JSON LD component, this must explicitly be called like a function and not JSX
   *
   * @example
   *
   * import { ArticleJsonLd } from 'next-seo';
   * <ExtendJsonLd extraProps={{ foo: "bar" }} originalRender={ArticleJsonLd(props)} />
   */
  originalRender: JSX.Element;
};

function ExtendJsonLd({
  extraProps,
  originalRender,
}: ExtendJsonLdProps): JSX.Element {
  const output: JSX.Element = {
    ...originalRender,
    props: {
      ...originalRender.props,
      ...extraProps,
    },
  };

  if (Object.prototype.hasOwnProperty.call(output.props, "mainEntityOfPage")) {
    const url = extraProps?.url;
    output.props.url = url;

    delete output.props.mainEntityOfPage;
  }

  return output;
}

const structuredDataComponent = (props: StructureDataProps): JSX.Element => {
  const regWallProps = {
    isAccessibleForFree: false,
    hasPart: {
      "@type": "WebPageElement",
      isAccessibleForFree: false,
      cssSelector: ".gated",
    },
  };
  switch (props?.type) {
    case "Article":
      return (
        <ExtendJsonLd
          extraProps={props}
          originalRender={ArticleJsonLd(props)}
        />
      );
    case "CollectionPage":
      return (
        <ExtendJsonLd
          extraProps={props}
          originalRender={CollectionPageJsonLd(props)}
        />
      );
    case "WebPage":
      return <WebPageJsonLd {...props} />;
    case "Person":
      return <SocialProfileJsonLd {...props} />;
    case "NewsArticle":
      // eslint-disable-next-line no-case-declarations
      const extendedNewsArticleProps = {
        ...props,
        ...regWallProps,
      };
      return (
        <ExtendJsonLd
          extraProps={props}
          originalRender={NewsArticleJsonLd(extendedNewsArticleProps)}
        />
      );
    default:
      return <></>;
  }
};

export const PageMeta = ({
  seo,
  structuredData,
}: {
  seo: NextSeoProps;
  structuredData?: StructureDataProps;
}): JSX.Element => (
  <>
    {structuredDataComponent(structuredData)}
    <NextSeo {...seo} />
  </>
);
