import { useEffect, useState } from "react";
import {
  FeatureFlags,
  defaultFeatureFlags,
} from "~/lib/edge/feature-flags/schema";
import { cookieName } from "~/lib/edge/feature-flags/settings";
import { parseFeatureFlagsCookie } from "~/lib/shared/featureFlags";

const findFeaturesCookie = (str: string): string => {
  const featureCookie: undefined | string[] = str
    .split(";")
    .map((v) => v.split("="))
    .find(([k]) => k.trim() === cookieName);
  return featureCookie ? featureCookie[1] : "";
};

const extractFeatureFlags = (fullCookieString: string): FeatureFlags => {
  const rawFeatures = findFeaturesCookie(fullCookieString);
  const parsedFeatureFlags = parseFeatureFlagsCookie(rawFeatures);

  return {
    ...defaultFeatureFlags,
    ...parsedFeatureFlags,
  };
};

export const useFeatureFlags = (): FeatureFlags => {
  const [value, setValue] = useState<FeatureFlags>(() => defaultFeatureFlags);
  useEffect(() => {
    setValue(extractFeatureFlags(document.cookie));
  }, []);
  return value;
};
