import isbot from "isbot";
import { PianoSDKStatus, usePiano } from "./PianoContext";

const pianoCrawlerUA = "cXensebot";
export const useIsPaywallShown = (): boolean => {
  const ctx = usePiano();

  // The meter is bypassed in the following scenarios:
  // 1. Piano isn't instantiated
  // 2. SSG (no window)
  // 3. Scraping bot, e.g., google or google news
  // 4. Piano scraping bot
  if (
    ctx.pianoStatus === PianoSDKStatus.NotReady ||
    typeof window === "undefined" ||
    !window.navigator ||
    isbot(navigator.userAgent) ||
    navigator.userAgent.includes(pianoCrawlerUA)
  ) {
    return false;
  }
  return ctx.paywallShown;
};
