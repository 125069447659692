import { Icon } from "~/ui-library";
import { Flyout } from "~/ui-library/components/Flyout/Flyout";
import { XMarkIcon } from "~/ui-library/icons";
import { PianoSDKStatus, usePiano } from "~/components/Piano/PianoContext";
import { useStickyPromotionFlyout } from "~/components/Promotions";

export const PromotionalBannerFlyout: React.FC<{}> = () => {
  const ctx = usePiano();
  const promotionalBannerSlug =
    ctx.pianoStatus === PianoSDKStatus.Ready && ctx.promotionalBannerSlug
      ? ctx.promotionalBannerSlug
      : "";
  const { closeFlyout, isShowingFlyout } = useStickyPromotionFlyout({
    slug: promotionalBannerSlug,
    storageKey: promotionalBannerSlug,
    analyticsShowMessage: "Showed Promotional Banner",
    analyticsDismissMessage: "Dismissed Promotional Banner",
  });

  const isOpen = !!promotionalBannerSlug && isShowingFlyout;
  return (
    <Flyout
      appearance="primary"
      openFromDirection="bottom"
      isOpen={isOpen}
      showOverlay={false}
      flyoutClassName="bg-transparent min-h-[100px] shadow-none duration-snail"
      priority="low"
    >
      <div
        id="piano-promotional-banner"
        data-testid="piano-promotional-banner"
      />
      <Icon
        className="absolute right-s top-s cursor-pointer"
        onClick={closeFlyout}
        SvgIcon={XMarkIcon}
      />
    </Flyout>
  );
};
