/**
 * Hook to retrieve the global document object
 *
 * When used on client: Returns window.document as expected
 *
 * When used on server: Returns undefined as window/document are undefined
 *
 * This is abstracted into a hook to be easily mocked in tests to simulate server rendering
 */
export const useDocument = (): Document | undefined => {
  if (typeof document === "undefined") {
    return undefined;
  }

  return document;
};
