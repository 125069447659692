import {
  cloneElement,
  createContext,
  forwardRef,
  useContext,
  useState,
  HTMLProps,
  ReactElement,
  ReactNode,
  useMemo,
} from "react";

import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  safePolygon,
  shift,
  size,
  useDismiss,
  useFloating,
  useFocus,
  useHover,
  useInteractions,
  useMergeRefs,
  useRole,
  Placement,
  UseFloatingOptions,
} from "@floating-ui/react";

export type TooltipOptions = {
  placement?: Placement;
  shiftOptions?: Parameters<typeof shift>[0];
  offsetOptions?: Parameters<typeof offset>[0];
  flipOptions?: Parameters<typeof flip>[0];
  portalElementId?: string;
  onOpenChange?: UseFloatingOptions["onOpenChange"];
};

const useTooltip = ({
  placement = "top",
  portalElementId,
  shiftOptions = { padding: 8 },
  offsetOptions = 8,
  flipOptions = {
    crossAxis: placement.includes("-"),
    fallbackAxisSideDirection: "start",
    padding: 5,
  },
  onOpenChange,
}: TooltipOptions) => {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen: UseFloatingOptions["onOpenChange"] = (open, event, reason) => {
    setIsOpen(open);
    onOpenChange?.(isOpen, event, reason);
  };

  const data = useFloating({
    placement,
    open: isOpen,
    onOpenChange: onOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetOptions),
      flip(flipOptions),
      shift(shiftOptions),
      size({
        // prevents the tooltip from growing beyond the viewport
        apply({ availableHeight, availableWidth, elements }) {
          Object.assign(elements.floating.style, {
            maxHeight: `${availableHeight}px`,
            maxWidth: `${availableWidth}px`,
          });
        },
      }),
    ],
  });

  const { context } = data;

  const hover = useHover(context, {
    move: false,
    handleClose: safePolygon({ requireIntent: false, buffer: 1 }),
  });
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const role = useRole(context, { role: "tooltip" });

  const interactions = useInteractions([hover, focus, dismiss, role]);

  return useMemo(
    () => ({
      isOpen,
      setOpen: setIsOpen,
      portalElementId,
      ...interactions,
      ...data,
    }),
    [isOpen, setIsOpen, interactions, data, portalElementId]
  );
};

type TooltipContextData = ReturnType<typeof useTooltip> | null;

const TooltipContext = createContext<TooltipContextData>(null);

const useTooltipContext = () => {
  const context = useContext(TooltipContext);

  if (context == null) {
    throw new Error("Tooltip components must be wrapped in <Tooltip />");
  }

  return context;
};

export const Tooltip = ({
  children,
  ...options
}: { children: ReactNode } & TooltipOptions) => {
  const tooltip = useTooltip(options);
  return (
    <TooltipContext.Provider value={tooltip}>
      {children}
    </TooltipContext.Provider>
  );
};

export const TooltipTrigger = forwardRef<
  HTMLElement,
  HTMLProps<HTMLElement> & { children: ReactElement }
>(({ children, ...props }, propRef) => {
  const context = useTooltipContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  return cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      "data-state": context.isOpen ? "open" : "closed",
      tabIndex: children.props.tabIndex ?? 0,
    })
  );
});

TooltipTrigger.displayName = "TooltipTrigger";

export const TooltipContent = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement>
>(
  (
    {
      style,
      className = "bg-black text-white p-s w-[400px] rounded-3 z-500",
      ...props
    }: HTMLProps<HTMLDivElement>,
    propRef
  ) => {
    const context = useTooltipContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    if (!context.isOpen) {
      return null;
    }

    return (
      <FloatingPortal id={context.portalElementId}>
        <div
          ref={ref}
          style={{
            ...context.floatingStyles,
            ...style,
          }}
          className={className}
          {...context.getFloatingProps(props)}
        />
      </FloatingPortal>
    );
  }
);

TooltipContent.displayName = "TooltipContent";
